import { createSlice } from "@reduxjs/toolkit";

import { AppsMetaConfig } from "./types";
import { fetchAppsConfig } from "@ducks/config";
import { APPS_SEO_SETTINGS, APPS_PAGE_SEO } from "./constants";

const initialState: AppsMetaConfig = {
  default: APPS_SEO_SETTINGS,
  disney: {
    ...APPS_SEO_SETTINGS,
    pages: {
      activate: APPS_PAGE_SEO,
      activateThankYou: APPS_PAGE_SEO,
      activatePending: APPS_PAGE_SEO,
      activateComplete: APPS_PAGE_SEO,
      purchase: APPS_PAGE_SEO,
      purchaseThankYou: APPS_PAGE_SEO,
      contact: APPS_PAGE_SEO,
      contactThankYou: APPS_PAGE_SEO,
      waiting: APPS_PAGE_SEO,
    },
  },
};

const appsMetaSlice = createSlice({
  name: "appsMeta",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAppsConfig.fulfilled, (state, action) => {
      const { meta } = action.payload;
      if (meta) {
        state.default = meta.default;
        state.disney = meta.disney;
      }
    });
  },
});

export default appsMetaSlice.reducer;
