import { FetchStatus } from "@ducks/types";

export enum PortalType {
  TV = 1,
  BB = 2,
}

export enum SignUpErrorCode {
  Validation = "VALIDATION_ERROR",
  InvalidPhone = "phone.invalidPhone",
  InvalidToken = "FORBIDDEN",
  LeadProcessed = "INVALID_LEAD_UPDATE",
  LeadIdError = "LEAD_READ_FAILED",
  InvalidEmail = "string.email",
}

export enum IdType {
  nric = "NRIC",
}

export enum Gender {
  male = "Male",
  female = "Female",
}

export enum ResidentialType {
  SingleDwelling = 1,
  MultiDwelling = 2,
}

export enum ProductType {
  Shop = "shop",
  Broadband = "broadband",
}

export interface FormConfig {
  name: {
    isRequired: boolean;
    salutations: FormParameter[];
    preselectedSalutationValue?: string;
    htmlInputProps?: HtmlInputProps;
  };
  id: {
    isRequired: boolean;
    types: IdTypeItems[];
    preselectedTypeValue?: string;
    htmlInputProps?: HtmlInputProps;
  };
  document: {
    isRequired: boolean;
    fileTypes: string[]; // MIME type
    maxFileMB: number;
    placeholderIcon: string;
    loadingIcon: string;
    cancelIcon: string;
    deleteIcon: string;
    pdfPlaceholderIcon: string;
  };
  dob: {
    isRequired: boolean;
    minEligibleAge: number;
    noAdditionalDocAge: number;
    maxValidAge: number;
  };
  gender: DropdownConfig;
  ethnic: DropdownConfig;
  language: DropdownConfig;
  residentialType: DropdownConfig;
  houseNumber: TextfieldConfig;
  unit: TextfieldConfig;
  block: TextfieldConfig;
  building: TextfieldConfig;
  street: {
    isRequired: boolean;
    types: FormParameter[];
    preselectedTypeValue?: string;
    htmlInputProps?: HtmlInputProps;
  };
  postcode: {
    isRequired: boolean;
    htmlInputProps?: HtmlInputProps;
    blockedCharactersRegex: string;
    length: number;
  };
  area: BaseFieldConfig;
  city: BaseFieldConfig;
  state: BaseFieldConfig;
  showInstallationSchedule: number;
  installationDate: InstallationDateConfig;
  installationTime: InstallationTimeConfig;
  mobileNumber: {
    prefixes: FormParameter[];
    preselectedPrefixValue?: string;
    htmlInputProps?: HtmlInputProps;
    isRequired: boolean;
    blockedCharactersRegex: string;
    length: number;
  };
  alternateNumber: {
    prefixLabel: string;
    prefixValue: string;
    htmlInputProps?: HtmlInputProps;
    isRequired: boolean;
    blockedCharactersRegex: string;
    length: number;
  };
  email: TextfieldConfig;
  regex: FormRegex;
  cableTypeMap: Record<string, string>;
  serviceProviderMap: Record<string, string>;
}

export interface BaseFieldConfig {
  isRequired: boolean;
}

export interface DropdownConfig extends BaseFieldConfig {
  items: FormParameter[];
  preselectedValue?: string;
}

export interface TextfieldConfig extends BaseFieldConfig {
  htmlInputProps?: HtmlInputProps;
}

export interface HtmlInputProps {
  [key: string]: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

export interface InstallationDateConfig {
  isRequired: number;
  first: number;
  last: number;
  calendarIcon: string;
}
export interface InstallationTimeConfig {
  isRequired: number;
  items: FormParameter[];
  preselectedValue?: string;
}

export interface FormParameter {
  label: string;
  value: string | number;
}

interface FormRegex {
  name: string;
  house: string;
  unit: string;
  block: string;
  building: string;
  street: string;
  postcode: string;
  email: string;
  mobile: string;
  alternateNumber: string;
}

interface DropdownState {
  value: string;
  hasRequiredError: boolean;
}
interface TextfieldState extends DropdownState {
  hasInvalidError: boolean;
}

export interface SignUpState {
  config: FormConfig;
  lead: {
    id: string;
    token: string;
  };
  leadStatus: FetchStatus;
  salutation: DropdownState;
  name: TextfieldState;
  idType: DropdownState;
  idValue: TextfieldState;
  document: {
    files: {
      [name: string]: DocumentData;
    };
    status: FetchStatus;
  };
  dob: {
    value: string;
    hasRequiredError: boolean;
    hasInvalidError: boolean;
    isNotEligibleError: boolean;
    needAdditionalDoc: boolean;
  };
  gender: DropdownState;
  ethnic: DropdownState;
  language: DropdownState;
  residentialType: {
    value: ResidentialType | null;
    hasRequiredError: boolean;
  };
  house: TextfieldState;
  unit: TextfieldState;
  block: TextfieldState;
  building: TextfieldState;
  streetName: TextfieldState;
  streetType: DropdownState;
  street: TextfieldState;
  postcode: TextfieldState;
  postcodeStatus: FetchStatus;
  area: {
    value: string;
    items: string[];
    hasRequiredError: boolean;
  };
  city: DropdownState;
  state: DropdownState;
  installationDate: DropdownState;
  installationTime: DropdownState;
  mobilePrefix: DropdownState;
  mobileNumber: TextfieldState;
  alternateNumber: TextfieldState;
  email: TextfieldState;
  showError: boolean;

  // TODO: values that need to pass in during update lead, but doesn't have a field in sign up page
  cableType: string;
  serviceProvider: string;
  allowDishInstallation: boolean;
}

export interface DocumentData {
  file: File;
  data?: string;
  documentId?: string;
}

export interface AddDocument extends DocumentData {
  name: string;
}

export interface UpdateEmail {
  email: string;
  isValid: boolean;
}

export interface UpdatePostcode {
  postcode: string;
  hasRequiredError: boolean;
  hasInvalidError: boolean;
}

export interface FetchPostcodeReturn {
  area: string[];
  city: string;
  state: string;
}

export interface FetchPostcodeResponse {
  area: string[];
  city: string;
  state: string;
}

export interface PackageParameters {
  offerId: string;
  selectedPackages: string[];
  campaignCode?: string;
  promoCode?: string;

  bundlePack: string;
  selectedAddOns: string[];
  selectedDevices: string[];
  speed?: string;
  cableType?: string;
  serviceProvider?: string;

  tierId: string;
  packId: string;
  addonIds: string[];
  deviceIds: string[];
  speedId?: string;
  subType?: string;
  broadbandInterest: boolean;
}

export interface BasicLeadParameters {
  type: string;
  package: PackageParameters;
}

export interface GATrackingParameters {
  utmSource?: string;
  utmMedium?: string;
  utmCampaign?: string;
}

export interface GenerateLeadParameters extends BasicLeadParameters {
  gaTracking?: GATrackingParameters;
}

export interface GenerateLeadReturn {
  leadId: string;
  token: string;
}

export interface GenerateLeadResponse extends GenerateLeadReturn {
  type: string;
}

export interface CustomerLeadParameters {
  name: string;
  title: string;
  idType: string;
  idValue: string;
  dob: string;
  phoneNumber: string;
  alternatePhoneNumber?: string;
  homePhoneNumber?: string;
  officePhoneNumber?: string;
  email: string;
  gender: string;
  ethnic: string;
  language: string;
  city: string;
  state: string;
  town: string;
  postal: string;
  streetName: string;
  residenceType: number;
  houseNumber?: string;
  buildingName?: string;
  unitNumber?: string;
  block?: string;
  installationDate?: string;
  installationTime?: string;
  allowDishInstallation?: boolean;
}

export interface BFCustomerLeadParameters {
  signature: string;
  phoneNumber: string;
  alternatePhoneNumber?: string;
  homePhoneNumber?: string;
  officePhoneNumber?: string;
  email: string;
  city: string;
  state: string;
  town: string;
  postal: string;
  streetName: string;
  residenceType: number;
  houseNumber?: string;
  buildingName?: string;
  unitNumber?: string;
  block?: string;
  installationDate?: string;
  installationTime?: string;
  allowDishInstallation?: boolean;
}

export interface UpdateLeadReturn {
  verificationRequired: boolean;
}

export interface UpdateLeadResponse extends UpdateLeadReturn {
  leadId: string;
}

export interface UpdateLeadErrorResponse {
  errorCode: string | null;
  invalidMobile: boolean;
  invalidAlternateNumber: boolean;
  invalidEmail: boolean;
}

export interface UpdateLeadParameters extends Required<BasicLeadParameters>, CustomerLeadParameters {
  orderSummaryQuery: string;
}

export interface UpdateBFLeadParameters extends Required<BasicLeadParameters>, BFCustomerLeadParameters {
  orderSummaryQuery: string;
}

export interface GenerateSignedUrlParameters {
  type: string;
  title: string;
}

export interface GenerateSignedUrlReturn {
  documentId: string;
  signedUploadUrl: string;
}

export interface GenerateSignedUrlResponse extends GenerateSignedUrlReturn {}

export interface IdTypeItems {
  label: string;
  value: string;
  regex: string;
  placeholder: string;
  documents: string[];
  additionalDocuments: string[];
  blockedCharactersRegex: string;
  length: number;
}
