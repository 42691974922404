/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export interface APIResponse<T = any> {
  responseCode: number;
  responseMessage: string;
  response: T;
}

export interface APIErrorResponse {
  errors: ErrorDetails[];
}

interface ErrorDetails {
  errorCode: string;
  errorTitle: string;
  errorDescription: string;
  errorDebugDescription: string;
  errorAttributes: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  errorDisplayCode?: string;
}

export function isErrorResponse(response: unknown): response is APIErrorResponse {
  const errors = (response as APIErrorResponse).errors;
  return errors !== undefined && Array.isArray(errors);
}

export function getErrorCode(response: APIErrorResponse) {
  const { errors } = response;
  if (errors.length && errors[0].errorCode) return errors[0].errorCode;
  return null;
}

export function getErrorMessage(dictionary: Record<string, string>, code?: string, defaultCode = "GENERIC_ERROR") {
  return code && dictionary[code] ? dictionary[code] : dictionary[defaultCode];
}

export function getErrorDisplayCode(response: APIErrorResponse) {
  const { errors } = response;
  if (errors.length && errors[0].errorDisplayCode) return errors[0].errorDisplayCode;
  return null;
}
