import { createAction, createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppState, AppThunk } from "@store";
import {
  ReferralCodeItem,
  ReferralRetrieveRequest,
  ReferralRetrieveResponse,
  ReferralRetrieveResult,
  ReferralState,
} from "./types";
import { getInvoice, invoiceUpdated } from "../shop";
import settings from "@settings";
import { APIResponse, getErrorCode, isErrorResponse } from "@lib/api";

export const fetchReferral = createAsyncThunk<
  ReferralRetrieveResult,
  string,
  { state: AppState; rejectValue: string | null }
>("referral/fetchReferral", async (promoCode, thunkApi) => {
  const { token } = thunkApi.getState().signUp.lead;

  const request: ReferralRetrieveRequest = { promoCode };
  if (process.env.REACT_APP_USE_MOCK_REFERRAL === "true") request.mock = true;

  const response = await fetch(settings.apiEndpoint.checkReferralCode, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(request),
  });

  const data = (await response.json()) as APIResponse<ReferralRetrieveResponse>;
  if (isErrorResponse(data.response)) {
    return thunkApi.rejectWithValue(getErrorCode(data.response));
  }
  return data.response;
});

const initialState: ReferralState = {
  showConfirmModal: false,
  retrievedCodes: [],
  referralCode: "",
  appliedCode: "",
  referralStatus: {
    loading: false,
    error: false,
    success: false,
  },
};

const referralSlice = createSlice({
  name: "referral",
  initialState,
  reducers: {
    confirmModalOpened(state) {
      state.showConfirmModal = true;
    },
    confirmModalClosed(state) {
      state.showConfirmModal = false;
    },
    referralCodeUpdated(state, action: PayloadAction<string>) {
      const value = action.payload;
      if (value !== state.referralCode) state.referralCode = action.payload;
    },
    referralCleared(state) {
      state.retrievedCodes = [];
      state.appliedCode = "";
      state.referralCode = "";
      state.referralStatus = {
        loading: false,
        success: false,
        error: false,
      };
    },
    referralApplied(state, action: PayloadAction<string>) {
      state.appliedCode = action.payload;
    },
    appliedCodeRemoved(state) {
      const { retrievedCodes, appliedCode } = state;
      state.retrievedCodes = retrievedCodes.filter((item) => item.id !== appliedCode);
      state.appliedCode = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchReferral.pending, (state) => {
        state.referralStatus = {
          loading: true,
          success: false,
          error: false,
        };
      })
      .addCase(fetchReferral.rejected, (state, action) => {
        state.referralStatus = {
          loading: false,
          success: false,
          error: true,
        };
        if (action.payload) state.referralStatus.errorCode = action.payload;
      })
      .addCase(fetchReferral.fulfilled, (state, action) => {
        const { refereeOfferPrice, promoDescription, promoTitle, referralSignature, customerType } = action.payload;
        state.referralStatus = {
          loading: false,
          success: true,
          error: false,
        };
        state.retrievedCodes.unshift({
          id: state.referralCode,
          title: promoTitle,
          description: promoDescription,
          price: refereeOfferPrice,
          signature: referralSignature,
          referralType: customerType,
        });
      });
  },
});

export const {
  confirmModalOpened,
  confirmModalClosed,
  referralCodeUpdated,
  referralCleared,
  referralApplied,
  appliedCodeRemoved,
} = referralSlice.actions;

export default referralSlice.reducer;

/* Thunks */
export const applyReferral = (): AppThunk => (dispatch, getState) => {
  const {
    referral: { referralCode, retrievedCodes },
    shop: { selectedPack },
    config: { price },
  } = getState();

  const referral = retrievedCodes.find((referral) => referral.id === referralCode);

  if (referral) {
    dispatch(referralApplied(referralCode));

    if (selectedPack) {
      const invoice = getInvoice(selectedPack, getState().shop, price, referral);
      dispatch(invoiceUpdated(invoice));
    }
  }
};

export const retrieveReferral =
  (code: string): AppThunk =>
  async (dispatch, getState) => {
    const {
      referral: { appliedCode, retrievedCodes },
    } = getState();

    dispatch(referralCodeUpdated(code));

    const referral = retrievedCodes.find((referral) => referral.id === code);

    if (referral) {
      dispatch(trackApply());
      if (appliedCode) dispatch(confirmModalOpened());
      else dispatch(applyReferral());
    } else {
      const response = await dispatch(fetchReferral(code));
      if (fetchReferral.fulfilled.match(response)) {
        dispatch(trackApply());
        if (appliedCode) dispatch(confirmModalOpened());
        else dispatch(applyReferral());
      }
    }
  };

export const resetReferral = (): AppThunk => (dispatch, getState) => {
  const {
    shop: { selectedPack },
    config: { price },
  } = getState();
  if (selectedPack) {
    dispatch(referralCleared());

    const invoice = getInvoice(selectedPack, getState().shop, price);
    dispatch(invoiceUpdated(invoice));
  }
};

/* Analytics actions */
export const trackApply = createAction("referral/trackApply");
export const trackReplace = createAction<boolean>("referral/trackReplace");
export const trackInvalidSubmission = createAction("referral/trackInvalidSubmission");

/* Utilities functions */
export function getAppliedReferral(referralState: ReferralState): ReferralCodeItem | null {
  const { appliedCode, retrievedCodes } = referralState;
  return retrievedCodes.find((referral) => referral.id === appliedCode) ?? null;
}
