import { fetchConfig } from "@ducks/config";
import { createSlice } from "@reduxjs/toolkit";

import { ResizerConfig } from "./types";

const initialState: ResizerConfig = {
  url: "",
  config: {},
};

const resizerSlice = createSlice({
  name: "resizer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchConfig.fulfilled, (state, action) => {
      if (action.payload.resizer) {
        state.url = action.payload.resizer.url;
        state.config = action.payload.resizer.config;
      }
    });
  },
});

export default resizerSlice.reducer;
