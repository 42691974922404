import { createAction } from "@reduxjs/toolkit";
import {
  DisneyErrorData,
  DisneyPageType,
  DisneyTrafficData,
  DownloadAppsCtaEvent,
  TermsConditionsLinkEvent,
} from "./analytics";

export const trackDisneyError = createAction<DisneyErrorData>("appsDisney/disneyError");
export const trackAppsDisneyPageLoad = createAction<string>("appsDisney/trackAppsDisneyPageLoad");
export const trackTermsConditionsLink = createAction<TermsConditionsLinkEvent>("appsDisney/trackTermsConditionsLink");
export const trackMultiSmcModalDisplay = createAction("appsDisney/trackMultiSmcModalDisplay");
export const trackHowToCheckLink = createAction("appsDisney/trackHowToCheckLink");
export const trackActivateSubscribeNowLink = createAction("appsDisney/trackActivateSubscribeNowLink");
export const trackPurchaseSubscribeNowCta = createAction("appsDisney/trackPurchaseSubscribeNowCta");
export const trackConfirmOrderModalDisplay = createAction("appsDisney/trackConfirmOrderModalDisplay");
export const trackPurchaseConfirmCta = createAction("appsDisney/trackPurchaseConfirmCta");
export const trackActivateCancelCta = createAction("appsDisney/trackActivateCancelCta");
export const trackActivateProceedCta = createAction("appsDisney/trackActivateProceedCta");
export const trackRequestOtpModalDisplay = createAction("appsDisney/trackRequestOtpModalDisplay");
export const trackOtpResendLink = createAction("appsDisney/trackOtpResendLink");
export const trackOtpEditMobileNumberLink = createAction("appsDisney/trackOtpEditMobileNumberLink");
export const trackOtpVerifyCta = createAction("appsDisney/trackOtpVerifyCta");
export const trackDownloadAppsCta = createAction<DownloadAppsCtaEvent>("appsDisney/trackDownloadAppsCta");
export const trackBackToHomeCta = createAction<DisneyPageType>("appsDisney/trackThankYouBackToHomeCta");
export const trackDisneyTraffic = createAction<DisneyTrafficData>("appsDisney/disneyTraffic");
export const trackDisneyTrafficSessionExpired = createAction<string>("appsDisney/disneyTrafficSessionExpired");
export const trackWaitingRetry = createAction("appsDisney/trackWaitingRetry");
export const trackRetrySuccess = createAction<string>("appsDisney/trackRetrySuccess");
export const trackDisneyErrorCta = createAction<string | undefined>("appsDisney/trackDisneyErrorCta");
export const trackPurchaseActivation = createAction("appsDisney/trackPurchaseActivation");
export const trackwhatsAppHelplineUpgradePack = createAction("appsDisney/trackwhatsAppHelplineUpgradePack");
export const trackPurchaseIneligible = createAction("appsDisney/trackPurchaseIneligible");
export const trackUpgradeMyPack = createAction("appsDisney/trackUpgradeMyPack");
export const trackMultiPrimarySMC = createAction<string>("appsDisney/trackMultiPrimarySMC");
export const trackWhatsAppHelplineMultiSMC = createAction("appsDisney/trackWhatsAppHelplineMultiSMC");
