// https://material.io/resources/color/#!/?view.left=1&view.right=1&secondary.color=e6007d&secondary.text.color=ffffff&primary.color=11162d

import createPalette from "@material-ui/core/styles/createPalette";

const primaryColor = "#e6007d";
const secondaryColor = "#1d7ee2";

const bgDefault = "#fff";
const bgPaper = "#fff";

const primaryTextColor = "#333";
const secondaryTextColor = "#fff";
const disabledTextColor = "#888";
// const heading = "#000";

const error = "#ff0000";

const grey = {
  100: "#f0f0f0",
  300: "#d8d8d8",
  400: "#bbb",
  600: "#888",
};

const palette = createPalette({
  type: "light",
  primary: {
    main: primaryColor,
  },
  secondary: {
    main: secondaryColor,
  },
  background: {
    default: bgDefault,
    paper: bgPaper,
  },
  common: {
    black: primaryTextColor,
  },
  text: {
    primary: primaryTextColor,
    secondary: secondaryTextColor,
    disabled: disabledTextColor,
  },
  error: {
    main: error,
  },
  action: {
    active: primaryTextColor,
    disabled: grey[300],
  },
  grey,
});

export default palette;
