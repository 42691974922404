import { OrderSummaryConfig, ReferralConfig } from "./types";

export const sessionExpired = "SESSION_EXPIRED";
export const sessionExpiredTv = "SESSION_EXPIRED_TV";
export const sessionExpiredBb = "SESSION_EXPIRED_BB";

export const isBroadband = "isBroadband";
export const orderSummaryLink = "Order Summary Link";

export const ORDER_SUMMARY_LIST_TYPE = "Order Summary";

const REFERRAL_CODE: ReferralConfig = {
  blockedRegex: "",
  length: 0,
};

export const ORDER_SUMMARY_CONFIG: OrderSummaryConfig = {
  addOnsSequence: [],
  promotions: {},
  terms: {},
  showAlternateNumber: false,
  showHomeNumber: false,
  showOfficeNumber: false,
  referralCode: REFERRAL_CODE,
  appliedReferralIcon: "",
  idTypeMapping: {
    NRIC: "",
    Passport: "",
    "Police ID": "",
    "Army ID": "",
    "Navy ID": "",
    "Air Force ID": "",
    "National IC - Old": "",
    "Registration ID": "",
  },
};
