import { EventsMap } from "redux-beacon";
import combineEvents from "@redux-beacon/combine-events";

import {
  getBrand,
  payTvPackage,
  payTvAddOn,
  payTvDevice,
  payTVMethod,
  methodListType,
  EcommerceMode,
  EcommerceProduct,
  GtmEvent,
  ShopEventDefinition,
  Brand,
  EventType,
  LoginType,
} from "../analytics";
import { InitShopState, PromoLinkEventParams } from "./types";
import {
  initPackSelectionSuccess,
  trackMethodLearnMore,
  trackShowMorePopularAddOns,
  updateSelectedPack,
  updateSelectedAddOns,
  updateSelectedDevice,
  updateSelectedMethod,
  updateSelectedSubscriber,
  upsellBroadbandCancelCta,
  upsellBroadbandProceedTvCta,
  upsellBroadbandProceedBbCta,
  updateBbUpsellChoice,
  trackProceed,
  trackViewPack,
  trackPromoRibbonLink,
  trackExistingCustomer,
  initPackWithoutSelectionSuccess,
  trackBenefitHighlight,
  trackCompareDevices,
  trackPromoBannerLink,
  trackLogin,
  normalizeAddOnIds,
} from ".";
import { tierConfigSelector, tierPriceSelector, tierTitleSelector } from "@selectors/packSelection";
import { portalSelector } from "@selectors/portalType";
import { getShopUrlQueryString } from "@ducks/shop";
import { BbUpsellChoice } from "@ducks/shop/types";
import { signUpFormPath } from "@constants/paths";

enum Trigger {
  Manual = "manual",
  Auto = "auto",
  Preselect = "auto-preselected",
  ChangeBase = "auto-base-changed",
  Bundle = "auto-bundle",
}

enum Subscriber {
  Existing = "Existing Subscriber",
  New = "New Subscriber",
}

const initShopSuccessEvent: ShopEventDefinition<InitShopState> = (action, prevState, nextState) => {
  const {
    config: { devices: deviceNames },
    shop: { packs, selectedPack, devices, selectedDevice },
  } = nextState;
  const tierTitle = tierTitleSelector(nextState);
  const { baseHeading, deviceHeading } = tierConfigSelector(nextState);
  const portal = portalSelector(nextState);

  if (selectedPack) {
    const brand = getBrand(portal);
    const events: GtmEvent[] = [
      createEcommerceEvent({
        mode: EcommerceMode.Add,
        brand,
        listType: baseHeading,
        products: [
          {
            name: tierTitle,
            id: tierTitle,
            price: packs[selectedPack.id].itemPrice.toFixed(2),
            brand,
            category: payTvPackage,
            variant: selectedPack.title,
            method: Trigger.Preselect,
            quantity: "1",
          },
        ],
      }),
    ];
    if (selectedDevice)
      events.push(
        createEcommerceEvent({
          mode: EcommerceMode.Add,
          brand,
          listType: deviceHeading,
          products: [
            {
              name: deviceNames[selectedDevice].name,
              id: deviceNames[selectedDevice].name,
              price: devices[selectedDevice].itemPrice.toFixed(2),
              brand,
              category: payTvDevice,
              variant: `Added to ${tierTitle}`,
              method: Trigger.Preselect,
              quantity: "1",
            },
          ],
        })
      );
    return events;
  }
};

const viewPackEvent: ShopEventDefinition = (action, prevState, nextState) => {
  const tierTitle = tierTitleSelector(nextState);
  const tierPrice = tierPriceSelector(nextState);
  const portal = portalSelector(nextState);
  const brand = getBrand(portal);

  return createEcommerceEvent({
    mode: EcommerceMode.Detail,
    brand,
    listType: "View Pack",
    products: [
      {
        name: tierTitle,
        id: tierTitle,
        price: tierPrice.toString(),
        brand,
        category: payTvPackage,
        variant: "",
      },
    ],
  });
};

// Pack added (manual or auto)
const packAddedEvent: ShopEventDefinition = (action, prevState, nextState) => {
  const { packs, selectedPack: nextPack } = nextState.shop;
  const { baseHeading } = tierConfigSelector(nextState);
  const tierTitle = tierTitleSelector(nextState);
  const portal = portalSelector(nextState);

  if (nextPack) {
    const brand = getBrand(portal);
    return createEcommerceEvent({
      mode: EcommerceMode.Add,
      brand,
      listType: baseHeading,
      products: [
        {
          name: tierTitle,
          id: tierTitle,
          price: packs[nextPack.id].itemPrice.toFixed(2),
          brand: getBrand(portal),
          category: payTvPackage,
          variant: nextPack.title,
          method: Trigger.Manual,
          quantity: "1",
        },
      ],
    });
  }
};

// Pack removed (manual)
const packRemovedEvent: ShopEventDefinition = (action, state) => {
  const { packs, selectedPack } = state.shop;
  const tierTitle = tierTitleSelector(state);
  const { baseHeading } = tierConfigSelector(state);
  const portal = portalSelector(state);

  if (selectedPack) {
    const brand = getBrand(portal);
    return createEcommerceEvent({
      mode: EcommerceMode.Remove,
      brand,
      listType: baseHeading,
      products: [
        {
          name: tierTitle,
          id: tierTitle,
          price: packs[selectedPack.id].itemPrice.toFixed(2),
          brand,
          category: payTvPackage,
          variant: selectedPack.title,
          method: Trigger.Manual,
          quantity: "1",
        },
      ],
    });
  }
};

// Add-ons removed (auto)
const addOnsClearedEvent: ShopEventDefinition = (action, state) => {
  const {
    config: { addOns: addOnNames },
    shop: { calculator, selectedPack, selectedAddOns, listedAddOns },
  } = state;
  const tierTitle = tierTitleSelector(state);
  const portal = portalSelector(state);
  const { basicHeading, popularHeading } = tierConfigSelector(state);

  if (selectedPack) {
    const brand = getBrand(portal);
    const { basic, popular } = selectedAddOns.reduce(
      ({ basic, popular }, addOnId) => {
        const name = addOnNames[addOnId].name;
        const id = calculator.getPricePlanId(addOnId);
        const price = listedAddOns[addOnId].itemPrice.toFixed(2);
        const addOn: EcommerceProduct = {
          name,
          id,
          price,
          brand,
          category: payTvAddOn,
          variant: `Added to ${tierTitle}`,
          method: Trigger.ChangeBase,
          quantity: "1",
        };
        if (normalizeAddOnIds(selectedPack.basicAddons).indexOf(addOnId) >= 0) basic.push(addOn);
        if (normalizeAddOnIds(selectedPack.popularAddons).indexOf(addOnId) >= 0) popular.push(addOn);
        return { basic, popular };
      },
      { basic: [], popular: [] } as { basic: EcommerceProduct[]; popular: EcommerceProduct[] }
    );
    const events: GtmEvent[] = [];
    if (basic.length)
      events.push(
        createEcommerceEvent({
          mode: EcommerceMode.Remove,
          brand,
          listType: basicHeading,
          products: basic,
        })
      );
    if (popular.length)
      events.push(
        createEcommerceEvent({
          mode: EcommerceMode.Remove,
          brand,
          listType: popularHeading,
          products: popular,
        })
      );

    if (events.length) return events;
  }
};

// Device preselected (auto)
const devicePreselectedEvent: ShopEventDefinition = (action, prevState, nextState) => {
  const {
    config: { devices: deviceNames },
    shop: { selectedDevice, devices },
  } = nextState;
  const tierTitle = tierTitleSelector(nextState);
  const portal = portalSelector(nextState);
  const { deviceHeading } = tierConfigSelector(nextState);

  if (selectedDevice) {
    const brand = getBrand(portal);
    return createEcommerceEvent({
      mode: EcommerceMode.Add,
      brand,
      listType: deviceHeading,
      products: [
        {
          name: deviceNames[selectedDevice].name,
          id: deviceNames[selectedDevice].name,
          price: devices[selectedDevice].itemPrice.toFixed(2),
          brand,
          category: payTvDevice,
          variant: `Added to ${tierTitle}`,
          method: Trigger.Preselect,
          quantity: "1",
        },
      ],
    });
  }
};

// Device removed by changing pack (auto)
const deviceChangedViaPackEvent: ShopEventDefinition = (action, state) => {
  const {
    config: { devices: deviceNames },
    shop: { selectedDevice, devices },
  } = state;
  const tierTitle = tierTitleSelector(state);
  const portal = portalSelector(state);
  const { deviceHeading } = tierConfigSelector(state);

  if (selectedDevice) {
    const brand = getBrand(portal);
    return createEcommerceEvent({
      mode: EcommerceMode.Remove,
      brand,
      listType: deviceHeading,
      products: [
        {
          name: deviceNames[selectedDevice].name,
          id: deviceNames[selectedDevice].name,
          price: devices[selectedDevice].itemPrice.toFixed(2),
          brand,
          category: payTvDevice,
          variant: `Added to ${tierTitle}`,
          method: Trigger.ChangeBase,
          quantity: "1",
        },
      ],
    });
  }
};

const addOnUpdateEvent: ShopEventDefinition = (action, prevState, nextState) => {
  const {
    config: { addOns: addOnNames },
    shop: { calculator, listedAddOns, selectedPack, addOnSelection },
  } = nextState;
  const tierTitle = tierTitleSelector(nextState);
  const portal = portalSelector(nextState);
  const { basicHeading, popularHeading } = tierConfigSelector(nextState);

  if (selectedPack) {
    const brand = getBrand(portal);
    const events: GtmEvent[] = [];
    const {
      addManual = [],
      addCombo = "",
      addRequired = [],
      removeManual = [],
      removeBundled = [],
      removeRequired = [],
    } = addOnSelection || {};

    const addBasic: EcommerceProduct[] = [];
    const addPopular: EcommerceProduct[] = [];
    // Add-ons added by user manually
    addManual.forEach((addOnId) => {
      const addOn: EcommerceProduct = {
        name: addOnNames[addOnId].name,
        id: calculator.getPricePlanId(addOnId),
        price: listedAddOns[addOnId].itemPrice.toFixed(2),
        brand,
        category: payTvAddOn,
        variant: `Added to ${tierTitle}`,
        method: Trigger.Manual,
        quantity: "1",
      };
      const isBasic = selectedPack.basicAddons.indexOf(addOnId) >= 0;
      const isPopular = selectedPack.popularAddons.indexOf(addOnId) >= 0;
      isBasic && addBasic.push(addOn);
      isPopular && addPopular.push(addOn);
    });
    // Add-ons added automatically via bundling
    const addAuto = [...addRequired];
    if (addCombo) addAuto.push(addCombo);
    addAuto.forEach((addOnId) => {
      const addOn: EcommerceProduct = {
        name: addOnNames[addOnId].name,
        id: calculator.getPricePlanId(addOnId),
        price: listedAddOns[addOnId].itemPrice.toFixed(2),
        brand,
        category: payTvAddOn,
        variant: `Added to ${tierTitle}`,
        method: Trigger.Bundle,
        quantity: "1",
      };
      const isBasic = selectedPack.basicAddons.indexOf(addOnId) >= 0;
      const isPopular = selectedPack.popularAddons.indexOf(addOnId) >= 0;
      isBasic && addBasic.push(addOn);
      isPopular && addPopular.push(addOn);
    });
    if (addBasic.length)
      events.push(
        createEcommerceEvent({
          mode: EcommerceMode.Add,
          brand,
          listType: basicHeading,
          products: addBasic,
        })
      );
    if (addPopular.length)
      events.push(
        createEcommerceEvent({
          mode: EcommerceMode.Add,
          brand,
          listType: popularHeading,
          products: addPopular,
        })
      );

    const removeBasic: EcommerceProduct[] = [];
    const removePopular: EcommerceProduct[] = [];
    // Add-ons removed by user manually
    removeManual.forEach((addOnId) => {
      const addOn: EcommerceProduct = {
        name: addOnNames[addOnId].name,
        id: calculator.getPricePlanId(addOnId),
        price: listedAddOns[addOnId].itemPrice.toFixed(2),
        brand,
        category: payTvAddOn,
        variant: `Added to ${tierTitle}`,
        method: Trigger.Manual,
        quantity: "1",
      };
      const isBasic = selectedPack.basicAddons.indexOf(addOnId) >= 0;
      const isPopular = selectedPack.popularAddons.indexOf(addOnId) >= 0;
      isBasic && removeBasic.push(addOn);
      isPopular && removePopular.push(addOn);
    });
    // Add-ons removed automatically via bundling
    [...removeBundled, ...removeRequired].forEach((addOnId) => {
      const addOn: EcommerceProduct = {
        name: addOnNames[addOnId].name,
        id: calculator.getPricePlanId(addOnId),
        price: listedAddOns[addOnId].itemPrice.toFixed(2),
        brand,
        category: payTvAddOn,
        variant: `Added to ${tierTitle}`,
        method: Trigger.Bundle,
        quantity: "1",
      };
      const isBasic = selectedPack.basicAddons.indexOf(addOnId) >= 0;
      const isPopular = selectedPack.popularAddons.indexOf(addOnId) >= 0;
      isBasic && removeBasic.push(addOn);
      isPopular && removePopular.push(addOn);
    });
    if (removeBasic.length)
      events.push(
        createEcommerceEvent({
          mode: EcommerceMode.Remove,
          brand,
          listType: basicHeading,
          products: removeBasic,
        })
      );
    if (removePopular.length)
      events.push(
        createEcommerceEvent({
          mode: EcommerceMode.Remove,
          brand,
          listType: popularHeading,
          products: removePopular,
        })
      );

    if (events.length) return events;
  }
};

const deviceUpdateEvent: ShopEventDefinition = (action, prevState, nextState) => {
  const {
    config: { devices: deviceNames },
    shop: { selectedDevice: removedDevice, devices },
  } = prevState;
  const { selectedDevice: addedDevice } = nextState.shop;
  const tierTitle = tierTitleSelector(nextState);
  const portal = portalSelector(nextState);
  const { deviceHeading } = tierConfigSelector(nextState);

  const brand = getBrand(portal);
  const listType = deviceHeading;
  const commonParams = {
    brand,
    category: payTvDevice,
    variant: `Added to ${tierTitle}`,
    method: Trigger.Manual,
    quantity: "1",
  };
  const events: GtmEvent[] = [
    createEcommerceEvent({
      mode: EcommerceMode.Add,
      brand,
      listType,
      products: [
        {
          name: deviceNames[addedDevice].name,
          id: deviceNames[addedDevice].name,
          price: devices[addedDevice].itemPrice.toFixed(2),
          ...commonParams,
        },
      ],
    }),
  ];
  if (removedDevice)
    events.push(
      createEcommerceEvent({
        mode: EcommerceMode.Remove,
        brand,
        listType,
        products: [
          {
            name: deviceNames[removedDevice].name,
            id: deviceNames[removedDevice].name,
            price: devices[removedDevice].itemPrice.toFixed(2),
            ...commonParams,
          },
        ],
      })
    );
  return events;
};

const methodUpdateEvent: ShopEventDefinition<boolean> = (action, prevState, nextState) => {
  const {
    config: { installationMethods },
    shop: { selectedMethod: removedMethod },
  } = prevState;
  const { selectedMethod: addedMethod, methods } = nextState.shop;
  const tierTitle = tierTitleSelector(nextState);
  const portal = portalSelector(nextState);
  const listType = methodListType;

  let isAuto = false;
  switch (action.type) {
    case updateSelectedPack.toString():
    case updateSelectedDevice.toString():
      isAuto = true;
      break;
  }

  const brand = getBrand(portal);
  const commonParams = {
    brand,
    category: payTVMethod,
    variant: `Added to ${tierTitle}`,
    method: isAuto ? Trigger.Auto : Trigger.Manual,
    quantity: "1",
  };
  const events: GtmEvent[] = [];
  if (removedMethod)
    events.push(
      createEcommerceEvent({
        mode: EcommerceMode.Remove,
        brand,
        listType,
        products: [
          {
            name: installationMethods[removedMethod]?.name,
            id: installationMethods[removedMethod]?.name,
            price: methods[removedMethod]?.itemFee?.toFixed(2),
            ...commonParams,
          },
        ],
      })
    );
  if (addedMethod)
    events.push(
      createEcommerceEvent({
        mode: EcommerceMode.Add,
        brand,
        listType,
        products: [
          {
            name: installationMethods[addedMethod]?.name,
            id: installationMethods[addedMethod]?.name,
            price: methods[addedMethod]?.itemFee?.toFixed(2),
            ...commonParams,
          },
        ],
      })
    );

  return events;
};

const subscriberUpdateEvent: ShopEventDefinition<boolean> = (action, state) => {
  const tierTitle = tierTitleSelector(state);
  const portal = portalSelector(state);

  return {
    event: EventType.Click,
    content_type: getBrand(portal),
    content_list_type: "Astro Subscriber?",
    content_title: action.payload ? "Existing Subscriber" : "New Subscriber",
    content_category: payTvPackage,
    content_id: tierTitle,
    content_name: tierTitle,
  };
};

const loginEvent: ShopEventDefinition<LoginType> = (action, state) => {
  const tierTitle = tierTitleSelector(state);
  const portal = portalSelector(state);

  return {
    event: EventType.Click,
    content_type: getBrand(portal),
    content_list_type: "Existing Subscriber",
    content_title: action.payload,
    content_category: payTvPackage,
    content_id: tierTitle,
    content_name: tierTitle,
  };
};

const methodLearnMoreEvent: ShopEventDefinition<string> = (action, state) => {
  const tierTitle = tierTitleSelector(state);
  const portal = portalSelector(state);

  return {
    event: EventType.Click,
    content_type: getBrand(portal),
    content_list_type: "Installation method",
    content_title: `Learn More - ${action.payload}`,
    content_category: payTvPackage,
    content_id: tierTitle,
    content_name: tierTitle,
  };
};

const showMoreEvent: ShopEventDefinition = (action, state) => {
  const { popularHeading } = tierConfigSelector(state);
  const tierTitle = tierTitleSelector(state);
  const portal = portalSelector(state);

  return {
    event: EventType.Click,
    content_type: getBrand(portal),
    content_list_type: popularHeading,
    content_title: "Show More",
    content_category: payTvPackage,
    content_id: tierTitle,
    content_name: tierTitle,
  };
};

const upsellCancelledEvent: ShopEventDefinition = (action, state) => {
  const tierTitle = tierTitleSelector(state);
  const portal = portalSelector(state);

  return {
    event: EventType.Click,
    content_type: getBrand(portal),
    content_list_type: "Broadband Upsell Popup",
    content_title: "Cancel Popup",
    content_category: payTvPackage,
    content_id: tierTitle,
    content_name: tierTitle,
  };
};

const upsellFailedEvent: ShopEventDefinition = (action, state) => {
  const tierTitle = tierTitleSelector(state);
  const portal = portalSelector(state);

  return {
    event: EventType.Click,
    content_type: getBrand(portal),
    content_list_type: "Broadband Upsell Popup",
    content_title: "Proceed With TV Pack",
    content_category: payTvPackage,
    content_id: tierTitle,
    content_name: tierTitle,
  };
};

const upsellSucceededEvent: ShopEventDefinition = (action, state) => {
  const tierTitle = tierTitleSelector(state);
  const portal = portalSelector(state);

  return {
    event: EventType.Click,
    content_type: getBrand(portal),
    content_list_type: "Broadband Upsell Popup",
    content_title: "Proceed With Broadband",
    content_category: payTvPackage,
    content_id: tierTitle,
    content_name: tierTitle,
  };
};

const proceedEvent: ShopEventDefinition = (action, state) => {
  const { isSubscriber } = state.shop;
  const tierTitle = tierTitleSelector(state);
  const portal = portalSelector(state);

  return {
    event: EventType.Click,
    content_type: getBrand(portal),
    content_list_type: "Click on Proceed",
    content_title: "Proceed",
    content_category: payTvPackage,
    content_id: tierTitle,
    content_name: tierTitle,
    subscriber: isSubscriber ? Subscriber.Existing : Subscriber.New,
  };
};

const upsellBB: ShopEventDefinition<BbUpsellChoice> = (action, state) => {
  const {
    router: {
      location: { search },
    },
    shop: { urlQueryParameters },
  } = state;
  const portal = portalSelector(state);
  const urlQueryString = getShopUrlQueryString(urlQueryParameters, search);

  let content_title = "";
  switch (action.payload) {
    case BbUpsellChoice.INTERESTED:
      content_title = "Yes";
      break;
    case BbUpsellChoice.NOT_INTERESTED:
      content_title = "No";
      break;
  }
  if (content_title)
    return {
      event: EventType.Click,
      content_type: getBrand(portal),
      content_list_type: "Interested in Broadband Popup",
      content_title,
      content_id: `/${signUpFormPath}?${urlQueryString}`,
    };
};

const promoRibbonEvent: ShopEventDefinition<PromoLinkEventParams> = (action, state) => {
  const portal = portalSelector(state);
  return {
    event: EventType.Click,
    content_type: getBrand(portal),
    content_list_type: "Promo Ribbon",
    content_category: "NA",
    content_id: action.payload?.href,
    content_title: action.payload?.title,
  };
};

const promoBannerEvent: ShopEventDefinition<PromoLinkEventParams> = (action, state) => {
  const portal = portalSelector(state);
  return {
    event: EventType.Click,
    content_type: getBrand(portal),
    content_list_type: "Promo Banner",
    content_category: "NA",
    content_id: action.payload?.href,
    content_title: action.payload?.title,
  };
};

const existingCustomerEvent: ShopEventDefinition = (action, state) => {
  const { isSubscriber } = state.shop;
  const tierTitle = tierTitleSelector(state);
  const portal = portalSelector(state);

  return {
    event: EventType.Click,
    content_type: getBrand(portal),
    content_list_type: "Click on Login",
    content_title: "Login Now To Upgrade",
    content_category: "Pay TV Package",
    content_id: tierTitle,
    content_name: tierTitle,
    subscriber: isSubscriber ? Subscriber.Existing : Subscriber.New,
  };
};

const benefitHighlightEvent: ShopEventDefinition<string> = (action, state) => {
  const tierTitle = tierTitleSelector(state);
  const portal = portalSelector(state);

  return {
    event: EventType.Click,
    content_type: getBrand(portal),
    content_list_type: "Benefit Highlights",
    content_title: action.payload,
    content_category: payTvPackage,
    content_id: tierTitle,
    content_name: tierTitle,
  };
};

const compareDevicesEvent: ShopEventDefinition<string> = (action, state) => {
  const { deviceHeading } = tierConfigSelector(state);
  const tierTitle = tierTitleSelector(state);
  const portal = portalSelector(state);

  return {
    event: EventType.Click,
    content_type: getBrand(portal),
    content_list_type: deviceHeading,
    content_title: action.payload,
    content_category: payTvPackage,
    content_id: tierTitle,
    content_name: tierTitle,
  };
};

const shopEventsMap: EventsMap = {
  [trackViewPack.toString()]: viewPackEvent,
  [initPackWithoutSelectionSuccess.toString()]: viewPackEvent,
  [initPackSelectionSuccess.toString()]: combineEvents(viewPackEvent, initShopSuccessEvent),
  [updateSelectedPack.toString()]: combineEvents(
    packAddedEvent,
    packRemovedEvent,
    addOnsClearedEvent,
    devicePreselectedEvent,
    deviceChangedViaPackEvent,
    methodUpdateEvent
  ),
  [updateSelectedAddOns.toString()]: addOnUpdateEvent,
  [updateSelectedDevice.toString()]: combineEvents(deviceUpdateEvent, methodUpdateEvent),
  [updateSelectedMethod.toString()]: methodUpdateEvent,
  [updateSelectedSubscriber.toString()]: subscriberUpdateEvent,
  [trackMethodLearnMore.toString()]: methodLearnMoreEvent,
  [trackShowMorePopularAddOns.toString()]: showMoreEvent,
  [upsellBroadbandCancelCta.toString()]: upsellCancelledEvent,
  [upsellBroadbandProceedTvCta.toString()]: upsellFailedEvent,
  [upsellBroadbandProceedBbCta.toString()]: upsellSucceededEvent,
  [updateBbUpsellChoice.toString()]: upsellBB,
  [trackProceed.toString()]: proceedEvent,
  [trackPromoRibbonLink.toString()]: promoRibbonEvent,
  [trackPromoBannerLink.toString()]: promoBannerEvent,
  [trackExistingCustomer.toString()]: existingCustomerEvent,
  [trackBenefitHighlight.toString()]: benefitHighlightEvent,
  [trackCompareDevices.toString()]: compareDevicesEvent,
  [trackLogin.toString()]: loginEvent,
};

export default shopEventsMap;

function createEcommerceEvent(params: {
  mode: EcommerceMode;
  brand: Brand;
  listType?: string;
  products: EcommerceProduct[];
}) {
  return {
    event: `ecommerce_${params.mode}`,
    content_type: params.brand,
    content_list_type: params.listType,
    ecommerce: {
      [params.mode]: {
        products: params.products,
      },
    },
  };
}
