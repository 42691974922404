import { createSlice, isFulfilled, isPending, isRejectedWithValue, PayloadAction } from "@reduxjs/toolkit";
import { push, replace } from "connected-react-router";
import { getCookie, setCookie } from "acm-components/module/lib/cookie";

import {
  AgreeToTermsPayload,
  AppsMobileNumberConfig,
  AppsUserState,
  OfferIdMap,
  Smartcard,
  AstroAccounts,
  AstroAccount,
  OfferIdMapping,
  SelectedSmartcard,
  DisneyAddOnsConfig,
  ActivationSelection,
  PurchaseSelection,
  AppsErrors,
  CustomerTypeMap,
  SmartcardValidation,
  PurchaseEligibleIdMap,
} from "./types";
import { fetchAppsConfig } from "@ducks/config";
import {
  ACTIVATION_SELECTION,
  ACTIVATION_STATUS,
  ACTIVE_STATUS,
  APPS_CONFIG,
  APPS_ERRORS,
  APPS_MOBILE_NUMBER_CONFIG,
  cookie,
  DISNEY_ADDONS_CONFIG,
  PURCHASE_SELECTION,
} from "./constants";
import { AppThunk } from "@store";
import {
  disneyActivateCompletePath,
  disneyActivatePath,
  disneyActivatePendingPath,
  disneyContactPath,
  disneyPurchasePath,
  disneyWaitingPath,
  disneyPurchaseIneligiblePath,
} from "@constants/paths";
import { constructAppsLoginUrl } from "@selectors/ulm";
import { errorCodeUpdated } from "@ducks/apps/error";
import {
  activate,
  activatePurchase,
  checkEligibility,
  generateOtpForPurchase,
  purchase,
  validateActivation,
  validateActivationForDuplicatePurchase,
  validatePurchase,
  resync,
} from "@ducks/apps/disneyActions";
import {
  CustomerInfoBase,
  ValidateEligibilityCustomerInfo,
  SmartcardEligibility,
  ListEntitlementsCustomerInfo,
} from "../types";
import { errorCodes, reason, ulmWithoutForceLogin } from "../constants";
import {
  trackActivateSubscribeNowLink,
  trackDisneyTraffic,
  trackRetrySuccess,
  trackWaitingRetry,
} from "../analyticActions";
import { addSpaceAfterPunctuation, capitalizeSentence, getDomain } from "@lib/common";
import { DisneyPageType } from "../analytics";

const initialState: AppsUserState = {
  appsConfig: APPS_CONFIG,
  mobileConfig: APPS_MOBILE_NUMBER_CONFIG,
  disneyAddOnsConfig: DISNEY_ADDONS_CONFIG,
  activationSelection: ACTIVATION_SELECTION,
  purchaseSelection: PURCHASE_SELECTION,
  activationError: APPS_ERRORS,
  purchaseError: APPS_ERRORS,
  accounts: {
    activation: [],
    purchase: [],
  },
  availableSmartcards: {
    activation: 0,
    purchase: 0,
  },
  hasActivatedAccounts: false,
  hasPendingAccounts: false,
  hasAccountsWithoutSmartcardData: false,
  accountIds: "",
  entitlementToken: "",
  eligibilityToken: "",
  eligibilityStatus: {
    loading: false,
    success: false,
    error: false,
  },
  resyncStatus: {
    loading: false,
    success: false,
    error: false,
  },
  validatePurchaseStatus: {
    loading: false,
    success: false,
    error: false,
  },
  offerMapping: [],
  purchaseEligibleIdMapping: [],
  hasCompletedCheckingUser: false,
  isPlanModalOpen: false,
  isConfirmOrderModalOpen: false,
  purchaseValidateToken: "",
  customerTypeMapping: [],
  showWaitingPage: false,
  hasCompletedCheckingWaiting: false,
  hasInitUser: false,
};

const appsUserSlice = createSlice({
  name: "appsUser",
  initialState,
  reducers: {
    userInitiated(state) {
      state.hasInitUser = true;
    },
    planModalOpened(state) {
      state.isPlanModalOpen = true;
    },
    planModalClosed(state) {
      state.isPlanModalOpen = false;
    },
    activationMobilePrefixUpdated(state, action: PayloadAction<string>) {
      const prefix = action.payload;
      if (prefix !== state.activationSelection.mobilePrefix) {
        state.activationSelection.mobilePrefix = prefix;
        state.activationError.mobileErrorCode = "";
      }
      const { isRequired } = state.mobileConfig.prefix;
      state.activationError.mobilePrefix.hasRequiredError = isRequired && !prefix.trim();
    },
    purchaseMobilePrefixUpdated(state, action: PayloadAction<string>) {
      const prefix = action.payload;
      if (prefix !== state.purchaseSelection.mobilePrefix) {
        state.purchaseSelection.mobilePrefix = prefix;
        state.purchaseError.mobileErrorCode = "";
      }
      const { isRequired } = state.mobileConfig.prefix;
      state.purchaseError.mobilePrefix.hasRequiredError = isRequired && !prefix.trim();
    },
    activationMobileSuffixUpdated(state, action: PayloadAction<string>) {
      const suffix = action.payload;
      if (suffix !== state.activationSelection.mobileSuffix) {
        state.activationSelection.mobileSuffix = suffix;
        state.activationError.mobileErrorCode = "";
      }
      const { isRequired, regex } = state.mobileConfig.suffix;
      state.activationError.mobileSuffix.hasRequiredError = isRequired && !suffix.trim();
      state.activationError.mobileSuffix.hasInvalidError = !!suffix.trim() && !new RegExp(regex).test(suffix);
    },
    purchaseMobileSuffixUpdated(state, action: PayloadAction<string>) {
      const suffix = action.payload;
      if (suffix !== state.purchaseSelection.mobileSuffix) {
        state.purchaseSelection.mobileSuffix = suffix;
        state.purchaseError.mobileErrorCode = "";
      }
      const { isRequired, regex } = state.mobileConfig.suffix;
      state.purchaseError.mobileSuffix.hasRequiredError = isRequired && !suffix.trim();
      state.purchaseError.mobileSuffix.hasInvalidError = !!suffix.trim() && !new RegExp(regex).test(suffix);
    },
    activationPhoneConsentUpdated(state, action: PayloadAction<boolean>) {
      state.activationSelection.hasConsentedToUpdatePhone = action.payload;
    },
    purchasePhoneConsentUpdated(state, action: PayloadAction<boolean>) {
      state.purchaseSelection.hasConsentedToUpdatePhone = action.payload;
    },
    activationAgreeToTermsUpdated(state, action: PayloadAction<AgreeToTermsPayload>) {
      const { checked, index } = action.payload;
      const agreements = state.activationSelection.termsAgreements;
      agreements[index] = Number(checked);
      state.activationSelection.termsAgreements = agreements;
    },
    purchaseAgreeToTermsUpdated(state, action: PayloadAction<AgreeToTermsPayload>) {
      const { checked, index } = action.payload;
      const agreements = state.purchaseSelection.termsAgreements;
      agreements[index] = Number(checked);
      state.purchaseSelection.termsAgreements = agreements;
    },
    validateActivationForm(state, action: PayloadAction<string[]>) {
      const { mobileConfig, activationSelection, activationError } = state;
      const error = !isAllowedToProceedWithActivation(
        mobileConfig,
        activationSelection,
        activationError,
        action.payload
      );
      if (error !== state.activationError.showError) state.activationError.showError = true;
    },
    validatePurchaseForm(state, action: PayloadAction<string[]>) {
      const { mobileConfig, purchaseSelection, purchaseError } = state;
      const error = !isAllowedToProceedWithPurchase(mobileConfig, purchaseSelection, purchaseError, action.payload);
      if (error !== state.purchaseError.showError) state.purchaseError.showError = true;
    },
    activationSmartcardSelected(state, action: PayloadAction<string>) {
      const smcNo = action.payload;
      if (smcNo) {
        const selectedCard = getSelectedSmartcard(smcNo, state.accounts.activation);
        if (selectedCard) {
          state.activationSelection.smartcard = selectedCard;
          state.isPlanModalOpen = false;
        }
      }
    },
    purchaseSmartcardSelected(state, action: PayloadAction<string>) {
      const smcNo = action.payload;
      if (smcNo) {
        const selectedCard = getSelectedSmartcard(smcNo, state.accounts.purchase);
        if (selectedCard) {
          state.purchaseSelection.smartcard = selectedCard;
          state.isPlanModalOpen = false;
        }
      }
    },
    addOnSelected(state, action: PayloadAction<string>) {
      const addOnId = action.payload;
      if (state.purchaseSelection.addOnId !== addOnId) state.purchaseSelection.addOnId = addOnId;
    },
    confirmOrderModalOpened(state) {
      state.isConfirmOrderModalOpen = true;
    },
    confirmOrderModalClosed(state) {
      state.isConfirmOrderModalOpen = false;
    },
    resetValidatePurchaseState(state) {
      state.validatePurchaseStatus = {
        loading: false,
        error: false,
        success: false,
      };
      state.purchaseValidateToken = "";
    },
    waitingPageDisplay(state, action: PayloadAction<boolean>) {
      state.showWaitingPage = action.payload;
    },
    waitingConditionIsChecked(state) {
      state.hasCompletedCheckingWaiting = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAppsConfig.fulfilled, (state, action) => {
        const {
          apps: appsConfig,
          mobileNumber: mobileConfig,
          offerMapping,
          purchaseEligibleIdMapping,
          disneyAddOnsConfig,
          customerTypeMapping,
        } = action.payload;
        if (appsConfig) state.appsConfig = appsConfig;
        if (offerMapping) state.offerMapping = offerMapping;
        if (purchaseEligibleIdMapping) state.purchaseEligibleIdMapping = purchaseEligibleIdMapping;
        if (disneyAddOnsConfig) {
          state.disneyAddOnsConfig = disneyAddOnsConfig;
          state.purchaseSelection.addOnId = getPreselectedAddOn(disneyAddOnsConfig);
        }
        if (customerTypeMapping) state.customerTypeMapping = customerTypeMapping;
        if (mobileConfig) {
          state.mobileConfig = mobileConfig;
          const {
            prefix: { isRequired: isPrefixRequired },
            suffix: { isRequired: isSuffixRequired },
          } = mobileConfig;
          const preselectedPrefix = getPreselectedMobilePrefix(mobileConfig);
          const updatePhone = isDefaultUpdatePhone(mobileConfig);
          const hasPrefixRequiredError = isPrefixRequired && !preselectedPrefix;
          // Activation Flow
          state.activationSelection.mobilePrefix = preselectedPrefix;
          state.activationError.mobilePrefix.hasRequiredError = hasPrefixRequiredError;
          state.activationError.mobileSuffix.hasRequiredError = isSuffixRequired;
          state.activationError.mobileSuffix.hasInvalidError = false;
          state.activationSelection.hasConsentedToUpdatePhone = updatePhone;
          // Purchase Flow
          state.purchaseSelection.mobilePrefix = preselectedPrefix;
          state.purchaseError.mobilePrefix.hasRequiredError = hasPrefixRequiredError;
          state.purchaseError.mobileSuffix.hasRequiredError = isSuffixRequired;
          state.purchaseError.mobileSuffix.hasInvalidError = false;
          state.purchaseSelection.hasConsentedToUpdatePhone = updatePhone;
        }
      })
      .addCase(checkEligibility.pending, (state) => {
        state.eligibilityStatus.loading = true;
        state.eligibilityStatus.success = false;
        state.eligibilityStatus.error = false;
      })
      .addCase(checkEligibility.rejected, (state, action) => {
        state.eligibilityStatus.loading = false;
        state.eligibilityStatus.success = false;
        state.eligibilityStatus.error = true;
        if (action.payload) {
          state.eligibilityStatus.errorCode = action.payload.errorCode;
          state.entitlementToken = action.payload.entitlementToken ?? "";
          state.accountIds = action.payload.accountId ?? "";
        }
      })
      .addCase(checkEligibility.fulfilled, (state, action) => {
        state.eligibilityStatus.loading = false;
        state.eligibilityStatus.success = true;
        state.eligibilityStatus.error = false;
        const { eligibilityToken, customerInfo } = action.payload;
        state.accountIds = customerInfo.map((account) => account.accountId).join(",");
        state.eligibilityToken = eligibilityToken;
        const accounts = getAccounts(
          customerInfo,
          state.offerMapping,
          state.customerTypeMapping,
          state.purchaseEligibleIdMapping
        );
        state.accounts = accounts;
        state.availableSmartcards = {
          activation: totalAvailableSmartcards(accounts.activation),
          purchase: totalAvailableSmartcards(accounts.purchase),
        };
        const defaultActivationSmartcard = getDefaultSmartcard(accounts.activation);
        if (defaultActivationSmartcard) state.activationSelection.smartcard = defaultActivationSmartcard;
        const defaultPurchaseSmartcard = getDefaultSmartcard(accounts.purchase);
        if (defaultPurchaseSmartcard) state.purchaseSelection.smartcard = defaultPurchaseSmartcard;
        state.hasActivatedAccounts = hasAccountsWithCertainStatus(customerInfo, isActive);
        state.hasPendingAccounts = hasAccountsWithCertainStatus(customerInfo, isInProgress);
        state.hasAccountsWithoutSmartcardData = hasAccountsWithoutSmartcardData(customerInfo);
      })
      .addCase(resync.pending, (state) => {
        state.resyncStatus = {
          loading: true,
          success: false,
          error: false,
        };
      })
      .addCase(resync.rejected, (state, action) => {
        state.resyncStatus = {
          loading: false,
          success: false,
          error: true,
        };
      })
      .addCase(resync.fulfilled, (state, action) => {
        state.resyncStatus = {
          loading: false,
          success: true,
          error: false,
        };
      })
      .addCase(validateActivation.rejected, (state, action) => {
        if (action.payload) {
          const { errorCode } = action.payload;
          if (errorCode && errorCode === errorCodes.phoneAlreadyUsed) state.activationError.mobileErrorCode = errorCode;
        }
      })
      .addCase(validateActivationForDuplicatePurchase.rejected, (state, action) => {
        if (action.payload) {
          const { errorCode } = action.payload;
          if (errorCode && errorCode === errorCodes.phoneAlreadyUsed) state.purchaseError.mobileErrorCode = errorCode;
        }
      })
      .addCase(activate.rejected, (state, action) => {
        if (action.payload) {
          const { errorCode } = action.payload;
          if (errorCode && errorCode === errorCodes.phoneAlreadyUsed) state.activationError.mobileErrorCode = errorCode;
        }
      })
      .addCase(validatePurchase.pending, (state) => {
        state.validatePurchaseStatus.loading = true;
        state.validatePurchaseStatus.success = false;
        state.validatePurchaseStatus.error = false;
      })
      .addCase(validatePurchase.rejected, (state, action) => {
        state.validatePurchaseStatus.loading = false;
        state.validatePurchaseStatus.success = false;
        state.validatePurchaseStatus.error = true;
        if (action.payload) {
          const { errorCode, eligibilityToken } = action.payload;
          if (errorCode) {
            state.validatePurchaseStatus.errorCode = errorCode;
            switch (errorCode) {
              case errorCodes.phoneAlreadyUsed:
                state.purchaseError.mobileErrorCode = errorCode;
                break;
              case errorCodes.duplicatePurchaseValidation:
                state.eligibilityToken = eligibilityToken ?? "";
                break;
              default:
                break;
            }
          }
        }
      })
      .addCase(validatePurchase.fulfilled, (state, action) => {
        state.validatePurchaseStatus.loading = false;
        state.validatePurchaseStatus.success = true;
        state.validatePurchaseStatus.error = false;
        state.purchaseValidateToken = action.payload.purchaseValidateToken;
        state.isConfirmOrderModalOpen = true;
      })
      .addCase(generateOtpForPurchase.pending, (state) => {
        state.isConfirmOrderModalOpen = false;
      })
      .addCase(purchase.rejected, (state, action) => {
        if (action.payload) {
          const { errorCode } = action.payload;
          if (errorCode && errorCode === errorCodes.phoneAlreadyUsed) state.purchaseError.mobileErrorCode = errorCode;
        }
      })
      .addCase(activatePurchase.rejected, (state, action) => {
        if (action.payload) {
          const { errorCode } = action.payload;
          if (errorCode && errorCode === errorCodes.phoneAlreadyUsed) state.purchaseError.mobileErrorCode = errorCode;
        }
      });
  },
});

export const {
  userInitiated,
  planModalOpened,
  planModalClosed,
  activationMobilePrefixUpdated,
  purchaseMobilePrefixUpdated,
  activationMobileSuffixUpdated,
  purchaseMobileSuffixUpdated,
  activationPhoneConsentUpdated,
  purchasePhoneConsentUpdated,
  activationAgreeToTermsUpdated,
  purchaseAgreeToTermsUpdated,
  validateActivationForm,
  validatePurchaseForm,
  activationSmartcardSelected,
  purchaseSmartcardSelected,
  addOnSelected,
  confirmOrderModalOpened,
  confirmOrderModalClosed,
  resetValidatePurchaseState,
  waitingPageDisplay,
  waitingConditionIsChecked,
} = appsUserSlice.actions;

export default appsUserSlice.reducer;

/* Thunks */
export const setWaitingWindowCookie = (): AppThunk => async (dispatch, getState) => {
  const { waitingRetryDuration } = getState().appsUser.appsConfig;
  const domain = getDomain();
  setCookie(
    cookie.waitingWindow,
    Date.now() + waitingRetryDuration * 1000,
    waitingRetryDuration / 60,
    false,
    domain,
    true
  );
};

export const initAppsUser = (): AppThunk => async (dispatch, getState) => {
  const {
    ulm,
    router: {
      location: { pathname },
    },
    appsUser: {
      appsConfig: { resyncRetryDuration },
      eligibilityStatus: { success: alreadyInit },
    },
  } = getState();
  dispatch(userInitiated());
  if (alreadyInit) return;

  const loginUrl = constructAppsLoginUrl(ulm, pathname);

  const { isLoggedIn, is2faUser, isUlmHandlerTriggered, userContactId } = ulm;
  if (isLoggedIn) {
    if (!is2faUser) {
      dispatch(
        trackDisneyTraffic({
          screen_name: errorCodes.unlinkedAccount,
          reason: reason.missingInfo,
        })
      );
      dispatch(errorCodeUpdated(errorCodes.unlinkedAccount));
    } else {
      // Check if waiting period for entitlement
      const waitingWindowCookie = getCookie(cookie.waitingWindow);
      // Check if user changed identity
      const contactIdCookie = getCookie(cookie.contactId);
      if (waitingWindowCookie && contactIdCookie === userContactId) dispatch(replace(`/${disneyWaitingPath}`));
      else {
        const result = await dispatch(checkEligibility(true));
        if (isFulfilled(result)) {
          const path = getState().router.location.pathname;
          const { activation, purchase } = getState().appsUser.availableSmartcards;
          // Is eligible for activation or purchase
          if (activation || purchase) {
            switch (path) {
              case `/${disneyActivatePath}`:
              case `/${disneyActivatePath}/`:
                // If on Activate page, switch to Purchase page if no SMCs for activation
                if (!activation) dispatch(replace(`/${disneyPurchasePath}`));
                break;
              case `/${disneyPurchasePath}`:
              case `/${disneyPurchasePath}/`:
                // If on Purchase page, switch to Activation page if no SMCs for purchase
                if (!purchase) dispatch(replace(`/${disneyActivatePath}`));
                break;
            }
          }
          // Has activated accounts
          else if (getState().appsUser.hasActivatedAccounts) dispatch(replace(`/${disneyActivateCompletePath}`));
          // Has pending activation accounts
          else if (getState().appsUser.hasPendingAccounts) dispatch(replace(`/${disneyActivatePendingPath}`));
          // is ineligible
          else if (getState().appsUser.availableSmartcards) dispatch(replace(`/${disneyPurchaseIneligiblePath}`));
        } else if (isRejectedWithValue(result)) {
          if (result.payload?.contactForm) {
            // Is BCP scenario
            const resyncResult = await dispatch(resync());
            if (!isPending(resyncResult)) {
              const domain = getDomain();
              dispatch(setWaitingWindowCookie());
              setCookie(cookie.resync, 1, resyncRetryDuration / 60, false, domain, true);
              setCookie(cookie.contactId, userContactId, 7 * 24 * 60, false, domain, true); //set to login refresh token cookie duration of 7 days

              dispatch(replace(`/${disneyWaitingPath}`));
            }
          }
          // All other errors are handled by error redux slice
        }
      }
    }
  } else if (!isUlmHandlerTriggered) {
    dispatch(
      trackDisneyTraffic({
        screen_name: ulmWithoutForceLogin,
        reason: reason.notLoggedIn,
      })
    );
    window.location.href = loginUrl;
  } else {
    dispatch(
      trackDisneyTraffic({
        screen_name: errorCodes.loginError,
        reason: reason.notLoggedIn,
      })
    );
    dispatch(errorCodeUpdated(errorCodes.loginError));
  }
};

export const choosePurchaseFlow = (): AppThunk => (dispatch) => {
  dispatch(trackActivateSubscribeNowLink());
  dispatch(push(`/${disneyPurchasePath}`));
  dispatch(planModalClosed());
};

export const resetConfirmOrderModal = (): AppThunk => (dispatch, getState) => {
  const {
    appsOtp: { verificationStatus },
  } = getState();
  dispatch(confirmOrderModalClosed());
  if (!verificationStatus.success) dispatch(resetValidatePurchaseState());
};

export const checkEligibilityForWaiting = (): AppThunk => async (dispatch, getState) => {
  dispatch(trackWaitingRetry());
  const result = await dispatch(checkEligibility());
  if (isFulfilled(result)) {
    const { availableSmartcards, hasActivatedAccounts, hasPendingAccounts } = getState().appsUser;
    const { activation, purchase } = availableSmartcards;
    if (activation) {
      dispatch(trackRetrySuccess(DisneyPageType.Activate));
      dispatch(replace(`/${disneyActivatePath}`));
    } else if (purchase) {
      dispatch(trackRetrySuccess(DisneyPageType.Purchase));
      dispatch(replace(`/${disneyPurchasePath}`));
    } else if (hasActivatedAccounts) {
      dispatch(trackRetrySuccess(DisneyPageType.ActivateComplete));
      dispatch(replace(`/${disneyActivateCompletePath}`));
    } else if (hasPendingAccounts) {
      dispatch(trackRetrySuccess(DisneyPageType.ActivatePending));
      dispatch(replace(`/${disneyActivatePendingPath}`));
    }
  } else if (isRejectedWithValue(result)) {
    if (result.payload) {
      const { contactForm } = result.payload;
      if (contactForm) dispatch(replace(`/${disneyContactPath}`));
    }
  }
};

export const initWaitingPage = (): AppThunk => (dispatch, getState) => {
  const {
    ulm: { isLoggedIn, userContactId },
  } = getState();
  dispatch(userInitiated());
  const waitingWindowCookie = getCookie(cookie.waitingWindow);
  const contactIdCookie = getCookie(cookie.contactId);
  let showPage = false;
  if (isLoggedIn && waitingWindowCookie && contactIdCookie === userContactId) {
    const expiryTime = Number(waitingWindowCookie);
    const durationLeft = expiryTime - Date.now();
    if (!isNaN(durationLeft)) {
      if (durationLeft > 0) showPage = true;
    } else {
      dispatch(setWaitingWindowCookie());
      showPage = true;
    }
  }
  dispatch(waitingPageDisplay(showPage));
  dispatch(waitingConditionIsChecked());
};

/* Utility functions */

function isDefaultUpdatePhone(config: AppsMobileNumberConfig): boolean {
  const { enableUpdatePhone, requireUserConsentToUpdatePhone } = config;
  return enableUpdatePhone && !requireUserConsentToUpdatePhone ? true : false;
}

function getPreselectedMobilePrefix(config: AppsMobileNumberConfig): string {
  const { preselectedValue, items } = config.prefix;
  if (preselectedValue) {
    const preselectedPrefix = items.find(({ value }) => value === preselectedValue)?.value;
    if (preselectedPrefix) return preselectedPrefix;
  }
  return "";
}

function getPreselectedAddOn(config: DisneyAddOnsConfig): string {
  const { preselectedAddOnId, addOns } = config;
  if (preselectedAddOnId) {
    const preselectedAddOn = addOns.find(({ id }) => id === preselectedAddOnId);
    if (preselectedAddOn) return preselectedAddOnId;
  }
  return "";
}

function hasAgreedToAllTerms(terms: string[], agreements: number[]) {
  const numberOfTerms = terms.length;
  const numberOfAgreements = agreements.reduce((acc, agree) => {
    agree && (acc += 1);
    return acc;
  }, 0);
  return numberOfTerms === numberOfAgreements;
}

export function isMobileSuffixValid(value: string, config: AppsMobileNumberConfig) {
  const { isRequired, regex } = config.suffix;
  let valid = true;
  if (isRequired) valid = valid && !!value.trim();
  if (regex) valid = valid && new RegExp(regex).test(value);
  return valid;
}

export function isMobileNumberValid(
  userSelection: ActivationSelection,
  errors: AppsErrors,
  config: AppsMobileNumberConfig
) {
  const { mobilePrefix, mobileSuffix } = userSelection;
  const { mobileErrorCode } = errors;
  const {
    prefix: { isRequired: isPrefixRequired },
  } = config;
  let valid = true;
  if (mobileErrorCode) valid = false;
  if (isPrefixRequired) valid = valid && !!mobilePrefix.trim();
  return valid && isMobileSuffixValid(mobileSuffix, config);
}

export function isAllowedToProceedWithActivation(
  config: AppsMobileNumberConfig,
  userSelection: ActivationSelection,
  errors: AppsErrors,
  activationTerms: string[]
): boolean {
  const { termsAgreements, smartcard } = userSelection;
  return (
    isMobileNumberValid(userSelection, errors, config) &&
    hasAgreedToAllTerms(activationTerms, termsAgreements) &&
    !!smartcard
  );
}

export function isAllowedToProceedWithPurchase(
  config: AppsMobileNumberConfig,
  userSelection: PurchaseSelection,
  errors: AppsErrors,
  activationTerms: string[]
): boolean {
  const { termsAgreements, smartcard, addOnId } = userSelection;
  return (
    isMobileNumberValid(userSelection, errors, config) &&
    hasAgreedToAllTerms(activationTerms, termsAgreements) &&
    !!smartcard &&
    !!addOnId
  );
}

function getPlanName(smartcard: SmartcardEligibility, offerIdMap: OfferIdMap): string {
  let plan: null | OfferIdMapping = null;
  for (const { assetid } of smartcard.entitlements) {
    const map = offerIdMap.find(({ offerId }) => offerId === assetid);
    if (map) {
      plan = map;
      break;
    }
  }
  return plan?.name ?? "";
}

function getEligibleForPurchase(
  smartcard: SmartcardEligibility,
  purchaseEligibleIdMap: PurchaseEligibleIdMap
): boolean {
  let found = false;
  for (const { assetid } of smartcard.entitlements) {
    const map = purchaseEligibleIdMap.find(({ offerId }) => offerId === assetid);
    if (map) {
      found = true;
      break;
    }
  }
  return found;
}

function getCustomerTypeName(customerType: string, customerTypeMap: CustomerTypeMap): string {
  return customerTypeMap.find((c) => c.customerType === customerType.toUpperCase())?.name ?? "";
}

export function isAvailable(activationStatus: string): boolean {
  return activationStatus.toUpperCase() === ACTIVATION_STATUS.available;
}

export function isActive(activationStatus: string): boolean {
  return activationStatus.toUpperCase() === ACTIVATION_STATUS.active;
}

export function isInProgress(activationStatus: string): boolean {
  return activationStatus.toUpperCase() === ACTIVATION_STATUS.inProgress;
}

function isValidForActivation(smartcard: SmartcardEligibility): SmartcardValidation {
  const { isEligibleForDisney, activationStatus } = smartcard;
  const activating = isInProgress(activationStatus);
  const activated = isActive(activationStatus);
  // For Activation flow, list both available, in progress, and already activated SMCs
  const valid = isEligibleForDisney ? true : activating || activated ? true : false;
  return { valid, activating, activated };
}

function isValidForPurchase(smartcard: SmartcardEligibility): SmartcardValidation {
  const { isEligibleForDisney, activationStatus } = smartcard;
  // For Purchase flow, list only available SMCs
  const valid = !isEligibleForDisney && isAvailable(activationStatus);
  return { valid, activating: false, activated: false };
}

function getSmartcards(
  customerInfo: ValidateEligibilityCustomerInfo,
  offerIdMap: OfferIdMap,
  customerTypeMap: CustomerTypeMap,
  validationFunction: (smartcard: SmartcardEligibility) => SmartcardValidation
): Smartcard[] {
  const { smartcards, customerType } = customerInfo;
  const customerTypeName = getCustomerTypeName(customerType, customerTypeMap);

  return Object.entries(smartcards).reduce((acc, [smartcardNumber, info]) => {
    const { apId, packId } = info;
    const { valid, activating, activated } = validationFunction(info);

    if (valid) {
      const planName = getPlanName(info, offerIdMap);
      acc.push({
        smartcardNumber,
        apId,
        name: customerTypeName || planName,
        packId: packId ?? "",
        activating,
        activated,
      });
    }
    return acc;
  }, [] as Smartcard[]);
}

function getPurchaseSmartcards(
  customerInfo: ValidateEligibilityCustomerInfo,
  offerIdMap: OfferIdMap,
  customerTypeMap: CustomerTypeMap,
  validationFunction: (smartcard: SmartcardEligibility) => SmartcardValidation,
  purchaseEligibleIdMap: PurchaseEligibleIdMap
): Smartcard[] {
  const { smartcards, customerType } = customerInfo;
  const customerTypeName = getCustomerTypeName(customerType, customerTypeMap);

  return Object.entries(smartcards).reduce((acc, [smartcardNumber, info]) => {
    const { apId, packId } = info;
    const { valid, activating, activated } = validationFunction(info);
    const purchaseEligible = getEligibleForPurchase(info, purchaseEligibleIdMap);
    if (valid && purchaseEligible) {
      const planName = getPlanName(info, offerIdMap);
      acc.push({
        smartcardNumber,
        apId,
        name: customerTypeName || planName,
        packId: packId ?? "",
        activating,
        activated,
      });
    }
    return acc;
  }, [] as Smartcard[]);
}

export function isAccountEntitled(account: CustomerInfoBase): boolean {
  const { enabled, status } = account;
  return enabled && status.toUpperCase() === ACTIVE_STATUS;
}

function hasAccountsWithCertainStatus(
  customerInfo: ValidateEligibilityCustomerInfo[],
  statusCheckFunction: (activationStatus: string) => boolean
) {
  return customerInfo.some(
    (info) =>
      isAccountEntitled(info) &&
      Object.values(info.smartcards).some(({ activationStatus }) => statusCheckFunction(activationStatus))
  );
}

function getAccounts(
  customerInfo: ValidateEligibilityCustomerInfo[],
  offerIdMap: OfferIdMap,
  customerTypeMap: CustomerTypeMap,
  purchaseEligibleIdMap: PurchaseEligibleIdMap
): AstroAccounts {
  const activation: AstroAccount[] = [];
  const purchase: AstroAccount[] = [];
  for (const info of customerInfo) {
    const { accountId, displayAccountId, address: _address } = info;
    if (isAccountEntitled(info)) {
      const address = _address ? formatAddress(_address) : undefined;
      const activationSmartcards = getSmartcards(info, offerIdMap, customerTypeMap, isValidForActivation);
      const purchaseSmartcards = getPurchaseSmartcards(
        info,
        offerIdMap,
        customerTypeMap,
        isValidForPurchase,
        purchaseEligibleIdMap
      );
      if (activationSmartcards.length > 0)
        activation.push({
          accountId,
          displayAccountId,
          address,
          smartcards: activationSmartcards,
        });

      if (purchaseSmartcards.length > 0)
        purchase.push({
          accountId,
          displayAccountId,
          address,
          smartcards: purchaseSmartcards,
        });
    }
  }
  return { activation, purchase };
}

function getSelectedSmartcard(smc: string, accounts: AstroAccount[]): SelectedSmartcard | null {
  let smartcard: SelectedSmartcard | null = null;
  for (const { accountId, displayAccountId, smartcards } of accounts) {
    for (const { smartcardNumber, apId, name, packId } of smartcards) {
      if (smartcardNumber === smc) {
        smartcard = {
          accountId,
          displayAccountId,
          smartcardNumber,
          apId,
          name,
          packId,
        };
      }
    }
  }
  return smartcard;
}

export function totalAvailableSmartcards(accounts: AstroAccount[]) {
  return accounts.reduce((acc, account) => {
    for (const card of account.smartcards) {
      if (!card.activated && !card.activating) ++acc;
    }
    return acc;
  }, 0 as number);
}

/* If only one smartcard available - preselect it */
export function getDefaultSmartcard(accounts: AstroAccount[]): SelectedSmartcard | null {
  const { totalAvailable, card } = accounts.reduce(
    (acc, account) => {
      for (const c of account.smartcards) {
        if (!c.activated && !c.activating) {
          ++acc.totalAvailable;
          acc.card = {
            ...c,
            accountId: account.accountId,
            displayAccountId: account.displayAccountId,
          };
        }
      }
      return acc;
    },
    { totalAvailable: 0, card: null } as {
      totalAvailable: number;
      card: null | SelectedSmartcard;
    }
  );
  if (totalAvailable === 1 && card) return card;
  return null;
}

function hasAccountsWithoutSmartcardData(customerInfo: ListEntitlementsCustomerInfo[]): boolean {
  return !!customerInfo.find((account) => isAccountEntitled(account) && !isAccountWithSmartcardData(account));
}

export function isAccountWithSmartcardData(info: ListEntitlementsCustomerInfo): boolean {
  return info.smartcards && Object.keys(info.smartcards).length > 0;
}

function formatAddress(address: string): string {
  return capitalizeSentence(addSpaceAfterPunctuation(address));
}
