import {
  ShopEventDefinition,
  EventType,
  getBrand,
  createEcommerceEvent,
  EcommerceMode,
  getProducts,
} from "@ducks/analytics";
import { EventsMap } from "redux-beacon";
import { viewOtpModal, trackResendOtp, trackEditMobileNumber, trackVerifyOtp } from ".";
import { portalSelector } from "@selectors/portalType";

const OTP_LIST_TYPE = "Request OTP";
const OTP_CATEGORY = "6-digit One Time Pin";

/* Events definitions */
const otpModalDisplayEvent: ShopEventDefinition = (action, state) => {
  const portal = portalSelector(state);

  return createEcommerceEvent({
    mode: EcommerceMode.Checkout,
    brand: getBrand(portal),
    listType: OTP_LIST_TYPE,
    products: getProducts(state),
    actionStep: 2,
  });
};

const otpResendEvent: ShopEventDefinition = (action, state) => {
  const portal = portalSelector(state);
  return {
    event: EventType.Click,
    content_type: getBrand(portal),
    content_list_type: OTP_LIST_TYPE,
    content_category: OTP_CATEGORY,
    content_title: "Resend",
  };
};

const otpEditMobileNumberEvent: ShopEventDefinition = (action, state) => {
  const portal = portalSelector(state);
  return {
    event: EventType.Click,
    content_type: getBrand(portal),
    content_list_type: OTP_LIST_TYPE,
    content_category: OTP_CATEGORY,
    content_title: "Edit Mobile Number",
  };
};

const otpVerifySuccessEvent: ShopEventDefinition = (action, state) => {
  const portal = portalSelector(state);
  return {
    event: EventType.Submit,
    content_type: getBrand(portal),
    content_list_type: OTP_LIST_TYPE,
    content_title: "Verify",
  };
};

/* Events map */
const otpEventsMap: EventsMap = {
  [viewOtpModal.toString()]: otpModalDisplayEvent,
  [trackResendOtp.toString()]: otpResendEvent,
  [trackEditMobileNumber.toString()]: otpEditMobileNumberEvent,
  [trackVerifyOtp.toString()]: otpVerifySuccessEvent,
};

export default otpEventsMap;
