import { AppConfig, MetaConfig, PackSelectionConfig } from "./types";
export { PRICE_CONFIG } from "@lib/calculator";

export const APP_CONFIG: AppConfig = {
  maintenanceMode: false,
  landingUrl: "https://product.astro.com.my",
  landingUrlTv: "https://product.astro.com.my/tv",
  landingUrlBb: "https://product.astro.com.my/broadband",
};

export const PACK_SELECTION_CONFIG: PackSelectionConfig = {
  upsellUrl: "https://stg-eco-product.pink.cat/broadband#broadband-speed",
  subscriberProceedUrlBb: "https://product.astro.com.my/broadband/subscribe",
  manageMyAccountUrl: "https://selfservice.astro.com.my",
  tickedIcon: "",
  untickedIcon: "",
  tickedDisabledIcon: "",
  untickedIconFilled: "",
  listStyleIcon: "",
  bannerOverlayOffset: 0,
  bannerSwiperConfig: {},
  deviceSwiperConfig: {},
  triggerMethodForBbUpsell: "",
  helpMePlaceholderSpace: "",
  methodPlaceholderSpace: "",
};

export const META_CONFIG: MetaConfig = {
  titleTemplate: "",
  thumbnail: "https://static02.astro.com.my/astro/media/astromain/packagepromotion/astro_share_1.png",
  ogType: "article",
  twitterCard: "summary",
  twitterSite: "@astroshop",
  schemaMarkup: {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "Astro",
    logo: "https://acm-assets.eco.astro.com.my/images/astro-logo.svg",
    sameAs: [
      "https://www.facebook.com/Astro",
      "https://twitter.com/astroonline",
      "https://www.instagram.com/astromalaysia/",
      "https://www.youtube.com/user/astromalaysiatv",
      "https://www.linkedin.com/company/astro",
      "https://en.wikipedia.org/wiki/Astro_(television)",
      "https://www.astro.com.my/",
    ],
  },
  pages: {
    orderSummary: {
      title: "Order Summary | Astro Shop",
      metas: [{ name: "robots", content: "noindex, nofollow" }],
    },
    "404": {
      title: "404 | Astro Shop",
      metas: [{ name: "robots", content: "noindex, nofollow" }],
    },
    signUp: {
      title: "Sign Up | Astro Shop",
      metas: [{ name: "robots", content: "noindex, nofollow" }],
    },
    checkCoverage: {
      title: "Check Coverage | Astro Shop",
      metas: [{ name: "robots", content: "noindex, nofollow" }],
    },
    thankYou: {
      title: "Thank you | Astro Shop",
      metas: [{ name: "robots", content: "noindex, nofollow" }],
    },
    registerInterest: {
      title: "Register Interest | Astro Shop",
      metas: [{ name: "robots", content: "noindex, nofollow" }],
    },
  },
  domain: "",
  themeColor: "#e6007d",
};
