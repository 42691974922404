import { EventsMap } from "redux-beacon";
import { ShopEventDefinition, EventType, getBrand, LogoutModalActionType } from "../analytics";
import { portalSelector } from "@selectors/portalType";
import { trackLogoutModalActions } from ".";

const logoutModalEvent: ShopEventDefinition<LogoutModalActionType> = (action, state) => {
  const portal = portalSelector(state);

  return {
    event: EventType.Click,
    content_type: getBrand(portal),
    content_list_type: "Popup Logout",
    content_title: action.payload,
    content_id: action.payload,
  };
};

const ulmEventsMap: EventsMap = {
  [trackLogoutModalActions.toString()]: logoutModalEvent,
};

export default ulmEventsMap;
