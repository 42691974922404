import { createAsyncThunk } from "@reduxjs/toolkit";

import { AppState } from "@store";
import settings from "@settings";
import {
  DisneyBasicErrorResponse,
  DisneyGenerateLeadError,
  DisneyGenerateLeadRequest,
  DisneyGenerateLeadResponse,
  DisneyVerifyLeadRequest,
} from "./types";
import { APIErrorResponse, APIResponse, getErrorCode, getErrorDisplayCode, isErrorResponse } from "@lib/api";
import { errorCodes, generateLeadErrorValue, validationErrorTokenValue } from "./constants";
import { trackDisneyError } from "./analyticActions";
import { delayedFetch } from "./disneyActions";

export const generateDisneyLead = createAsyncThunk<
  string,
  void,
  { state: AppState; rejectValue: DisneyGenerateLeadError }
>("appsDisney/generateLead", async (request, thunkApi) => {
  let httpStatus = "";
  const {
    ulm: { ulmToken },
    appsContact: { formValues },
    appsLanguage: { fallbackErrorDisplayCode },
  } = thunkApi.getState();
  const apiEndpoint = settings.apiEndpoint.disneyGenerateLead;
  try {
    const body: DisneyGenerateLeadRequest = {
      title: formValues.salutation.value,
      name: formValues.name.value,
      email: formValues.email.value,
      phoneNumber: formValues.mobilePrefix.value + formValues.mobileSuffix.value,
      smc: formValues.smartcardNumber.value,
    };
    process.env.REACT_APP_DISNEY_USE_MOCK_OTP === "true" && (body.mock = true);

    const response = await delayedFetch(apiEndpoint, {
      method: "POST",
      headers: {
        Authorization: ulmToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    httpStatus = response.status.toString();
    const data = (await response.json()) as APIResponse<DisneyGenerateLeadResponse>;

    if (isErrorResponse(data.response)) {
      const errorCode = getErrorCode(data.response);
      const errorDisplayCode = getErrorDisplayCode(data.response);
      const emailError = isEmailValidationError(data.response);
      const error = { emailError, errorCode, errorDisplayCode };
      throw error;
    }
    return data.response.leadToken;
  } catch ({ errorCode: _errorCode, errorDisplayCode: _errorDisplayCode, emailError = false }) {
    const errorCode = _errorCode ?? errorCodes.genericError;
    const errorDisplayCode = _errorDisplayCode ?? fallbackErrorDisplayCode;
    thunkApi.dispatch(
      trackDisneyError({
        api_endpoint: apiEndpoint,
        error_code: errorCode,
        error_status: httpStatus ?? "",
      })
    );
    return thunkApi.rejectWithValue({ errorCode, errorDisplayCode, emailError, apiEndpoint });
  }
});

export const verifyDisneyLead = createAsyncThunk<
  void,
  string,
  { state: AppState; rejectValue: DisneyBasicErrorResponse }
>("appsDisney/verifyLead", async (otp, thunkApi) => {
  let httpStatus = "";
  const {
    ulm: { ulmToken },
    appsOtp: { leadToken },
    appsLanguage: { fallbackErrorDisplayCode },
  } = thunkApi.getState();
  const apiEndpoint = settings.apiEndpoint.disneyVerifyLead;
  try {
    const body: DisneyVerifyLeadRequest = {
      leadToken,
      otp,
    };
    process.env.REACT_APP_DISNEY_USE_MOCK_OTP === "true" &&
      otp === process.env.REACT_APP_DISNEY_MOCK_OTP &&
      (body.mock = true);

    const response = await delayedFetch(apiEndpoint, {
      method: "POST",
      headers: {
        Authorization: ulmToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    httpStatus = response.status.toString();

    const data = await response.json();
    if (isErrorResponse(data.response)) {
      const isTokenError = isTokenValidationError(data.response, validationErrorTokenValue.verifyLead);
      const errorCode = isTokenError ? errorCodes.validationErrorToken : getErrorCode(data.response);
      const errorDisplayCode = getErrorDisplayCode(data.response);
      const error = { errorCode, errorDisplayCode };
      throw error;
    }
    return;
  } catch ({ errorCode: _errorCode, errorDisplayCode: _errorDisplayCode }) {
    const errorCode = _errorCode ?? errorCodes.genericError;
    const errorDisplayCode = _errorDisplayCode ?? fallbackErrorDisplayCode;
    thunkApi.dispatch(
      trackDisneyError({
        api_endpoint: apiEndpoint,
        error_code: errorCode,
        error_status: httpStatus,
      })
    );
    return thunkApi.rejectWithValue({ errorCode, errorDisplayCode, apiEndpoint });
  }
});

/* Utility functions */

function isEmailValidationError(response: APIErrorResponse): boolean {
  const { errorCode, errorAttributes } = response.errors[0];
  if (errorCode === errorCodes.validationError)
    if (errorAttributes) {
      const { type, path, context, field, types } = errorAttributes;
      // Lambda error response format
      if (type === generateLeadErrorValue.emailType) return true;
      if (path && path[0] === generateLeadErrorValue.email) return true;
      if (context && context.key === generateLeadErrorValue.email) return true;
      // K8 error response format
      if (field && field[0] === generateLeadErrorValue.email) return true;
      if (types && types[0] === generateLeadErrorValue.emailType) return true;
    }
  return false;
}

export function isTokenValidationError(response: APIErrorResponse, tokenKey: string): boolean {
  const { errorCode, errorAttributes } = response.errors[0];
  if (errorCode === errorCodes.validationError)
    if (errorAttributes) {
      const { path, context, field } = errorAttributes;
      // Lambda error response format
      if (path && path[0] === tokenKey) return true;
      if (context && context.key === tokenKey) return true;
      // K8 error response format
      if (field && field[0] === tokenKey) return true;
    }
  return false;
}
