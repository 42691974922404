import { PayloadAction } from "@reduxjs/toolkit";
import { EventDefinition } from "redux-beacon";

import { AppState } from "@store";
import { ITEMTYPE } from "@lib/calculator";
import { tierTitleSelector } from "@selectors/packSelection";
import { portalSelector, PortalType } from "@selectors/portalType";

export const payTvPackage = "Pay TV Package";
export const payTvAddOn = "Pay TV Add-On";
export const payTvDevice = "Pay TV Device";
export const payTVMethod = "Pay TV Installation Method";

export const methodListType = "Select installation method";

export enum Brand {
  Shop = "Astro Shop",
  Broadband = "Astro Broadband",
}

export enum EcommerceMode {
  Add = "add",
  Remove = "remove",
  Detail = "detail",
  Checkout = "checkout",
}

export enum EventType {
  Click = "clickEvent",
  Dropdown = "dropdownEvent",
  Submit = "submitEvent",
  Nav = "navEvent",
}

export type LoginType = "Manage My Account" | "Login Now";
export type LogoutModalActionType = "Logout" | "Cancel";

export type SignUpSubmitEventDefinition<T = undefined> = EventDefinition<
  SignUpSubmitGtmEvent,
  Partial<PayloadAction<T>>,
  AppState
>;

export interface SignUpSubmitGtmEvent extends GtmEvent {
  id_type: string;
  gender: string;
  ethnic: string;
  language: string;
  residential_type: string;
  city: string;
  state: string;
  installation_date: string;
  installation_time: string;
}

export type ShopEventDefinition<T = undefined> = EventDefinition<
  GtmEvent | GtmEvent[] | undefined,
  Partial<PayloadAction<T>>,
  AppState
>;

export interface GtmEvent {
  event?: string;
  content_type?: string;
  content_list_type?: string;
  content_title?: string;
  content_category?: string;
  content_id?: string;
  content_name?: string;
  ecommerce?: Record<
    string,
    {
      actionField?: {
        step?: number;
        affiliation?: string;
        revenue?: string;
        tax?: string;
      };
      products: EcommerceProduct[];
    }
  >;
}

export interface EcommerceProduct {
  name?: string;
  id?: string;
  price?: string;
  brand?: string;
  category?: string;
  variant?: string;
  method?: string;
  quantity?: string;
}

export function getBrand(portal: PortalType) {
  return portal === PortalType.BB ? Brand.Broadband : Brand.Shop;
}

export function getProducts(state: AppState) {
  const {
    shop: {
      invoice: { id, items, speed: speedId },
      selectedPack,
    },
    config: { addOns, devices },
  } = state;
  const tierTitle = tierTitleSelector(state);
  const portal = portalSelector(state);

  const brand = getBrand(portal);
  const variant = `Added to ${tierTitle}`;

  /* Base pack */
  const baseItem = {
    name: tierTitle,
    id: items[id].pricePlanId?.split(",")[0] ?? "",
    price: (items[id].price + (speedId ? items[speedId].price : 0)).toFixed(2),
    brand,
    category: payTvPackage,
    variant: selectedPack?.title ?? "",
    quantity: "1",
  };
  const addOnItems = [];
  const deviceItems = [];

  /* Add-ons & devices */
  for (const [itemId, item] of Object.entries(items)) {
    switch (item.type) {
      case ITEMTYPE.addOn:
      case ITEMTYPE.mini:
        if (!item.isBundled && item.pricePlanId && addOns[itemId]) {
          const name = addOns[itemId].name;
          addOnItems.push({
            name,
            id: item.pricePlanId || name,
            price: item.price.toFixed(2),
            brand,
            category: payTvAddOn,
            variant,
            quantity: "1",
          });
        }
        break;
      case ITEMTYPE.device:
        if (devices[itemId]) {
          const name = devices[itemId].name;
          deviceItems.push({
            name,
            id: name,
            price: item.price.toFixed(2),
            brand,
            category: payTvDevice,
            variant,
            quantity: "1",
          });
        }
        break;
    }
  }
  return [baseItem, ...addOnItems, ...deviceItems];
}

export function createEcommerceEvent(params: {
  mode: EcommerceMode;
  brand: Brand;
  listType: string;
  products: EcommerceProduct[];
  actionStep: number;
}) {
  return {
    event: `ecommerce_${params.mode}`,
    content_type: params.brand,
    content_list_type: params.listType,
    ecommerce: {
      [params.mode]: {
        actionField: { step: params.actionStep },
        products: params.products,
      },
    },
  };
}
