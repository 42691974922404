import {
  ActivateCompleteLanguageConfig,
  ActivateLanguageConfig,
  AppsErrorMessageMap,
  ConfirmOrderModalLanguageConfig,
  ContactThankYouLanguageConfig,
  ContactLanguageConfig,
  HowToCheckModalLanguageConfig,
  MobileNumberLanguageConfig,
  MultiSmcModalLanguageConfig,
  OtpLanguageConfig,
  PurchaseLanguageConfig,
  SelectPlanLanguageConfig,
  ThankYouLanguageConfig,
  ThankYouMessage,
  WhatsNextLanguageConfig,
  WaitingLanguageConfig,
  PurchaseInEligibleConfig,
  UpgradePackConfig,
} from "./types";

export const SELECT_PLAN_LANGUAGE: SelectPlanLanguageConfig = {
  placeholder: "",
  smartcardsFound: "",
  errorMessage: "",
};

export const MOBILE_NUMBER_LANGUAGE: MobileNumberLanguageConfig = {
  title: "",
  descriptionWithUpdatePhoneHtml: "",
  descriptionWithoutUpdatePhoneHtml: "",
  prefixPlaceholder: "",
  suffixPlaceholder: "",
  errorMessage: {
    REQUIRED: "",
    INVALID: "",
  },
  updatePhoneConsentTermsHtml: "",
};

export const ACTIVATE_LANGUAGE: ActivateLanguageConfig = {
  title: "",
  descriptionHtml: "",
  selectPlanTitle: "",
  selectPlanDescriptionHtml: "",
  cancelButton: "",
  proceedButton: "",
  termsHtml: [],
};

export const PURCHASE_LANGUAGE: PurchaseLanguageConfig = {
  title: "",
  descriptionHtml: "",
  selectPlanTitle: "",
  selectPlanDescriptionHtml: "",
  subscribeButton: "",
  termsHtml: [],
};

export const PURCHASE_INELIGIBLE: PurchaseInEligibleConfig = {
  title: "",
  descriptionHtml: "",
  upgradeButton: "",
};

export const UPGRADE_PACK: UpgradePackConfig = {
  id: "",
  title: "",
  subtitleHtml: "",
  descriptionHtml: "",
};

export const APPS_ERROR_MESSAGE_LANGUAGE: AppsErrorMessageMap = {
  GENERIC_ERROR: {
    title: "Oops, something went wrong",
    messageHtml: "Apologies for the inconvenience, but rest assured we’re working on it. Please try again later.",
  },
  LOGIN_ERROR: {
    title: "Oops, something went wrong with your login attempt",
    messageHtml: "Apologies for the inconvenience, but rest assured we’re working on it. Please try again later.",
    button: {
      text: "Login",
    },
  },
  NOT_ENTITLED: {
    title: "Oops, you are not entitled for Disney+ Hotstar",
    messageHtml: "Apologies for the inconvenience",
  },
  SESSION_EXPIRED: {
    title: "Session Expired",
    messageHtml: "Your session has expired",
    button: {
      text: "Login Again",
    },
  },
  MAINTENANCE: {
    title: "Under maintenance",
    messageHtml: "Oops, we are down for scheduled maintenance right now. We will be back soon.",
    button: {
      text: "Back to home",
      url: "",
    },
  },
  NOT_FOUND: {
    title: "404",
    messageHtml: "Oops, the page you were looking for does not exist.",
  },
};

export const CONFIRM_ORDER_MODAL_LANGUAGE: ConfirmOrderModalLanguageConfig = {
  title: "",
  description: "",
  mobileNumber: "",
  orderDescription: "",
  contract: "",
  price: "",
  serviceTax: "",
  total: "",
  confirm: "",
};

export const MULTI_SMC_MODAL_LANGUAGE: MultiSmcModalLanguageConfig = {
  accountSectionTitle: "",
  accountNotListed: "",
  subscribeNow: "",
  inProgressHtml: "",
  activatedHtml: "",
  defaultName: "",
  smartcardFormat: "",
  whatsAppHelpline: "",
  whatsApp: "",
  activate: {
    title: "",
    descriptionHtml: "",
  },
  purchase: {
    title: "",
    descriptionHtml: "",
  },
};

export const HOW_TO_CHECK_MODAL_LANGUAGE: HowToCheckModalLanguageConfig = {
  enterModalText: "",
  title: "",
  description: "",
  imageUrl: "",
  imageAlt: "",
};

export const OTP_LANGUAGE: OtpLanguageConfig = {
  title: "",
  description: "",
  textfieldLabel: "",
  resend: "",
  resendIn: "",
  verify: "",
  editMobileNumber: "",
  errorMessage: {
    REQUIRED: "",
    INVALID: "",
  },
};

export const THANK_YOU_MESSAGE: ThankYouMessage = {
  title: "",
  descriptionHtml: "",
};

export const WHATS_NEXT_LANGUAGE: WhatsNextLanguageConfig = {
  title: "",
  descriptionHtml: "",
  images: [],
  buttonText: "",
};

export const THANK_YOU_LANGUAGE: ThankYouLanguageConfig = {
  activate: THANK_YOU_MESSAGE,
  purchase: {},
};

export const ACTIVATE_COMPLETE_LANGUAGE: ActivateCompleteLanguageConfig = {
  title: "",
  descriptionHtml: "",
  images: [],
  needHelpTitle: "",
  needHelpDescriptionHtml: "",
  buttonText: "",
};

export const CONTACT_LANGUAGE: ContactLanguageConfig = {
  tryAgainMessage: {
    title: "",
    descriptionHtml: "",
    buttonText: "",
    buttonUrl: "",
  },
  dividerText: "",
  form: {
    title: "",
    descriptionHtml: "",
    buttonText: "",
    fields: {
      fullName: {},
      salutation: {},
      name: {},
      smartcardNumber: {},
      email: {},
    },
  },
};

export const CONTACT_THANK_YOU_LANGUAGE: ContactThankYouLanguageConfig = {
  ...THANK_YOU_MESSAGE,
  buttonText: "",
};

export const WAITING_LANGUAGE: WaitingLanguageConfig = {
  titleHtml: "",
  descriptionHtml: "",
  buttonText: "",
  disabledButtonText: "",
};
