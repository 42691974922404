import { EventType, getBrand, payTvPackage, ShopEventDefinition } from "@ducks/analytics";
import { tierTitleSelector } from "@selectors/packSelection";
import { EventsMap } from "redux-beacon";
import { trackApply, trackReplace, trackInvalidSubmission } from ".";
import { portalSelector } from "@selectors/portalType";

const orderSummaryPromoCode = "Order Summary Promo Code";
const orderSummaryPromoCodePopup = "Order Summary Promo Code Popup";

/* Event definitions */
const applyReferralCodeEvent: ShopEventDefinition = (action, state) => {
  const {
    referral: { referralCode },
  } = state;
  const tierTitle = tierTitleSelector(state);
  const portal = portalSelector(state);

  return {
    event: EventType.Click,
    content_type: getBrand(portal),
    content_list_type: orderSummaryPromoCode,
    content_title: referralCode,
    content_category: payTvPackage,
    content_id: tierTitle,
    content_name: tierTitle,
  };
};
const replaceReferralCodeEvent: ShopEventDefinition<boolean> = (action, state) => {
  const {
    referral: { referralCode },
  } = state;
  const tierTitle = tierTitleSelector(state);
  const portal = portalSelector(state);

  return {
    event: EventType.Click,
    content_type: getBrand(portal),
    content_list_type: orderSummaryPromoCodePopup,
    content_title: `${action.payload ? `Confirm` : `Cancel`} Replace Promo Code - ${referralCode}`,
    content_category: payTvPackage,
    content_id: tierTitle,
    content_name: tierTitle,
  };
};
const invalidReferralCodeOnSubmitEvent: ShopEventDefinition = (action, state) => {
  const {
    referral: { appliedCode },
  } = state;
  const tierTitle = tierTitleSelector(state);
  const portal = portalSelector(state);

  return {
    event: EventType.Click,
    content_type: getBrand(portal),
    content_list_type: orderSummaryPromoCodePopup,
    content_title: `Invalid Promo Code - ${appliedCode}`,
    content_category: payTvPackage,
    content_id: tierTitle,
    content_name: tierTitle,
  };
};

/* Events map */
const referralEventsMap: EventsMap = {
  [trackApply.toString()]: applyReferralCodeEvent,
  [trackReplace.toString()]: replaceReferralCodeEvent,
  [trackInvalidSubmission.toString()]: invalidReferralCodeOnSubmitEvent,
};

export default referralEventsMap;
