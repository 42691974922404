import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  ConfigState,
  ConfigPayload,
  AppConfig,
  ITierConfig,
  IAddOnConfig,
  IDeviceConfig,
  MetaConfig,
  PackSelectionConfig,
  StreamingAppConfig,
  InstallationMethodMap,
  AppsConfigPayload,
} from "./types";
import * as constants from "./constants";
import { InputRegex } from "../types";
import { LanguageConfig } from "../language/types";
import { FormConfig } from "../signUp/types";
import { OtpConfig } from "../otp/types";
import { ProductInfoConfig } from "../productInfo/types";
import { AddressConfig } from "../address/types";
import { ThankYouConfig } from "../thankYou/types";
import { THANK_YOU } from "../thankYou/constants";
import { ResizerConfig } from "../resizer/types";
import { IPriceConfig } from "@lib/calculator";
import { OrderSummaryConfig } from "../orderSummary/types";
import { extractConfig, IFortressResponse } from "@lib/fortress";
import { AppsLanguagePayload } from "@ducks/apps/language/types";
import settings from "@settings";
import { AppState } from "@store";
import { disneyActivatePath } from "@constants/paths";

import { AppsMetaConfig } from "../apps/meta/types";
import { AppsUlmConfig } from "../ulm/types";
import {
  AppsConfig,
  AppsMobileNumberConfig,
  CustomerTypeMap,
  DisneyAddOnsConfig,
  OfferIdMap,
  PurchaseEligibleIdMap,
} from "@ducks/apps/user/types";
import { AppsOtpConfig } from "../apps/otp/types";
import { AppsContactConfig } from "../apps/contact/types";

export const getConfigs = createAsyncThunk<void, void, { state: AppState }>(
  "config/getConfigs",
  async (request, thunkApi) => {
    const { pathname } = thunkApi.getState().router.location;
    const dispatch = thunkApi.dispatch;

    const disneyAppsRegex = new RegExp(`/${disneyActivatePath}(/?|/.+)`);
    if (pathname.match(disneyAppsRegex)) dispatch(fetchAppsConfig());
    else dispatch(fetchConfig());
  }
);

export const fetchConfig = createAsyncThunk("config/fetchConfig", async () => {
  const response = await fetch(settings.config.url, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = (await response.json()) as IFortressResponse<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  const payload: ConfigPayload = {};

  const languageConfig = extractConfig<LanguageConfig>(settings.config.key.language, data);
  languageConfig && (payload.language = languageConfig);

  const appConfig = extractConfig<AppConfig>(settings.config.key.app, data);
  appConfig && (payload.app = appConfig);

  const packSelectionConfig = extractConfig<PackSelectionConfig>(settings.config.key.packSelection, data);
  packSelectionConfig && (payload.packSelection = packSelectionConfig);

  const streamingAppsConfig = extractConfig<StreamingAppConfig>(settings.config.key.streamingApps, data);
  streamingAppsConfig && (payload.streamingApps = streamingAppsConfig);

  const tierConfig = extractConfig<ITierConfig[]>(settings.config.key.tiers, data);
  tierConfig && (payload.tiers = tierConfig);

  const addOnsConfig = extractConfig<IAddOnConfig>(settings.config.key.addOns, data);
  addOnsConfig && (payload.addOns = addOnsConfig);

  const devicesConfig = extractConfig<IDeviceConfig>(settings.config.key.devices, data);
  devicesConfig && (payload.devices = devicesConfig);

  const installationMethodsConfig = extractConfig<InstallationMethodMap>(settings.config.key.installationMethods, data);
  installationMethodsConfig && (payload.installationMethods = installationMethodsConfig);

  const orderSummaryConfig = extractConfig<OrderSummaryConfig>(settings.config.key.orderSummary, data);
  orderSummaryConfig && (payload.orderSummary = orderSummaryConfig);

  const priceConfig = extractConfig<IPriceConfig>(settings.config.key.price, data);
  priceConfig && (payload.price = priceConfig);

  const metaConfig = extractConfig<MetaConfig>(settings.config.key.meta, data);
  metaConfig && (payload.meta = metaConfig);

  const formConfig = extractConfig<FormConfig>(settings.config.key.form, data);
  formConfig && (payload.form = formConfig);

  const otpConfig = extractConfig<OtpConfig>(settings.config.key.otp, data);
  otpConfig && (payload.otp = otpConfig);

  const addressConfig = extractConfig<AddressConfig>(settings.config.key.address, data);
  addressConfig && (payload.address = addressConfig);

  const regexConfig = extractConfig<InputRegex>(settings.config.key.regex, data);
  regexConfig && (payload.regex = regexConfig);

  const thankYouConfig = extractConfig<ThankYouConfig>(settings.config.key.thankYou, data);
  thankYouConfig && (payload.thankYou = thankYouConfig);

  const resizerConfig = extractConfig<ResizerConfig>(settings.config.key.resizer, data);
  resizerConfig && (payload.resizer = resizerConfig);

  const productInfoConfig = extractConfig<ProductInfoConfig>(settings.config.key.productInfo, data);
  productInfoConfig && (payload.productInfo = productInfoConfig);

  return payload;
});

export const fetchAppsConfig = createAsyncThunk("config/fetchAppsConfig", async () => {
  const response = await fetch(settings.appsConfig.url, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = (await response.json()) as IFortressResponse<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  const payload: AppsConfigPayload = {};

  const metaConfig = extractConfig<AppsMetaConfig>(settings.appsConfig.key.meta, data);
  metaConfig && (payload.meta = metaConfig);

  const languageConfig = extractConfig<AppsLanguagePayload>(settings.appsConfig.key.language, data);
  languageConfig && (payload.language = languageConfig);

  const ulmConfig = extractConfig<AppsUlmConfig>(settings.appsConfig.key.ulm, data);
  ulmConfig && (payload.ulm = ulmConfig);

  const mobileNumberConfig = extractConfig<AppsMobileNumberConfig>(settings.appsConfig.key.mobileNumber, data);
  mobileNumberConfig && (payload.mobileNumber = mobileNumberConfig);

  const appsConfig = extractConfig<AppsConfig>(settings.appsConfig.key.apps, data);
  appsConfig && (payload.apps = appsConfig);

  const offerMapConfig = extractConfig<OfferIdMap>(settings.appsConfig.key.offerMapping, data);
  offerMapConfig && (payload.offerMapping = offerMapConfig);

  const purchaseEligibleConfig = extractConfig<PurchaseEligibleIdMap>(
    settings.appsConfig.key.purchaseEligibleOfferId,
    data
  );
  purchaseEligibleConfig && (payload.purchaseEligibleIdMapping = purchaseEligibleConfig);

  const disneyAddonsConfig = extractConfig<DisneyAddOnsConfig>(settings.appsConfig.key.disneyAddOns, data);
  disneyAddonsConfig && (payload.disneyAddOnsConfig = disneyAddonsConfig);

  const otpConfig = extractConfig<AppsOtpConfig>(settings.appsConfig.key.otp, data);
  otpConfig && (payload.otp = otpConfig);

  const customerTypeMapConfig = extractConfig<CustomerTypeMap>(settings.appsConfig.key.customerTypeMapping, data);
  customerTypeMapConfig && (payload.customerTypeMapping = customerTypeMapConfig);

  const contactFormConfig = extractConfig<AppsContactConfig>(settings.appsConfig.key.contactForm, data);
  contactFormConfig && (payload.contactForm = contactFormConfig);

  return payload;
});

const initialState: ConfigState = {
  fetchStatus: {
    loading: false,
    error: false,
    success: false,
  },
  app: constants.APP_CONFIG,
  packSelection: constants.PACK_SELECTION_CONFIG,
  streamingApps: {},
  tiers: [],
  addOns: {},
  devices: {},
  installationMethods: {},
  price: constants.PRICE_CONFIG,
  meta: constants.META_CONFIG,
  thankYou: THANK_YOU,
};

const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchConfig.pending, (state) => {
        state.fetchStatus = {
          loading: true,
          error: false,
          success: false,
        };
      })
      .addCase(fetchConfig.rejected, (state) => {
        state.fetchStatus = {
          loading: false,
          error: true,
          success: false,
        };
      })
      .addCase(fetchConfig.fulfilled, (state, action) => {
        state.fetchStatus = {
          loading: false,
          error: false,
          success: true,
        };
        const {
          app,
          packSelection,
          streamingApps,
          tiers,
          addOns,
          devices,
          installationMethods,
          price,
          meta,
          thankYou,
        } = action.payload;
        app && (state.app = app);
        packSelection && (state.packSelection = packSelection);
        streamingApps && (state.streamingApps = streamingApps);
        tiers && (state.tiers = tiers);
        addOns && (state.addOns = addOns);
        devices && (state.devices = devices);
        installationMethods && (state.installationMethods = installationMethods);
        price && (state.price = price);
        meta && (state.meta = meta);
        thankYou && (state.thankYou = thankYou);
      })
      .addCase(fetchAppsConfig.pending, (state) => {
        state.fetchStatus = {
          loading: true,
          error: false,
          success: false,
        };
      })
      .addCase(fetchAppsConfig.rejected, (state) => {
        state.fetchStatus = {
          loading: false,
          error: true,
          success: false,
        };
      })
      .addCase(fetchAppsConfig.fulfilled, (state) => {
        state.fetchStatus = {
          loading: false,
          error: false,
          success: true,
        };
      });
  },
});

export default configSlice.reducer;
