import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppsErrorState } from "./types";
import {
  activate,
  activatePurchase,
  checkEligibility,
  generateOtpForPurchase,
  purchase,
  validateActivation,
  validateActivationForDuplicatePurchase,
  validatePurchase,
} from "@ducks/apps/disneyActions";
import { generateDisneyLead, verifyDisneyLead } from "../leadActions";
import { errorCodes } from "../constants";
import { fetchAppsConfig } from "@ducks/config";
import { APPS_ERROR_MESSAGE_LANGUAGE } from "../language/constants";
import { AppsErrorMessageMap } from "../language/types";

const initialState: AppsErrorState = {
  errorMessage: APPS_ERROR_MESSAGE_LANGUAGE,
  errorCode: "",
  errorDisplayCode: "",
  showErrorModal: false,
  apiEndpoint: "",
};

const appsErrorSlice = createSlice({
  name: "appsError",
  initialState,
  reducers: {
    errorModalOpened(state) {
      state.showErrorModal = true;
    },
    errorModalClosed(state) {
      state.showErrorModal = false;
    },
    errorCodeUpdated(state, action: PayloadAction<string>) {
      state.errorCode = action.payload;
    },
    resetError(state) {
      state.errorCode = "";
      state.errorDisplayCode = "";
      state.showErrorModal = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAppsConfig.fulfilled, (state, action) => {
        const { language } = action.payload;
        if (language) state.errorMessage = language.errorMessage;
      })
      .addCase(checkEligibility.pending, (state, action) => {
        state.showErrorModal = false;
      })
      .addCase(checkEligibility.rejected, (state, action) => {
        if (action.payload) {
          const { errorCode, errorDisplayCode, contactForm, apiEndpoint } = action.payload;
          if (!isErrorException(errorCode) && !contactForm) {
            state.errorCode = errorCode;
            state.errorDisplayCode = errorDisplayCode;
            state.showErrorModal = getShowAsPopupState(state.errorMessage, errorCode);
            state.apiEndpoint = apiEndpoint;
          }
        }
      })
      .addCase(validatePurchase.pending, (state, action) => {
        state.showErrorModal = false;
      })
      .addCase(validatePurchase.rejected, (state, action) => {
        if (action.payload) {
          const { errorCode, errorDisplayCode, contactForm, apiEndpoint } = action.payload;
          if (!isErrorException(errorCode) && !contactForm) {
            state.errorCode = errorCode;
            state.errorDisplayCode = errorDisplayCode;
            state.showErrorModal = getShowAsPopupState(state.errorMessage, errorCode);
            state.apiEndpoint = apiEndpoint;
          }
        }
      })
      .addCase(generateOtpForPurchase.pending, (state, action) => {
        state.showErrorModal = false;
      })
      .addCase(generateOtpForPurchase.rejected, (state, action) => {
        if (action.payload) {
          const { errorCode, errorDisplayCode, contactForm, apiEndpoint } = action.payload;
          if (!isErrorException(errorCode) && !contactForm) {
            state.errorCode = errorCode;
            state.errorDisplayCode = errorDisplayCode;
            state.showErrorModal = getShowAsPopupState(state.errorMessage, errorCode);
            state.apiEndpoint = apiEndpoint;
          }
        }
      })
      .addCase(purchase.pending, (state, action) => {
        state.showErrorModal = false;
      })
      .addCase(purchase.rejected, (state, action) => {
        if (action.payload) {
          const { errorCode, errorDisplayCode, contactForm, apiEndpoint } = action.payload;
          if (!isErrorException(errorCode) && !contactForm) {
            state.errorCode = errorCode;
            state.errorDisplayCode = errorDisplayCode;
            state.showErrorModal = getShowAsPopupState(state.errorMessage, errorCode);
            state.apiEndpoint = apiEndpoint;
          }
        }
      })
      .addCase(validateActivation.pending, (state, action) => {
        state.showErrorModal = false;
      })
      .addCase(validateActivation.rejected, (state, action) => {
        if (action.payload) {
          const { errorCode, errorDisplayCode, contactForm, apiEndpoint } = action.payload;
          if (!isErrorException(errorCode) && !contactForm) {
            state.errorCode = errorCode;
            state.errorDisplayCode = errorDisplayCode;
            state.showErrorModal = getShowAsPopupState(state.errorMessage, errorCode);
            state.apiEndpoint = apiEndpoint;
          }
        }
      })
      .addCase(activate.pending, (state, action) => {
        state.showErrorModal = false;
      })
      .addCase(activate.rejected, (state, action) => {
        if (action.payload) {
          const { errorCode, errorDisplayCode, contactForm, apiEndpoint } = action.payload;
          if (!isErrorException(errorCode) && !contactForm) {
            state.errorCode = errorCode;
            state.errorDisplayCode = errorDisplayCode;
            state.showErrorModal = getShowAsPopupState(state.errorMessage, errorCode);
            state.apiEndpoint = apiEndpoint;
          }
        }
      })
      .addCase(activatePurchase.pending, (state, action) => {
        state.showErrorModal = false;
      })
      .addCase(activatePurchase.rejected, (state, action) => {
        if (action.payload) {
          const { errorCode, errorDisplayCode, contactForm, apiEndpoint } = action.payload;
          if (!isErrorException(errorCode) && !contactForm) {
            state.errorCode = errorCode;
            state.errorDisplayCode = errorDisplayCode;
            state.showErrorModal = getShowAsPopupState(state.errorMessage, errorCode);
            state.apiEndpoint = apiEndpoint;
          }
        }
      })
      .addCase(validateActivationForDuplicatePurchase.pending, (state, action) => {
        state.showErrorModal = false;
      })
      .addCase(validateActivationForDuplicatePurchase.fulfilled, (state) => {
        state.errorCode = "";
        state.showErrorModal = false;
      })
      .addCase(validateActivationForDuplicatePurchase.rejected, (state, action) => {
        if (action.payload) {
          const { errorCode, errorDisplayCode, contactForm, apiEndpoint } = action.payload;
          if (!contactForm) {
            if (!isErrorException(errorCode)) {
              state.errorCode = errorCode;
              state.errorDisplayCode = errorDisplayCode;
              state.showErrorModal = getShowAsPopupState(state.errorMessage, errorCode);
              state.apiEndpoint = apiEndpoint;
            } else {
              // Clear the error because this action is only called when Validate Purchase API returns DUPLICATE_PURCHASE_VALIDATION error
              state.errorCode = "";
              state.errorDisplayCode = "";
              state.showErrorModal = false;
            }
          }
        }
      })
      .addCase(generateDisneyLead.pending, (state) => {
        state.showErrorModal = false;
      })
      .addCase(generateDisneyLead.rejected, (state, action) => {
        if (action.payload) {
          const { errorCode, errorDisplayCode, emailError, apiEndpoint } = action.payload;
          if (!isErrorException(errorCode) && !emailError) {
            state.errorCode = errorCode;
            state.errorDisplayCode = errorDisplayCode;
            state.showErrorModal = getShowAsPopupState(state.errorMessage, errorCode);
            state.apiEndpoint = apiEndpoint;
          }
        }
      })
      .addCase(verifyDisneyLead.pending, (state) => {
        state.showErrorModal = false;
      })
      .addCase(verifyDisneyLead.rejected, (state, action) => {
        if (action.payload) {
          const { errorCode, errorDisplayCode, apiEndpoint } = action.payload;
          if (errorCode && !isErrorException(errorCode)) {
            state.errorCode = errorCode;
            state.errorDisplayCode = errorDisplayCode;
            state.showErrorModal = getShowAsPopupState(state.errorMessage, errorCode);
            state.apiEndpoint = apiEndpoint;
          }
        }
      });
  },
});

export const { errorModalOpened, errorModalClosed, errorCodeUpdated, resetError } = appsErrorSlice.actions;

export default appsErrorSlice.reducer;

function isErrorException(errorCode: string): boolean {
  // List of error codes that are not handled using the error screen / error popup behaviour
  const excludedErrors = [
    errorCodes.phoneAlreadyUsed,
    errorCodes.failedToGenerateOtp,
    errorCodes.failedToVerifyOtp,
    errorCodes.validationErrorToken,
  ];
  return excludedErrors.includes(errorCode);
}

function getShowAsPopupState(errorMessage: AppsErrorMessageMap, errorCode: string): boolean {
  return !!(errorMessage[errorCode] ?? errorMessage.GENERIC_ERROR).showAsPopup;
}
