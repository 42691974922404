import { createMiddleware, EventsMap } from "redux-beacon";
import GoogleTagManager from "@redux-beacon/google-tag-manager";

import shopEventsMap from "@ducks/shop/events";
import orderSummaryEventsMap from "@ducks/orderSummary/events";
import signUpEventsMap from "@ducks/signUp/events";
import thankYouEventsMap from "@ducks/thankYou/events";
import otpEventsMap from "@ducks/otp/events";
import addressEventsMap from "@ducks/address/events";
import referralEventsMap from "@ducks/referral/events";
import productInfoEventsMap from "@ducks/productInfo/events";
import ulmEventsMap from "@ducks/ulm/events";
import appsEventsMap from "@ducks/apps/events";

const rootEventsMap: EventsMap = {
  ...shopEventsMap,
  ...orderSummaryEventsMap,
  ...signUpEventsMap,
  ...thankYouEventsMap,
  ...otpEventsMap,
  ...addressEventsMap,
  ...referralEventsMap,
  ...productInfoEventsMap,
  ...ulmEventsMap,
  ...appsEventsMap,
};

const gtm = GoogleTagManager();
const gtmMiddleware = createMiddleware(rootEventsMap, gtm);
export default gtmMiddleware;
