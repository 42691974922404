import { EventsMap } from "redux-beacon";

import {
  addressesFiltered,
  addressRejected,
  addressSelected,
  checkCoveragePageLoad,
  confirmAddress,
  keywordAdded,
  keywordRemoved,
  registerInterest,
  residentialTypeUpdated,
  searchButtonClick,
  trackCompleteRegisterInterest,
  trackNotListedOption,
  toggleRegisterInterest,
  trackTncLink,
} from ".";
import { ShopEventDefinition } from "@ducks/analytics";
import { abVariantSelector } from "@selectors/common";
import { AbTestVariant } from "@lib/abTest";

const pageLoadEvent: ShopEventDefinition = () => ({
  event: "ecommerce_coverage",
  content_type: "Astro Broadband",
  content_list_type: "Check Coverage",
});

const residentialTypeEvent: ShopEventDefinition = (action, prevState, nextState) => {
  const residentialType = nextState.address.fields.residentialType.value;
  const title = nextState.signUp.config.residentialType.items.find((item) => item.value === residentialType);
  if (title)
    return {
      event: "dropdownEvent",
      content_type: "Astro Broadband",
      content_list_type: "Check Coverage",
      content_category: "Residential Type",
      content_title: title.label,
    };
};

const searchAddressEvent: ShopEventDefinition = () => ({
  event: "searchEvent",
  content_type: "Astro Broadband",
  content_list_type: "Check Coverage",
  content_category: "Address Search",
  content_title: "Search Button",
});

const filterAddressEvent: ShopEventDefinition = () => ({
  event: "searchEvent",
  content_type: "Astro Broadband",
  content_list_type: "Check Coverage",
  content_category: "Address Search",
  content_title: "Search Box",
});

const filterAddressBySearchBoxEvent: ShopEventDefinition<string> = (action) => {
  // only trigger if the search term has more than 3 characters
  if (action.payload && action.payload.length > 3)
    return {
      event: "searchEvent",
      content_type: "Astro Broadband",
      content_list_type: "Check Coverage",
      content_category: "Address Search",
      content_title: "Search Box",
    };
};

const selectAddressEvent: ShopEventDefinition = () => ({
  event: "clickEvent",
  content_type: "Astro Broadband",
  content_list_type: "Check Coverage",
  content_category: "Address Search",
  content_title: "Select Address",
});

const addressNotListedEvent: ShopEventDefinition<boolean> = (action) => {
  if (action.payload)
    return {
      event: "clickEvent",
      content_type: "Astro Broadband",
      content_list_type: "Check Coverage",
      content_category: "Address Search",
      content_title: "My address is not listed",
    };
};

const rejectSelectedAddressEvent: ShopEventDefinition = () => ({
  event: "submitEvent",
  content_type: "Astro Broadband",
  content_list_type: "Check Coverage",
  content_title: "Cancel - Address Selected",
});

const addressFoundEvent: ShopEventDefinition = (action, state) => {
  const abVariant = abVariantSelector(state);
  const contentTitle =
    abVariant === AbTestVariant.Secondary ? "Confirm - Address Selected" : "Proceed - Address Selected";
  return {
    event: "submitEvent",
    content_type: "Astro Broadband",
    content_list_type: "Check Coverage",
    content_title: contentTitle,
  };
};

const registerInterestTncEvent: ShopEventDefinition<string> = (action) => {
  if (action.payload)
    return {
      event: "clickEvent",
      content_type: "Astro Broadband",
      content_list_type: "Check Coverage",
      content_category: "Register Interest",
      content_title: action.payload,
    };
};

const registerInterestEvent: ShopEventDefinition = (action, state) => {
  const abVariant = abVariantSelector(state);
  const contentTitle =
    abVariant === AbTestVariant.Secondary ? "Submit Register Interest" : "Submit Lead - Address Not Found";
  return {
    event: "submitEvent",
    content_type: "Astro Broadband",
    content_list_type: "Check Coverage",
    content_title: contentTitle,
  };
};

const weWillBeInTouchEvent: ShopEventDefinition = () => ({
  event: "submitEvent",
  content_type: "Astro Broadband",
  content_list_type: "Check Coverage",
  content_title: "We'll be in touch",
});

const addressEventsMap: EventsMap = {
  [checkCoveragePageLoad.toString()]: pageLoadEvent,
  [addressSelected.toString()]: selectAddressEvent,
  [residentialTypeUpdated.toString()]: residentialTypeEvent,
  [searchButtonClick.toString()]: searchAddressEvent,
  [keywordAdded.toString()]: filterAddressEvent, // variant-a filter event
  [keywordRemoved.toString()]: filterAddressEvent, // variant-a filter event
  [addressesFiltered.toString()]: filterAddressBySearchBoxEvent, // variant-b filter event
  [toggleRegisterInterest.toString()]: addressNotListedEvent,
  [trackNotListedOption.toString()]: addressNotListedEvent,
  [trackTncLink.toString()]: registerInterestTncEvent,
  [registerInterest.fulfilled.toString()]: registerInterestEvent,
  [addressRejected.toString()]: rejectSelectedAddressEvent,
  [confirmAddress.toString()]: addressFoundEvent,
  [trackCompleteRegisterInterest.toString()]: weWillBeInTouchEvent,
};

export default addressEventsMap;
