import { CSSProperties } from "@material-ui/styles";

type GlobalStyles = {
  "@font-face"?: CSSProperties["@font-face"];
} & Record<string, CSSProperties["@font-face"] | CSSProperties>;

const globalStyles: GlobalStyles = {
  html: {
    fontSize: "16px",
  },
  body: {
    overflowX: "hidden",
    WebkitTextSizeAdjust: "100%",
    MozTextSizeAdjust: "100%",
  },
  "h1, h2, h3, h4, h5, h6": {
    margin: 0,
    fontWeight: 800,
  },
  h1: {
    fontSize: "1.75rem",
    lineHeight: "2rem",
    "@media (min-width: 960px)": {
      fontSize: "2rem",
      lineHeight: "2.25rem",
    },
  },
  h2: {
    fontSize: "1.5rem",
    lineHeight: "30px",
  },
  h3: {
    fontSize: "1.25rem",
    lineHeight: "26px",
  },
  h4: {
    fontSize: "1rem",
    lineHeight: "22px",
  },
  h5: {
    fontSize: "1rem",
  },
  h6: {
    fontSize: "0.875rem",
  },
  a: {
    color: "inherit",
    textDecoration: "none",
  },
  p: {
    margin: 0,
    fontSize: "1rem",
    lineHeight: "22px",
  },
  ul: {
    margin: 0,
    paddingLeft: 0,
    listStyle: "none",
  },
  ol: {
    margin: 0,
  },
  li: {
    fontSize: "1rem",
    lineHeight: "22px",
  },
  strong: {
    fontWeight: "bold",
  },
  sup: {
    position: "relative",
    top: "-0.4em",
    verticalAlign: "baseline",
  },
  sub: {
    position: "relative",
    top: "0.4em",
    verticalAlign: "baseline",
  },
  "@keyframes shimmer": {
    "0%": {
      backgroundPosition: "-100% 0",
    },
    "100%": {
      backgroundPosition: "200% 0",
    },
  },
};

export default globalStyles;
