import { InputRegex } from "../types";
import { AddressConfig, AddressFields } from "./types";

export const config: AddressConfig = {
  residentialType: {
    isRequired: true,
    items: [],
  },
  streetType: {
    isRequired: true,
    items: [],
  },
  streetName: {
    isRequired: true,
  },
  building: {
    isRequired: true,
  },
  postcode: {
    isRequired: true,
    htmlInputProps: {
      maxLength: 5,
    },
  },
  houseNumber: {
    isRequired: true,
  },
  unit: {
    isRequired: true,
  },
  block: {
    isRequired: false,
  },
  street: {
    isRequired: true,
  },
  area: {
    isRequired: false,
  },
  name: {
    isRequired: true,
  },
  mobilePrefix: {
    isRequired: true,
    items: [],
  },
  mobileNumber: {
    isRequired: true,
    htmlInputProps: {
      minLength: 7,
    },
    blockedCharactersRegex: "",
    length: 8,
  },
  email: {
    isRequired: true,
  },
  allowDishInstallation: {
    isRequired: false,
  },
  tncAgreement: {
    isRequired: true,
  },
  searchIcon: "",
  radioUnselectedIcon: "",
  radioSelectedIcon: "",
  searchThrottlePeriod: 250,
};

export const regex: InputRegex = {
  email: "",
  name: "",
  postcode: "",
  mobileNumber: "",
};

export const fields: AddressFields = {
  residentialType: {
    value: null,
    hasRequiredError: true,
    hasInvalidError: false,
  },
  streetType: {
    value: "",
    hasRequiredError: true,
    hasInvalidError: false,
  },
  streetName: {
    value: "",
    hasRequiredError: true,
    hasInvalidError: false,
  },
  building: {
    value: "",
    hasRequiredError: true,
    hasInvalidError: false,
  },
  postcode: {
    value: "",
    hasRequiredError: true,
    hasInvalidError: false,
  },
  houseNumber: {
    value: "",
    hasRequiredError: true,
    hasInvalidError: false,
  },
  unit: {
    value: "",
    hasRequiredError: true,
    hasInvalidError: false,
  },
  block: {
    value: "",
    hasRequiredError: false,
    hasInvalidError: false,
  },
  street: {
    value: "",
    hasRequiredError: true,
    hasInvalidError: false,
  },
  area: {
    value: "",
    hasRequiredError: false,
    hasInvalidError: false,
  },
  name: {
    value: "",
    hasRequiredError: true,
    hasInvalidError: false,
  },
  mobilePrefix: {
    value: "",
    hasRequiredError: true,
    hasInvalidError: false,
  },
  mobileNumber: {
    value: "",
    hasRequiredError: true,
    hasInvalidError: false,
  },
  email: {
    value: "",
    hasRequiredError: true,
    hasInvalidError: false,
  },
  allowDishInstallation: {
    value: false,
    hasRequiredError: false,
    hasInvalidError: false,
  },
  tncAgreement: {
    value: false,
    hasRequiredError: true,
    hasInvalidError: false,
  },
};
