import { ITierConfig } from "@ducks/config/types";
import { IInvoice } from "@lib/calculator";

export const TIER_CONFIG: ITierConfig = {
  id: "",
  description: "",
  pageUrl: "",
  title: "",
  preSelectedPack: "",
  speeds: [],
  packs: [],
  seoSettings: {
    title: "",
    description: "",
    keywords: "",
  },
  showUpsellPopup: false,
  showApplyReferral: true,
  bannerImages: [],
  bannerOverlayImage: "",
  baseDescriptionHtml: "",
  packDescriptionHtml: "",
  basicDescriptionHtml: "",
  popularDescriptionHtml: "",
  deviceDescriptionHtml: "",
  installationMethodDescriptionHtml: "",
  baseHeading: "",
  packHeading: "",
  basicHeading: "",
  popularHeading: "",
  deviceHeading: "",
  installationMethodHeading: "",
  baseTipHtml: "",
  basicTipHtml: "",
  popularTipHtml: "",
  deviceTipHtml: "",
  installationMethodTipHtml: "",
  subscriberTipHtml: "",
  benefitHighlights: [],
};

export const INVOICE_BLANK: IInvoice = {
  valid: false,
  id: "",
  mini: 0,
  miniRequired: 0,
  boxRequired: 0,
  box: 0,
  method: 0,
  methodRequired: 0,
  items: {},
  promoCode: "",
  campaignCode: "",
  arpu: 0,
  subTotal: 0,
  total: 0,
  tax: {},
  grandTotal: 0,
  fee: {
    total: 0,
    tax: {},
    grandTotal: 0,
  },
};
