export enum AbTestVariant {
  Primary = "a",
  Secondary = "b",
}

type AbTestObject<T = unknown> = Partial<Record<AbTestVariant, T>>;

export type AbTestable<T = unknown> = T | AbTestObject<T>;

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
function isAbTestVariant(variant: any): variant is AbTestVariant {
  return Object.values(AbTestVariant).indexOf(variant) >= 0;
}

export function isAbTestObject(obj: unknown): obj is AbTestObject {
  return (
    typeof obj === "object" &&
    obj !== null &&
    !Array.isArray(obj) &&
    Object.values(AbTestVariant).some((variant) => obj.hasOwnProperty(variant))
  );
}

export function getVariantFromUrl(queryString: string): AbTestVariant {
  const queryParams = new URLSearchParams(queryString);
  const variant = queryParams.get(process.env.REACT_APP_AB_TEST_PARAM_NAME);
  if (variant && isAbTestVariant(variant)) return variant;
  return AbTestVariant.Primary;
}
