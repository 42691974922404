import { FormConfig } from "./types";

export const utmParameters = {
  source: "utm_source",
  medium: "utm_medium",
  campaign: "utm_campaign",
};

export const primaryMobileField = "primaryMobileField";

export const FORM_CONFIG: FormConfig = {
  name: {
    isRequired: false,
    salutations: [],
    preselectedSalutationValue: "",
  },
  id: {
    isRequired: false,
    types: [],
    preselectedTypeValue: "",
  },
  document: {
    isRequired: false,
    fileTypes: [],
    maxFileMB: 5,
    placeholderIcon: "",
    loadingIcon: "",
    cancelIcon: "",
    deleteIcon: "",
    pdfPlaceholderIcon: "",
  },
  dob: {
    isRequired: false,
    minEligibleAge: 21,
    maxValidAge: 100,
    noAdditionalDocAge: 27,
  },
  gender: {
    isRequired: false,
    items: [],
    preselectedValue: "",
  },
  ethnic: {
    isRequired: false,
    items: [],
    preselectedValue: "",
  },
  language: {
    isRequired: false,
    items: [],
    preselectedValue: "",
  },
  residentialType: {
    isRequired: false,
    items: [],
    preselectedValue: "",
  },
  houseNumber: {
    isRequired: false,
  },
  unit: {
    isRequired: false,
  },
  block: {
    isRequired: false,
  },
  building: {
    isRequired: false,
  },
  street: {
    isRequired: false,
    types: [],
  },
  postcode: {
    isRequired: false,
    blockedCharactersRegex: "",
    length: 0,
  },
  area: {
    isRequired: false,
  },
  city: {
    isRequired: false,
  },
  state: {
    isRequired: false,
  },
  showInstallationSchedule: 0,
  installationDate: {
    isRequired: 0,
    first: 8,
    last: 30,
    calendarIcon: "",
  },
  installationTime: {
    isRequired: 0,
    items: [],
    preselectedValue: "",
  },
  mobileNumber: {
    prefixes: [],
    isRequired: false,
    blockedCharactersRegex: "",
    length: 8,
  },
  alternateNumber: {
    prefixLabel: "",
    prefixValue: "",
    isRequired: false,
    blockedCharactersRegex: "",
    length: 10,
  },
  email: {
    isRequired: false,
  },
  regex: {
    name: "",
    house: "",
    unit: "",
    block: "",
    building: "",
    street: "",
    postcode: "",
    email: "",
    mobile: "",
    alternateNumber: "",
  },
  cableTypeMap: {},
  serviceProviderMap: {},
};
