import { createSlice, PayloadAction, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import dayjs from "dayjs";

import {
  ConsentErrorCode,
  ConsentErrorType,
  ConsentRejectValue,
  ConsentRequest,
  BillItemLabel,
  OrderSummaryState,
  UpdatePersonalInformation,
  OrderSummaryConfig,
  TermsConditionsEvent,
} from "./types";
import { FormParameter } from "../signUp/types";
import * as constants from "../orderSummary/constants";
import { pushWithParams } from "../route";
import { AppState, AppThunk } from "@store";
import { APIResponse, isErrorResponse, getErrorCode } from "@lib/api";
import { DISCOUNTTYPE, InvoiceDiscount, ITEMTYPE } from "@lib/calculator";
import { capitalizeSentence, interpolate } from "@lib/common";
import { thankYouPath } from "@constants/paths";
import settings from "@settings";
import { getInvoice, invoiceUpdated } from "@ducks/shop";
import { fetchConfig } from "@ducks/config";
import { getAppliedReferral, appliedCodeRemoved } from "@ducks/referral";
import { formatAddress } from "@selectors/address";
import { portalSelector, PortalType } from "@selectors/portalType";

export const consentLead = createAsyncThunk<
  void,
  ConsentRequest | undefined,
  { state: AppState; rejectValue: ConsentRejectValue }
>("orderSummary/consentLead", async (request = {}, thunkApi) => {
  if (request.referralSignature)
    if (!(request.promoCode && request.campaignCode))
      return thunkApi.rejectWithValue({
        errorType: ConsentErrorType.Modal,
        errorCode: ConsentErrorCode.InvalidReferral,
      });

  const { token } = thunkApi.getState().signUp.lead;
  const response = await fetch(settings.apiEndpoint.consent, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(request),
  });
  const data = (await response.json()) as APIResponse<Record<string, unknown>>;

  if (isErrorResponse(data.response)) {
    const errorCode = getErrorCode(data.response);
    switch (errorCode) {
      case ConsentErrorCode.RefereeOfferPriceMismatch:
      case ConsentErrorCode.InvalidReferral:
      case ConsentErrorCode.InvalidSmcNo:
      case ConsentErrorCode.InactiveSmcNo:
      case ConsentErrorCode.PromoCodeUsed:
      case ConsentErrorCode.PromoCodeNotEligible:
      case ConsentErrorCode.EligibilityCheckFailed:
        return thunkApi.rejectWithValue({
          errorType: ConsentErrorType.Modal,
          errorCode,
        });
      case ConsentErrorCode.InvalidToken:
      case ConsentErrorCode.LeadReadFailed:
      case ConsentErrorCode.LeadNotVerified:
      case ConsentErrorCode.LeadUpdateFailed:
      default:
        return thunkApi.rejectWithValue({
          errorType: ConsentErrorType.Overlay,
          errorCode: errorCode ?? undefined,
        });
    }
  }
});

const initialState: OrderSummaryState = {
  config: constants.ORDER_SUMMARY_CONFIG,

  packSection: [],
  addOnsSection: [],
  devicesMonthlySection: [],
  devicesInstallationSection: [],
  termsConditionsSection: [],

  nameIdentity: [],
  installationAddress: [],
  installationSchedule: [],
  contactInformation: [],

  consentStatus: {
    loading: false,
    error: false,
    success: false,
  },
};

const orderSummarySlice = createSlice({
  name: "orderSummary",
  initialState,
  reducers: {
    packsSectionUpdated(state, action: PayloadAction<BillItemLabel[]>) {
      state.packSection = action.payload;
    },
    addonsSectionUpdated(state, action: PayloadAction<BillItemLabel[]>) {
      state.addOnsSection = action.payload;
    },
    devicesSectionUpdated(state, action: PayloadAction<BillItemLabel[]>) {
      state.devicesMonthlySection = action.payload;
    },
    installationFeeUpdated(state, action: PayloadAction<BillItemLabel[]>) {
      state.devicesInstallationSection = action.payload;
    },
    personalInformationUpdated(state, action: PayloadAction<UpdatePersonalInformation>) {
      state.nameIdentity = action.payload.nameIdentity;
      state.installationAddress = action.payload.installationAddress;
      state.installationSchedule = action.payload.installationSchedule;
      state.contactInformation = action.payload.contactInformation;
    },
    termsConditionUpdated(state, action: PayloadAction<string[]>) {
      state.termsConditionsSection = action.payload;
    },
    referralErrorAcknowledged(state) {
      state.consentStatus = {
        loading: false,
        success: false,
        error: false,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchConfig.fulfilled, (state, action) => {
        action.payload.orderSummary && (state.config = action.payload.orderSummary);
      })
      .addCase(consentLead.pending, (state) => {
        state.consentStatus = {
          loading: true,
          success: false,
          error: false,
        };
      })
      .addCase(consentLead.rejected, (state, action) => {
        state.consentStatus = {
          loading: false,
          success: false,
          error: true,
        };
        if (action.payload)
          switch (action.payload.errorType) {
            case ConsentErrorType.Overlay:
              state.consentStatus.errorCode = action.payload.errorCode;
              break;
            case ConsentErrorType.Modal:
              state.consentStatus.referralErrorCode = action.payload.errorCode;
              break;
          }
      })
      .addCase(consentLead.fulfilled, (state) => {
        state.consentStatus = {
          loading: false,
          success: true,
          error: false,
        };
      });
  },
});

export const {
  packsSectionUpdated,
  addonsSectionUpdated,
  devicesSectionUpdated,
  installationFeeUpdated,
  personalInformationUpdated,
  termsConditionUpdated,
  referralErrorAcknowledged,
} = orderSummarySlice.actions;

export default orderSummarySlice.reducer;

/* Thunks */

export const updatePacksSection = (): AppThunk => (dispatch, getState) => {
  const {
    orderSummary: { config },
    shop: { invoice, selectedSpeed, selectedPack },
    language: { orderSummary: l },
  } = getState();

  let packsSection: BillItemLabel[] = [];
  if (selectedPack) {
    /* Assign value for name and price (with speed) */
    const packItem = invoice.items[invoice.id];
    const speedItem = invoice.speed ? invoice.items[invoice.speed] : undefined;
    const itemName = selectedSpeed
      ? interpolate(l.BB_INVOICE_NAME, { speed: selectedSpeed.title, pack: selectedPack.invoiceName })
      : selectedPack.invoiceName;
    const itemPrice = speedItem ? speedItem.price + packItem.price : packItem.price;

    /* Asssign section with default value */
    packsSection.push({
      name: itemName,
      currentPrice: interpolate(l.MONTHLY_PRICE, { amount: itemPrice.toFixed(2) }),
    });
    /* Assign new name, prev price and current price when discount exist */
    if (packItem.discounts) {
      let discountedItems = packItem.discounts.reduce((list, discount) => {
        if (discount.discountType === DISCOUNTTYPE.monthlyFee) {
          list.push(getDiscountedItems(itemPrice, discount, itemName, getState(), l.MONTHLY_PRICE));
        }
        return list;
      }, [] as BillItemLabel[]);
      if (speedItem && speedItem.discounts) {
        discountedItems = discountedItems.concat(
          speedItem.discounts.reduce((list, discount) => {
            if (discount.discountType === DISCOUNTTYPE.monthlyFee) {
              list.push(getDiscountedItems(itemPrice, discount, itemName, getState(), l.MONTHLY_PRICE));
            }
            return list;
          }, [] as BillItemLabel[])
        );
      }
      /* Replace default value with new discounted items if exist */
      if (discountedItems.length > 0) packsSection = discountedItems;
    }
    /* Add promos text */
    if (packItem.promos) packsSection = packsSection.concat(getPromotionItems(packItem.promos, config));
    if (speedItem && speedItem.promos) packsSection = packsSection.concat(getPromotionItems(speedItem.promos, config));
  }
  dispatch(packsSectionUpdated(packsSection));
};

export const updateAddonsSection = (): AppThunk => (dispatch, getState) => {
  const {
    config: { addOns },
    orderSummary: { config },
    shop: { invoice },
    language: { orderSummary: l },
  } = getState();

  const unsortedAddonsIds: string[] = [];

  /* Filter invoice items based on type */
  for (const [id, item] of Object.entries(invoice.items)) {
    if (!item.isBundled) if (item.type === ITEMTYPE.mini || item.type === ITEMTYPE.addOn) unsortedAddonsIds.push(id);
  }
  /* Sort addons id based on sequence */
  const sortedAddonsIds: string[] = getSortedAddonsList(unsortedAddonsIds, config);

  /* Assign value for addons section with correct sequence */
  const addonsSection = sortedAddonsIds.reduce((list, addonId) => {
    const invoiceItem = invoice.items[addonId];
    const name = addOns[addonId].name;
    let newList: BillItemLabel[] = [];

    /* Asssign section with default value */
    newList.push({
      name,
      currentPrice: interpolate(l.MONTHLY_PRICE, { amount: invoiceItem.price.toFixed(2) }),
    });
    /* Assign new name, prev price and current price when discount exist */
    if (invoiceItem.discounts) {
      const discountedItems = invoiceItem.discounts.reduce((discountList, discount) => {
        if (discount.discountType === DISCOUNTTYPE.monthlyFee) {
          discountList.push(getDiscountedItems(invoiceItem.price, discount, name, getState(), l.MONTHLY_PRICE));
        }
        return discountList;
      }, [] as BillItemLabel[]);
      /* Replace default value with new discounted items if exist */
      if (discountedItems.length > 0) newList = discountedItems;
    }
    /* Add promos text */
    if (invoiceItem.promos) newList = newList.concat(getPromotionItems(invoiceItem.promos, config));
    list = list.concat(newList);
    return list;
  }, [] as BillItemLabel[]);
  dispatch(addonsSectionUpdated(addonsSection));
};

export const updateDevicesSection = (): AppThunk => (dispatch, getState) => {
  const {
    config: { devices },
    orderSummary: { config },
    shop: { invoice },
    language: { orderSummary: l },
  } = getState();

  let devicesSection: BillItemLabel[] = [];

  for (const [id, item] of Object.entries(invoice.items)) {
    if (!item.isBundled) {
      if (item.type === ITEMTYPE.device) {
        const name = devices[id].name;
        let newList: BillItemLabel[] = [];

        /* Asssign section with default value */
        newList.push({
          name,
          currentPrice: item.price > 0 ? interpolate(l.MONTHLY_PRICE, { amount: item.price.toFixed(2) }) : l.FREE,
        });
        /* Assign new name, prev price and current price when discount exist */
        if (item.discounts) {
          const discountedItems = item.discounts.reduce((list, discount) => {
            if (discount.discountType === DISCOUNTTYPE.monthlyFee) {
              list.push(getDiscountedItems(item.price, discount, name, getState(), l.MONTHLY_PRICE));
            }
            return list;
          }, [] as BillItemLabel[]);
          /* Replace default value with new discounted items if exist */
          if (discountedItems.length > 0) newList = discountedItems;
        }
        /* Add promos text */
        if (item.promos) newList = newList.concat(getPromotionItems(item.promos, config));
        devicesSection = devicesSection.concat(newList);
      }
    }
  }
  dispatch(devicesSectionUpdated(devicesSection));
};

export const updateInstallationFee = (): AppThunk => (dispatch, getState) => {
  const {
    config: { devices, installationMethods: methods },
    language: { orderSummary: l },
    shop: { invoice },
  } = getState();

  let installationFeeSection: BillItemLabel[] = [];

  for (const [id, item] of Object.entries(invoice.items)) {
    if (!item.isBundled) {
      if (item.type === ITEMTYPE.device && item.fee !== undefined) {
        let name = devices[id].name;
        let itemFee = item.fee;
        let feeInclusive = item.feeIsInclusiveTax;
        let newList: BillItemLabel[] = [];

        const method = Object.entries(invoice.items).find(
          (item) => item[1].type === ITEMTYPE.method && item[1].fee !== undefined
        );
        if (method) {
          name = `${name} - ${methods[method[0]].name} ${methods[method[0]].feeDescription}`;
          itemFee = method[1].fee || 0;
          feeInclusive = method[1].feeIsInclusiveTax;
        }

        /* Asssign section with default value */
        newList.push({
          name: feeInclusive ? `${name} ${l.TAX_INCLUSIVE}` : name,
          currentPrice: itemFee > 0 ? itemFee.toFixed(2) : l.FREE,
        });
        /* Assign new name, prev price and current price when discount exist */
        if (item.discounts) {
          const discountedItems = item.discounts.reduce((list, discount) => {
            if (discount.discountType === DISCOUNTTYPE.oneTimeFee) {
              list.push(getDiscountedItems(itemFee, discount, name, getState(), "", item.feeIsInclusiveTax));
            }
            return list;
          }, [] as BillItemLabel[]);
          /* Replace default value with new discounted items if exist */
          if (discountedItems.length > 0) newList = discountedItems;
        }
        installationFeeSection = installationFeeSection.concat(newList);
      }
    }
  }
  dispatch(installationFeeUpdated(installationFeeSection));
};

export const updatePersonalInformation = (): AppThunk => (dispatch, getState) => {
  const {
    orderSummary: { config },
  } = getState();

  const updates = getPersonalInfo(config, getState());
  dispatch(personalInformationUpdated(updates));
};

export const updateTermsConditions = (): AppThunk => (dispatch, getState) => {
  const {
    orderSummary: { config },
    shop: { invoice, selectedPack },
  } = getState();
  const portal = portalSelector(getState());

  const unsortedAddonsIds: string[] = [];
  let termsConditionsIds: string[] = [];

  /* Get general terms list */
  if (invoice.terms) termsConditionsIds = termsConditionsIds.concat(invoice.terms);
  /* Get terms from pack section */
  if (invoice.speed && portal === PortalType.BB) {
    const speedItem = invoice.items[invoice.speed];
    if (speedItem.terms) termsConditionsIds = termsConditionsIds.concat(speedItem.terms);
  }
  if (selectedPack) {
    const packItem = invoice.items[invoice.id];
    if (packItem.terms) termsConditionsIds = termsConditionsIds.concat(packItem.terms);
  }
  /* Get terms from addons section */
  for (const [id, item] of Object.entries(invoice.items)) {
    if (!item.isBundled) if (item.type === ITEMTYPE.mini || item.type === ITEMTYPE.addOn) unsortedAddonsIds.push(id);
  }
  const sortedAddOnsIds = getSortedAddonsList(unsortedAddonsIds, config);
  termsConditionsIds = termsConditionsIds.concat(
    sortedAddOnsIds.reduce((list, addonId) => {
      const addonItem = invoice.items[addonId];
      if (addonItem.terms) list = list.concat(addonItem.terms);
      return list;
    }, [] as string[])
  );
  /* Get terms from device section */
  for (const item of Object.values(invoice.items)) {
    if (!item.isBundled && item.type === ITEMTYPE.device && item.terms) {
      termsConditionsIds = termsConditionsIds.concat(item.terms);
    }
  }
  const termsConditionSection = getTermsConditions(termsConditionsIds, config);
  dispatch(termsConditionUpdated(termsConditionSection));
};

export const submitOrderSummary = (): AppThunk => async (dispatch, getState) => {
  const { invoice } = getState().shop;
  const portal = portalSelector(getState());
  const referral = getAppliedReferral(getState().referral);
  const speed = portal === PortalType.BB ? constants.sessionExpiredBb : constants.sessionExpiredTv;
  const consentResult = await dispatch(
    consentLead(
      referral
        ? {
            referralSignature: referral.signature,
            promoCode: invoice.promoCode,
            campaignCode: invoice.campaignCode,
          }
        : undefined
    )
  );
  if (consentLead.fulfilled.match(consentResult)) {
    dispatch(trackCheckout());
    sessionStorage.setItem(constants.isBroadband, speed);
    dispatch(pushWithParams(thankYouPath));
  }
};

export const acknowledgeReferralError = (): AppThunk => (dispatch, getState) => {
  /**
   * This does 3 things:
   * 1. Reset consent API status and hide order summary error modal
   * 2. Remove referral object from Shop slice
   * 3. Remove "problematic" referral code from Referral Panel's list
   */
  dispatch(referralErrorAcknowledged());
  dispatch(appliedCodeRemoved());

  const shopState = getState().shop;
  const { selectedPack } = shopState;
  if (selectedPack) {
    const invoice = getInvoice(
      selectedPack,
      shopState,
      getState().config.price,
      getAppliedReferral(getState().referral)
    );
    dispatch(invoiceUpdated(invoice));
  }
};

/* Analytics actions */
export const trackCheckout = createAction("orderSummary/trackCheckout");
export const trackInstallFeeLink = createAction<string>("orderSummary/trackInstallFeeLink");
export const trackTermsConditions = createAction<TermsConditionsEvent>("orderSummary/trackTermsConditions");

/* Utility functions */
function getPersonalInfo(orderSummaryConfig: OrderSummaryConfig, appState: AppState): UpdatePersonalInformation {
  const {
    ulm: { ulmToken, isLoggedIn },
    customerInfo: {
      fetchStatus: { success: customerInfoFetchStatus },
      customerInfoData: { idType: idTypeBF, idSuffix: idSuffixBF, name: nameBF, title: salutationBF },
    },
    signUp: {
      config,
      salutation: s,
      name,
      idType,
      idValue,
      residentialType,
      house,
      unit,
      block,
      building,
      streetType,
      streetName,
      street,
      postcode,
      area,
      city,
      state,
      installationDate,
      installationTime,
      mobilePrefix,
      mobileNumber,
      alternateNumber,
      email,
    },
    language: { orderSummary: l },
  } = appState;

  const capitalizedNameBF = capitalizeSentence(nameBF);

  const nameIdentity: string[] = [];
  let installationAddress: string[] = [];
  const installationSchedule: string[] = [];
  const contactInformation: string[] = [];

  if (isLoggedIn && ulmToken && customerInfoFetchStatus) {
    /* Combine salutation and name */
    if (salutationBF || capitalizedNameBF) {
      let customerSalutation = salutationBF ? salutationBF.trim() : "";

      customerSalutation = customerSalutation
        ? customerSalutation
            .toLowerCase()
            .split(" ")
            .filter((word) => word !== "")
            .map((word) => word[0].toUpperCase() + word.substring(1))
            .join(" ")
        : "";

      const nameWithSalutation = customerSalutation ? `${customerSalutation} ${capitalizedNameBF}` : capitalizedNameBF;

      nameIdentity.push(nameWithSalutation);
    }
    /* Combine idType and idNo */
    if (idTypeBF && idSuffixBF)
      nameIdentity.push(
        interpolate(l.BF_MASKED_ID, { idType: orderSummaryConfig.idTypeMapping[idTypeBF], idSuffix: idSuffixBF })
      );
  } else {
    /* Get label for id type */
    const _idType = config.id.types.find(({ value }) => value === idType.value);
    /* Get label for salutation */
    const _salutation = getLabel(config.name.salutations, s.value);
    /* Combine salutation and name */
    const capitalizedName = capitalizeSentence(name.value);
    if (_salutation || capitalizedName) nameIdentity.push(`${_salutation} ${capitalizedName}`);
    /* Combine idType and idNo */
    if (_idType && idValue.value) nameIdentity.push(`${_idType.label} ${idValue.value}`);
  }

  /* Get installation address */
  if (residentialType.value)
    installationAddress = formatAddress(
      residentialType.value,
      house.value,
      unit.value,
      block.value,
      building.value,
      street.value || `${getLabel(config.street.types, streetType.value)} ${streetName.value}`,
      area.value,
      postcode.value,
      city.value,
      state.value
    ).map(capitalizeSentence);

  /* Get installation date */
  if (installationDate.value) {
    const formattedDate = dayjs(installationDate.value).format("dddd, D MMMM YYYY");
    installationSchedule.push(formattedDate);
  }
  /* Add installation time */
  if (installationTime.value) {
    const _installationTime = getLabel(config.installationTime.items, installationTime.value);
    installationSchedule.push(_installationTime);
  }

  /* Add mobile number */
  const mobileNumberPrefix = getLabel(config.mobileNumber.prefixes, mobilePrefix.value);
  if (mobileNumberPrefix || mobileNumber.value) contactInformation.push(`${mobileNumberPrefix}-${mobileNumber.value}`);
  /* Add alternate number */
  if (orderSummaryConfig.showAlternateNumber && alternateNumber.value)
    contactInformation.push(`${config.alternateNumber.prefixLabel}${alternateNumber.value}`);
  /* Add email */
  contactInformation.push(email.value);

  return {
    nameIdentity,
    installationAddress,
    installationSchedule,
    contactInformation,
  };
}
function getLabel(lists: FormParameter[], input: string): string {
  const item = lists.find(({ value }) => value === input);
  return item?.label ?? "";
}
/* Get terms conditions description list */
function getTermsConditions(termIds: string[], config: OrderSummaryConfig): string[] {
  const { terms } = config;
  const termsDescription = termIds.reduce((list, termId) => {
    if (terms[termId]) list.push(terms[termId].descriptionHtml);
    return list;
  }, [] as string[]);
  return termsDescription;
}
/* Sort selected addons */
function getSortedAddonsList(unsortedIds: string[], config: OrderSummaryConfig): string[] {
  /* Sort addons id based on sequence */
  let sortedAddonsIds: string[] = config.addOnsSequence.reduce((list, addonId) => {
    const index = unsortedIds.indexOf(addonId);
    if (index > -1) {
      list.push(addonId);
      unsortedIds.splice(index, 1);
    }
    return list;
  }, [] as string[]);
  sortedAddonsIds = sortedAddonsIds.concat(unsortedIds);
  return sortedAddonsIds;
}
/* Get item name when discount is applied */
function getDiscountedInvoiceName(
  discount: InvoiceDiscount,
  state: AppState,
  name: string,
  currentPrice: string,
  taxInclusive?: boolean
): string {
  const { discountId, amount, period, percent, promotionTitle, promotionDescription } = discount;
  const {
    orderSummary: {
      config: { promotions },
    },
    language: { orderSummary: l },
  } = state;

  let itemName = "";
  if (promotions[discountId] && promotionTitle && promotionDescription)
    itemName = interpolate(promotions[discountId].descriptionHtml, {
      name,
      title: promotionTitle,
      description: promotionDescription,
    });
  else if (promotionTitle && promotionDescription)
    itemName = interpolate(l.REFERRAL_INVOICE_NAME, { name, title: promotionTitle, description: promotionDescription });
  else if (promotions[discountId])
    itemName = interpolate(promotions[discountId].descriptionHtml, {
      name,
      discount: percent ? (percent * 100).toString() : amount.toFixed(2),
      period: period?.toString() ?? "",
      price: currentPrice,
    });
  if (taxInclusive) itemName += ` ${l.TAX_INCLUSIVE}`;
  return itemName;
}
/* Get discount list under items */
function getDiscountedItems(
  itemPrice: number,
  discount: InvoiceDiscount,
  name: string,
  state: AppState,
  priceTag?: string,
  taxInclusive?: boolean
): BillItemLabel {
  const prevPrice = itemPrice;
  const currentPrice = itemPrice - discount.amount;

  return {
    name: getDiscountedInvoiceName(discount, state, name, currentPrice.toFixed(2), taxInclusive),
    prevPrice: priceTag ? interpolate(priceTag, { amount: prevPrice.toFixed(2) }) : prevPrice.toFixed(2),
    currentPrice: priceTag ? interpolate(priceTag, { amount: currentPrice.toFixed(2) }) : currentPrice.toFixed(2),
  };
}
/* Get promotion list under items */
function getPromotionItems(promoIds: string[], config: OrderSummaryConfig): BillItemLabel[] {
  const { promotions } = config;
  const promotionDescription = promoIds.reduce((list, promoId) => {
    if (promotions[promoId])
      list.push({ name: promotions[promoId].descriptionHtml, currentPrice: promotions[promoId].priceTag ?? "" });
    return list;
  }, [] as BillItemLabel[]);
  return promotionDescription;
}
