export const PRICE_CONFIG: IPriceConfig = {
  tax: {},
  packs: {},
  addOns: {},
  boxExclusions: [],
  methodExclusions: [],
  miniConditions: {},
  addOnConditions: {},
  requiredConditions: {},
  discounts: [],
  promos: [],
  terms: [],
  comboConditions: [],
  codes: [],
  referralType: {
    noReferral: "",
  },
};

type BOOL = 0 | 1;

export interface TierPrice {
  beforeDiscount: number;
  afterDiscount: number;
}

export enum ITEMTYPE {
  pack = 1,
  mini,
  addOn,
  device,
  speed,
  method,
}

enum TAXTYPE {
  /**
   * Tax is applied on each item, rounded to 2 d.p., then totaled
   */
  itemised = 1,
  /**
   * Tax is applied on the total of all items, rounded to 2 d.p.
   */
  total,
}

export enum DISCOUNTTYPE {
  monthlyFee = 1,
  oneTimeFee,
}

export default class ShopCalculator {
  /**
   * Price config data
   */
  private data: IPriceConfig;

  constructor(priceConfig: IPriceConfig = PRICE_CONFIG) {
    this.data = priceConfig;
  }

  public getPricePlanId(id: string): string {
    switch (this.getItemType(id)) {
      case ITEMTYPE.pack:
        return this.data.packs[id].pricePlanId;
      case ITEMTYPE.addOn:
      case ITEMTYPE.mini:
      case ITEMTYPE.device:
      case ITEMTYPE.speed:
        return this.data.addOns[id].pricePlanId;
      default:
        return "";
    }
  }

  public isValidItem(id: string): boolean {
    return this.getItemType(id) !== null;
  }

  public getItemType(id: string): ITEMTYPE | null {
    const pack = this.data.packs[id];
    if (pack !== undefined) return pack.type;
    const addOn = this.data.addOns[id];
    if (addOn !== undefined) return addOn.type;
    return null;
  }

  /**
   * Get list of bundled items from base pack
   * @param packId Selected base pack ID
   */
  public getPackBundle(packId: string): string[] {
    return Object.keys(this.calculate(packId, []).items);
  }

  /**
   * Get one-time fee and monthly price for item (if any)
   * @param packId Selected base pack ID
   * @param selectedAddOnIds Selected add-ons IDs
   * @param itemId Item ID to retrieve one-time fee for
   */
  public getDeviceInfo(
    packId: string,
    selectedAddOnIds: string[],
    itemId: string,
    maxArpu?: number | undefined
  ): { displayPrice: number; displayFee: number; itemFee: number; monthlyPrice: number } | null {
    const invoice = this.calculate(packId, [...selectedAddOnIds, itemId]);
    if (maxArpu && invoice.arpu >= maxArpu) return null;
    const item = invoice.items[itemId];
    if (item) {
      let displayPrice = item.price;
      if (item.discounts) {
        displayPrice =
          item.price -
          item.discounts.reduce((list, discount) => {
            if (discount.discountType === DISCOUNTTYPE.monthlyFee) list += discount.amount;
            return list;
          }, 0 as number);
      }
      if (item.fee !== undefined)
        return {
          displayPrice,
          displayFee: invoice.fee.total,
          itemFee: item.fee,
          monthlyPrice: item.price,
        };
    }
    return null;
  }

  /**
   * Calculate how much is added to the bill for this item, actual item price, if item is bundled or required, if combo added for this selection
   * @param packId Selected base pack ID
   * @param selectedAddOnIds Selected add-ons' IDs
   * @param itemId Item ID to retrieve price for
   */
  public getItemInfo(
    packId: string,
    selectedAddOnIds: string[],
    itemId: string
  ): {
    priceDiff: number;
    itemPrice: number;
    isBundled: boolean;
    isRequired: boolean;
    combos: string[];
  } {
    const invoiceWithoutItem = this.calculate(packId, selectedAddOnIds);
    const invoiceWithItem = this.calculate(packId, [...selectedAddOnIds, itemId]);
    const item = invoiceWithItem.items[itemId];
    const combos = Object.keys(invoiceWithItem.items).filter(
      (id) => id !== itemId && Object.keys(invoiceWithoutItem.items).indexOf(id) < 0
    );
    return {
      priceDiff: invoiceWithItem.total - invoiceWithoutItem.total,
      itemPrice: item?.price || 0,
      isBundled: !!item?.isBundled,
      isRequired: !!item?.isRequired,
      combos,
    };
  }

  /**
   * Get minimum price among packs as tier price
   */
  public getTierPrice(packIds: string[], speedId?: string, device?: string): TierPrice {
    const priceList = packIds.reduce((list, packId) => {
      const packPrice = this.getPackPrice(packId, speedId, device);
      if (packPrice !== null) {
        list.push({
          beforeDiscount: packPrice.itemPrice,
          afterDiscount: packPrice.displayPrice,
        });
      }
      return list;
    }, [] as TierPrice[]);
    const beforeDiscount = Math.min(...priceList.map((list) => list.beforeDiscount));
    const afterDiscount = Math.min(...priceList.map((list) => list.afterDiscount));

    return { beforeDiscount, afterDiscount };
  }

  /**
   * Get price for base pack
   * @param packId Base pack ID
   */
  public getPackPrice(
    packId: string,
    speedId?: string,
    device?: string
  ): { displayPrice: number; itemPrice: number } | null {
    const addOns = speedId ? [speedId] : [];
    if (device) addOns.push(device);
    const invoice = this.calculate(packId, addOns);
    const pack = invoice.items[packId];
    const speedPrice = speedId ? invoice.items[speedId].price : 0;
    if (pack)
      return {
        displayPrice: invoice.total,
        itemPrice: pack.price + speedPrice,
      };
    return null;
  }

  /**
   * Generate invoice based on selection
   * @param packId Selected base pack ID
   * @param addOnIds Selected add-ons' IDs (minis, add-ons, boxes, speeds)
   * @param [options] Extra conditions / requirements when generating invoice
   */
  public calculate(packId: string, addOnIds: string[], options: CalculationOptions = {}): IInvoice {
    const invoice: IInvoice = {
      valid: true,
      id: "",
      mini: 0,
      miniRequired: 0,
      box: 0,
      boxRequired: 1, // NOTE: does not cater for multiple devices, quantity >= 1, or multiroom yet
      method: 0,
      methodRequired: 1,
      items: {},
      promoCode: "",
      campaignCode: "",
      arpu: 0,
      subTotal: 0,
      total: 0,
      tax: {},
      grandTotal: 0,
      fee: {
        total: 0,
        tax: {},
        grandTotal: 0,
      },
    };
    /**
     * List of all bundled and selected packs, add-ons, boxes, broadbands.
     */
    const allAddOnIds: string[] = [];
    /**
     * List of only selected add-ons (not bundled with pack selection)
     */
    const selectedAddOnIds: string[] = [];
    /**
     * List of selected minis only
     */
    const selectedMinis: string[] = [];
    /**
     * Referral type
     */
    const referralType = options.referral ? options.referral.referralType : this.data.referralType.noReferral;

    const pack = this.data.packs[packId]; // Get base pack
    if (!pack) {
      invoice.valid = false;
      return invoice;
    }

    /* Set base pack information */
    invoice.id = packId;
    invoice.arpu += pack.price;
    invoice.items[packId] = {
      price: pack.price,
      pricePlanId: pack.pricePlanId,
      type: pack.type,
    };
    allAddOnIds.push(packId);
    allAddOnIds.push(...pack.addOns); // Add bundled add-ons into all addons array
    selectedAddOnIds.push(packId); // Add base pack into selected array

    /* Get bundled add-ons information */
    for (const bundledAddOnId of pack.addOns) {
      const addOn = this.data.addOns[bundledAddOnId];
      if (addOn) {
        const item: InvoiceItem = {
          pricePlanId: addOn.pricePlanId,
          price: 0,
          type: addOn.type,
          isBundled: true,
          fee: addOn.fee, // if bundled, is fee waived? At the moment does not seem so
        };
        if (addOn.feeIsInclusiveTax) item.feeIsInclusiveTax = !!addOn.feeIsInclusiveTax;
        invoice.items[bundledAddOnId] = item;
        addOn.type === ITEMTYPE.mini && ++invoice.mini; // identify as mini bundled
      }
    }

    /* Get selected add-ons information */
    for (const addOnId of addOnIds) {
      const addOn = this.data.addOns[addOnId];
      if (addOn && !invoice.items[addOnId]) {
        // Only add price into total if it is not bundled with base pack
        selectedAddOnIds.push(addOnId); // Filter selected add-ons - only items not included in base pack
        const item: InvoiceItem = {
          pricePlanId: addOn.pricePlanId,
          price: addOn.price,
          type: addOn.type,
          fee: addOn.fee, // only for boxes
        };
        addOn.feeIsInclusiveTax && (item.feeIsInclusiveTax = !!addOn.feeIsInclusiveTax);
        addOn.isExcludedInArpu && (item.isExcludedInArpu = !!addOn.isExcludedInArpu);
        invoice.items[addOnId] = item;
        switch (addOn.type) {
          case ITEMTYPE.device:
            ++invoice.box;
            break;
          case ITEMTYPE.mini:
            ++invoice.mini && selectedMinis.push(addOnId);
            break;
          case ITEMTYPE.speed:
            invoice.speed = addOnId; // only one speed allowed
            break;
          case ITEMTYPE.method:
            ++invoice.method;
            break;
          default:
            break;
        }
        !addOn.isExcludedInArpu && (invoice.arpu += addOn.price); // Certain add-ons like speed & boxes are not in arpu
        allAddOnIds.push(addOnId); // Add selected add-on into all addons array
      }
    }

    /* Check if method should be excluded */
    this.data.boxExclusions.find((item) => allAddOnIds.find((addon) => addon === item)) && (invoice.boxRequired = 0);

    /* Check if method should be excluded */
    this.data.methodExclusions.find((item) => allAddOnIds.find((addon) => addon === item)) &&
      (invoice.methodRequired = 0);

    /* Get combo */
    const combo = this.data.comboConditions.find(
      (condition) =>
        this.data.addOns[condition.comboId] &&
        allAddOnIds.indexOf(condition.comboId) < 0 &&
        this.checkEntitlement(allAddOnIds, condition.includedPacks)
    );
    if (combo)
      // Push combo into selection and rerun calculation
      return this.calculate(packId, [...addOnIds, combo.comboId]);
    else {
      // Proceed with calculation

      /* Check mini validity and requirements */
      const miniConditions = this.data.miniConditions[packId]; // Get list of mini conditions for selected base pack
      const miniCondition = miniConditions.find((condition) => {
        // Top conditions have priority
        let valid = true;
        !!condition.includedPacks && (valid = this.checkEntitlement(allAddOnIds, condition.includedPacks));
        return valid;
      });
      invoice.valid = invoice.valid && !!miniCondition; // At least one mini condition must be applicable
      // if no miniCondition found, means config is missing a condition for selected base pack
      if (miniCondition) {
        const isMiniValid = miniCondition.valid[invoice.mini];
        invoice.valid = invoice.valid && !!isMiniValid;
        /* Get number of required minis for valid selection */
        let miniRequired = invoice.mini;
        let increment = true;
        while (!miniCondition.valid[miniRequired]) {
          miniRequired = increment ? miniRequired + 1 : miniRequired - 1;
          if (miniCondition.valid[miniRequired] === undefined) {
            if (!increment) {
              // Number of selected minis does not match the condition's valid array
              miniRequired = miniCondition.valid.lastIndexOf(1); // Get max number of required minis
              break;
            }
            increment = false;
            miniRequired = invoice.mini;
          }
        }
        invoice.miniRequired = miniRequired;
        invoice.valid = invoice.valid && miniRequired >= 0;

        /* Update mini price */
        for (let i = 0; i < selectedMinis.length; i++) {
          const miniId = selectedMinis[i];
          if (invoice.items[miniId] && !invoice.items[miniId].isBundled) {
            invoice.arpu -= invoice.items[miniId].price;
            invoice.items[miniId].price = miniCondition.amount[i];
            invoice.arpu += invoice.items[miniId].price;
          }
        }
      }

      /* Check conditions that alter item price and selection validity */
      // conditions with arpu as criteria should be checked later
      const arpuAddonConditions: Record<string, IAddOnCondition[]> = {};
      const arpuRequiredConditions: Record<string, IRequiredCondition[]> = {};
      for (const [id, item] of Object.entries(invoice.items))
        if (id !== invoice.id) {
          // Only check for add-ons
          if (item.isBundled)
            // Only check for non-bundled add-ons
            continue;
          const addOnConditions = this.data.addOnConditions[id];
          const requiredConditions = this.data.requiredConditions[id];

          /* Update add-on prices based on add-on conditions */
          if (addOnConditions) {
            let match = false;
            for (const condition of addOnConditions)
              if (
                this.isEntitled(
                  selectedAddOnIds.filter((id) => !invoice.items[id].isBundled),
                  allAddOnIds,
                  condition
                )
              ) {
                if (condition.arpu !== undefined || condition.ratioOfArpu !== undefined) {
                  // check later if depends on arpu
                  if (arpuAddonConditions[id] === undefined) arpuAddonConditions[id] = [];
                  arpuAddonConditions[id].push(condition);
                } else {
                  match = true;
                  this.updateItem(condition, id, invoice); // If entitled, update item price or bundled status
                }
                if (match) break;
              }
          }

          /* Check validity of selection */
          if (requiredConditions) {
            // at least one condition must be met
            let valid = false;
            const arpuConditions: IRequiredCondition[] = [];
            for (const condition of requiredConditions)
              if (
                this.isEntitled(
                  selectedAddOnIds.filter((id) => !invoice.items[id].isBundled),
                  allAddOnIds,
                  condition
                )
              ) {
                if (condition.arpu !== undefined) arpuConditions.push(condition);
                // check later if depends on arpu
                else valid = true;
              }
            !valid && !arpuConditions.length && (invoice.valid = false);
            !valid && arpuConditions.length && (arpuRequiredConditions[id] = arpuConditions);
          }
        }

      /* Get discounts */
      const discountIds: string[] = []; // applied discounts can be an used as inclusion/exclusion criteria
      const arpuDiscountConditions: IDiscount[] = []; // conditions with arpu as criteria, or dependent on an arpu-related discount, should be checked later
      for (const d of this.data.discounts) {
        const s = selectedAddOnIds.filter((id) => !invoice.items[id].isBundled);
        if (this.isEntitled(s, [...allAddOnIds, ...discountIds], d, undefined, referralType))
          if (
            !this.isEntitled(
              s,
              [...allAddOnIds, ...discountIds, ...arpuDiscountConditions.map((c) => c.discountId)],
              d,
              undefined,
              referralType
            )
          )
            // exclusion criteria includes arpu-related discounts (note this only goes 1 level)
            arpuDiscountConditions.push(d);
          else if (d.arpu !== undefined)
            // arpu-related discount
            arpuDiscountConditions.push(d);
          else {
            this.applyDiscount(d, invoice, selectedAddOnIds, allAddOnIds, options.referral);
            discountIds.push(d.discountId);
          }
      }

      /* Check add-on conditions related to arpu */
      // If any arpu-related conditions are met, these will overwrite any previously applied add-on conditions
      // conditions that do not affect arpu should be checked last
      const finalArpuAddonConditions: Record<string, IAddOnCondition[]> = {};
      for (const [id, conditions] of Object.entries(arpuAddonConditions)) {
        if (invoice.items[id].isExcludedInArpu) {
          // item price does not affect arpu
          finalArpuAddonConditions[id] = conditions;
          continue;
        }
        for (const condition of conditions) {
          if (condition.fee) {
            // fee does not affect arpu
            if (finalArpuAddonConditions[id] === undefined) finalArpuAddonConditions[id] = [];
            finalArpuAddonConditions[id].push(condition);
          } else if (
            this.isEntitled(
              selectedAddOnIds.filter((id) => !invoice.items[id].isBundled),
              allAddOnIds,
              condition,
              invoice.arpu - invoice.items[id].price
            )
          ) {
            // Find first condition that is fulfilled
            this.updateItem(condition, id, invoice);
            break;
          }
        }
      }
      // in case any addon conditions made items bundled at zero
      const _selectedAddOnIds = selectedAddOnIds.filter((id) => !invoice.items[id].isBundled);
      /* Check discount conditions related to arpu */
      // conditions that do not affect arpu should be checked last
      const finalArpuDiscountConditions: IDiscount[] = [];
      for (const d of arpuDiscountConditions) {
        if (d.isExcludedInArpu) {
          // does not affect arpu
          finalArpuDiscountConditions.push(d);
          continue;
        }
        const itemId = this.discountTiedTo(d, _selectedAddOnIds, [...allAddOnIds, ...discountIds]);
        let arpu = invoice.arpu;
        if (itemId !== null && !d.shouldIncludeInArpuComparison) arpu -= invoice.items[itemId].price;
        if (this.isEntitled(_selectedAddOnIds, [...allAddOnIds, ...discountIds], d, arpu, referralType)) {
          this.applyDiscount(d, invoice, selectedAddOnIds, allAddOnIds, options.referral);
          discountIds.push(d.discountId);
        }
      }

      /* Check add-on conditions related to arpu that do not affect arpu */
      for (const [id, conditions] of Object.entries(finalArpuAddonConditions)) {
        const condition = conditions.find((condition) =>
          this.isEntitled(
            selectedAddOnIds.filter((id) => !invoice.items[id].isBundled),
            allAddOnIds,
            condition,
            invoice.arpu
          )
        );
        // Find first condition that is fulfilled
        if (condition) this.updateItem(condition, id, invoice);
      }

      /* Check discount conditions related to arpu that do not affect arpu */
      for (const d of finalArpuDiscountConditions) {
        const itemId = this.discountTiedTo(d, _selectedAddOnIds, [...allAddOnIds, ...discountIds]);
        let arpu = invoice.arpu;
        if (itemId !== null && !d.shouldIncludeInArpuComparison) arpu -= invoice.items[itemId].price;
        if (this.isEntitled(_selectedAddOnIds, [...allAddOnIds, ...discountIds], d, arpu, referralType)) {
          this.applyDiscount(d, invoice, selectedAddOnIds, allAddOnIds, options.referral);
          discountIds.push(d.discountId);
        }
      }

      /* Check required conditions related to arpu */
      for (const [id, conditions] of Object.entries(arpuRequiredConditions)) {
        const valid = conditions.some((condition) =>
          this.isEntitled(_selectedAddOnIds, allAddOnIds, condition, invoice.arpu - invoice.items[id].price)
        );
        invoice.valid = invoice.valid && valid;
      }

      /* Check promos */
      for (const promo of this.data.promos) {
        if (this.isEntitled(selectedAddOnIds, allAddOnIds, promo)) {
          // Check if promotion text need to be shown on specific referral type
          if (promo.referralTypes && promo.referralTypes.length > 0) {
            const match = promo.referralTypes.find((type) => type === referralType);
            if (match === undefined) continue;
          }
          if (promo.isGeneral) {
            // apply to overall
            if (invoice.promos === undefined) invoice.promos = [];
            invoice.promos.push(promo.promoId);
          } else {
            let itemIds: string[] = [];
            if (promo.tiedTo)
              // apply to specific items
              itemIds = promo.tiedTo.filter((id) => allAddOnIds.indexOf(id) >= 0);
            else if (promo.includedPacks)
              // apply to items that triggered this condition
              itemIds = promo.includedPacks.reduce((ids, list) => {
                for (const id of list) if (allAddOnIds.indexOf(id) >= 0) ids.push(id);
                return ids;
              }, [] as string[]);
            for (const id of itemIds) {
              const item = invoice.items[id];
              if (item.promos === undefined) item.promos = [];
              item.promos.push(promo.promoId);
            }
          }
        }
      }

      /* Check terms */
      for (const term of this.data.terms) {
        if (this.isEntitled(selectedAddOnIds, allAddOnIds, term)) {
          if (term.isGeneral) {
            // apply to overall
            if (invoice.terms === undefined) invoice.terms = [];
            invoice.terms.push(term.termId);
          } else {
            let itemIds: string[] = [];
            if (term.tiedTo)
              // apply to specific items
              itemIds = term.tiedTo.filter((id) => allAddOnIds.indexOf(id) >= 0);
            else if (term.includedPacks)
              // apply to items that triggered this condition
              itemIds = term.includedPacks.reduce((ids, list) => {
                for (const id of list) if (allAddOnIds.indexOf(id) >= 0) ids.push(id);
                return ids;
              }, [] as string[]);
            for (const id of itemIds) {
              const item = invoice.items[id];
              if (item.terms === undefined) item.terms = [];
              item.terms.push(term.termId);
            }
          }
        }
      }

      /* Check boxes */ invoice.valid = invoice.valid && invoice.box >= invoice.boxRequired;
      // NOTE: Will update this once multiroom, multiple devices, quantity >= 1 comes in

      /* Check methods */ invoice.valid = invoice.valid && invoice.method === invoice.methodRequired;

      /* Check referral discounts */
      options.referral && this.applyReferral(options.referral, invoice);

      /* Assign promo and campaign codes */
      const codeCondition = this.data.codes.find((condition) =>
        this.isEntitled(selectedAddOnIds, allAddOnIds, condition, invoice.arpu, referralType)
      ); // Find first condition that matches
      if (codeCondition) {
        invoice.promoCode = codeCondition.promo;
        invoice.campaignCode = codeCondition.campaign;
      }

      /* Get different taxes */
      const itemisedTaxes: { id: string; ratio: number }[] = [];
      const totalTaxes: { id: string; ratio: number }[] = [];
      for (const [id, { type, ratio }] of Object.entries(this.data.tax)) {
        invoice.tax[id] = 0;
        invoice.fee.tax[id] = 0;
        switch (type) {
          case TAXTYPE.itemised:
            itemisedTaxes.push({ id, ratio });
            break;
          case TAXTYPE.total:
            totalTaxes.push({ id, ratio });
            break;
          default:
            break;
        }
      }

      /* Get total monthly fee and one-time fee from items */
      let itemisedTaxTotal = 0; // itemised tax on monthly fees
      let fees = 0; // need to get tax for fees that do not already include tax
      let allMonthlyFeeDiscounts: InvoiceDiscount[] = []; // extract discounts
      for (const { price, fee: originalFee, feeIsInclusiveTax, discounts } of Object.values(invoice.items)) {
        invoice.total = this.addRoundedToTwo(invoice.total, price);
        let fee = originalFee ?? 0;
        if (discounts) {
          allMonthlyFeeDiscounts = allMonthlyFeeDiscounts.concat(
            discounts.filter((d) => d.discountType === DISCOUNTTYPE.monthlyFee)
          ); // discounts tied to items
          fee -= discounts.reduce((acc, { discountType, amount }) => {
            discountType === DISCOUNTTYPE.oneTimeFee && (acc += amount);
            return acc;
          }, 0); // get discounted fee
        }
        invoice.fee.total = this.addRoundedToTwo(invoice.fee.total, fee);
        if (!feeIsInclusiveTax) fees = this.addRoundedToTwo(fees, fee);
        for (const { id, ratio } of itemisedTaxes) {
          // Apply tax per item e.g. GST
          const t = this.roundToTwo(price * ratio);
          itemisedTaxTotal = this.addRoundedToTwo(itemisedTaxTotal, t);
          invoice.tax[id] = this.addRoundedToTwo(invoice.tax[id], t); // monthly fees tax
          !feeIsInclusiveTax && (invoice.fee.tax[id] = this.addRoundedToTwo(invoice.fee.tax[id], fee * ratio)); // one-time fees tax
        }
      }
      invoice.subTotal = invoice.total; // total monthly fee without discounts and tax

      const discount = {
        amount: 0, // permanent discounts (no end period)
        itemisedTax: 0, // itemised tax that must be deducted for discount
      };
      const discountPeriods: Record<string, { discount: number; itemisedTax: number }> = {}; // different discounts and taxes by period

      for (const { amount, period } of allMonthlyFeeDiscounts) {
        invoice.total = this.addRoundedToTwo(invoice.total, -amount);
        if (!period)
          // permanent discount
          discount.amount = this.addRoundedToTwo(discount.amount, amount);
        else {
          // temporary discounts
          if (invoice.otherGrandTotals === undefined) invoice.otherGrandTotals = {};
          if (invoice.otherGrandTotals[period] === undefined) invoice.otherGrandTotals[period] = invoice.subTotal;
          if (discountPeriods[period] === undefined) discountPeriods[period] = { discount: 0, itemisedTax: 0 };
          discountPeriods[period].discount = this.addRoundedToTwo(discountPeriods[period].discount, amount);
        }
        /* Get itemised tax deductions for discounts e.g. GST */
        for (const { id, ratio } of itemisedTaxes) {
          const itemisedTax = amount * ratio;
          invoice.tax[id] = this.addRoundedToTwo(invoice.tax[id], -itemisedTax);
          if (!period) discount.itemisedTax = this.addRoundedToTwo(discount.itemisedTax, itemisedTax);
          else
            discountPeriods[period].itemisedTax = this.addRoundedToTwo(
              discountPeriods[period].itemisedTax,
              itemisedTax
            );
        }
      }

      /* Get future totals for each discount period */
      if (invoice.otherGrandTotals)
        for (const period of Object.keys(invoice.otherGrandTotals)) {
          invoice.otherGrandTotals[period] = this.addRoundedToTwo(invoice.otherGrandTotals[period], -discount.amount); // apply permanent discounts
          for (const [limit, { discount }] of Object.entries(discountPeriods)) // grandtotals by discount period limit
            if (parseInt(period) < parseInt(limit))
              // discount only applies within period
              invoice.otherGrandTotals[period] = this.addRoundedToTwo(invoice.otherGrandTotals[period], -discount);
        }

      /* Apply tax on total e.g. SST */
      for (const { id, ratio } of totalTaxes) {
        invoice.tax[id] = this.addRoundedToTwo(invoice.tax[id], invoice.total * ratio); // monthly fees tax
        invoice.fee.tax[id] = this.addRoundedToTwo(invoice.fee.tax[id], fees * ratio); // one-time fees tax

        if (invoice.otherGrandTotals)
          for (const [period, total] of Object.entries(invoice.otherGrandTotals))
            invoice.otherGrandTotals[period] = this.addRoundedToTwo(total, total * ratio);
      }

      /* Apply itemised tax on total e.g. GST */
      if (invoice.otherGrandTotals)
        for (const period of Object.keys(invoice.otherGrandTotals)) {
          invoice.otherGrandTotals[period] = this.addRoundedToTwo(
            invoice.otherGrandTotals[period],
            itemisedTaxTotal - discount.itemisedTax
          ); // permanent discounts
          for (const [limit, { itemisedTax }] of Object.entries(discountPeriods))
            if (parseInt(period) < parseInt(limit))
              // less tax is applied when discount is applied
              invoice.otherGrandTotals[period] = this.addRoundedToTwo(invoice.otherGrandTotals[period], -itemisedTax);
        }

      invoice.arpu = this.roundToTwo(invoice.arpu);
      invoice.grandTotal = this.addRoundedToTwo(
        invoice.total,
        Object.values(invoice.tax).reduce((total, t) => (total += t), 0)
      ); // monthly fees grandtotal
      invoice.fee.grandTotal = this.addRoundedToTwo(
        invoice.fee.total,
        Object.values(invoice.fee.tax).reduce((total, t) => (total += t), 0)
      ); // one-time fees grandtotal

      return invoice;
    }
  }

  /**
   * Apply referral discount. This function apply referral discounts to the invoice object if the referral parameters provided are valid.
   * If there are existing discounts tied to the base pack, all of them will be replaced by a single referral discount on the base pack.
   *
   * NOTE: Period is hardcoded to 24 months. Change it when period is retrieved from API.
   *
   * @param referral Referral parameters
   * @param invoice Invoice
   */
  private applyReferral(referral: ReferralParams, invoice: IInvoice) {
    const { id: basePackId, items } = invoice;
    if (!items[basePackId]) return;

    const amount = this.roundToTwo(items[basePackId].price - referral.discountedPrice);
    invoice.items[basePackId].discounts = [
      {
        referralType: referral.referralType,
        discountId: referral.id,
        discountType: DISCOUNTTYPE.monthlyFee,
        amount,
        period: 24,
        promotionTitle: referral.title,
        promotionDescription: referral.description,
      },
    ];
  }

  /**
   * Apply discount
   * @param condition Discount condition
   * @param invoice Invoice
   * @param selectedAddOnIds List of add-ons selected by user
   * @param allAddOnIds Complete list of all add-ons (selected and bundled)
   * @param referral (optional) Referral applied to invoice
   */
  private applyDiscount(
    condition: IDiscount,
    invoice: IInvoice,
    selectedAddOnIds: string[],
    allAddOnIds: string[],
    referral?: ReferralParams
  ): void {
    const { discountId, amount, percent, discountType, period, isExcludedInArpu } = condition;
    if (!amount && !percent) return;

    const discount: InvoiceDiscount = {
      referralType: this.data.referralType.noReferral,
      discountId,
      amount: amount ?? 0,
      discountType,
      period,
    }; // Get discount
    const itemId = this.discountTiedTo(condition, selectedAddOnIds, allAddOnIds);
    if (itemId === null) return; // discounts must be tied to a specific item, cannot be general

    const item = invoice.items[itemId];
    if (amount === undefined)
      if (percent !== undefined) {
        discount.percent = percent;
        const baseAmount = discountType === DISCOUNTTYPE.monthlyFee ? item.price : item.fee ?? 0;
        discount.amount = this.roundToTwo(percent * baseAmount);
      }

    if (referral && referral.referralType === condition.referralType) {
      // only if discount is dependent on referralType
      discount.referralType = referral.referralType;
      discount.promotionTitle = referral.title;
      discount.promotionDescription = referral.description;
    }

    if (item.discounts === undefined) item.discounts = [];
    item.discounts.push(discount);

    !isExcludedInArpu && discountType === DISCOUNTTYPE.monthlyFee && (invoice.arpu -= discount.amount);
  }

  /**
   * Get item that discount is tied to
   * @param discount Discount condition
   * @param selectedAddOnIds List of add-ons selected by user
   * @param allAddOnIds Complete lsit of all add-ons (selected and bundled)
   */
  private discountTiedTo(discount: IDiscount, selectedAddOnIds: string[], allAddOnIds: string[]): string | null {
    const { tiedTo, includedPacks, applyOnSelectedOnly } = discount;
    let itemId: string | undefined;
    if (tiedTo)
      itemId = tiedTo.find((id) => {
        if (applyOnSelectedOnly) return selectedAddOnIds.indexOf(id) >= 0;
        else return allAddOnIds.indexOf(id) >= 0;
      });
    else if (includedPacks)
      itemId = includedPacks[0].find((id) => {
        if (applyOnSelectedOnly) return selectedAddOnIds.indexOf(id) >= 0;
        else return allAddOnIds.indexOf(id) >= 0;
      });
    return itemId || null;
  }

  /**
   * Update item's info (price, bundled status, pricePlanId)
   * @param condition Condition to update item info
   * @param id ID of item
   * @param invoice Invoice
   */
  private updateItem(condition: IRequiredCondition, id: string, invoice: IInvoice): void {
    const item = invoice.items[id];
    if (condition.price !== undefined || condition.ratioOfArpu !== undefined) {
      let price = item.price;
      condition.price !== undefined && (price = condition.price);
      condition.ratioOfArpu !== undefined && (price = condition.ratioOfArpu * invoice.arpu);
      if (!item.isExcludedInArpu) {
        // item price affects arpu
        invoice.arpu -= item.price;
        invoice.arpu += price;
      }
      item.price = price;
    }
    if (condition.fee !== undefined) item.fee = condition.fee;
    item.isBundled = condition.price === 0;
    item.isRequired = !!condition.isRequired;
    condition.pricePlanId && (item.pricePlanId = condition.pricePlanId);
  }

  /**
   * Check if selection is entitled to condition
   * @param selectedAddOnIds List of add-ons selected by user
   * @param allAddOnIds Complete list of all add-ons (selected and bundled)
   * @param condition Condition to check entitlement
   * @param arpu (optional) Arpu of selection excluding add-on
   * @param referralType (optional) User's referral type
   */
  private isEntitled(
    selectedAddOnIds: string[],
    allAddOnIds: string[],
    condition: IRequiredCondition,
    arpu?: number,
    referralType?: string
  ): boolean {
    let entitled = true;

    /* Check packs condition */
    if (condition.includedPacks)
      entitled =
        entitled &&
        this.checkEntitlement(condition.applyOnSelectedOnly ? selectedAddOnIds : allAddOnIds, condition.includedPacks);
    if (condition.excludedPacks)
      entitled =
        entitled &&
        !condition.excludedPacks.reduce((acc, excludedPackId) => {
          return acc || allAddOnIds.indexOf(excludedPackId) >= 0;
        }, false);

    /* Check arpu condition */
    if (arpu !== undefined && condition.arpu !== undefined) entitled = entitled && arpu >= condition.arpu;

    /* Check referral type */
    if (referralType !== undefined && condition.referralType !== undefined)
      entitled = entitled && referralType === condition.referralType;

    return entitled;
  }

  /**
   * Check if list of items matches condition options
   * @param input List of items
   * @param conditionArray Options for condition to be valid
   */
  private checkEntitlement(input: string[], conditionArray: string[][]): boolean {
    return conditionArray.reduce((acc, innerArray) => {
      return (
        acc &&
        innerArray.reduce((acc, innerItem) => {
          return acc || input.indexOf(innerItem) >= 0;
        }, false as boolean)
      );
    }, true as boolean);
  }

  /**
   * Add numbers together rounded to 2 d.p.
   * @param value1 Number to round and add
   * @param value2 Number to round and add
   */
  private addRoundedToTwo(value1: number, value2: number): number {
    return this.roundToTwo(this.roundToTwo(value1) + this.roundToTwo(value2));
  }

  /**
   * Round number to 2 d.p.
   * @param value Value to round
   */
  private roundToTwo(value: number): number {
    return Math.round(value * 100) / 100;
  }
}

/* Database interfaces */

export interface IPriceConfig {
  /**
   * Tax values
   */
  tax: Record<string, ITax>;
  /**
   * List of base packs with bundled add-ons
   */
  packs: Record<string, IPack>;
  /**
   * List of add-ons (minis, premium add-ons, broadband, boxes)
   */
  addOns: Record<string, IAddOn>;
  /**
   * List of combos of add-ons.
   * Declaring combos here indicates that the combo should be pushed when conditions are met.
   * If condition is met, calculation is restarted with combo added as selected.
   * All combos can occur concurrently: every combo condition will be checked. List higher priority on top.
   */
  comboConditions: IComboCondition[];
  /**
   * List of addons or pack that do no need box
   */
  boxExclusions: string[];
  /**
   * List of addons or pack that do no need method
   */
  methodExclusions: string[];
  /**
   * List of mini conditions for each base pack.
   * Selection is valid only if a valid mini condition is found.
   * Sequence is top-first: only the first valid condition is accepted. List more specific on top.
   */
  miniConditions: Record<string, IMiniCondition[]>;
  /**
   * List of conditions for each add-on to be eligible for special prices.
   * Sequence is top-first: only the first valid condition is accepted. List more specific on top.
   * Applies to packs, add-ons and boxes.
   */
  addOnConditions: Record<string, IAddOnCondition[]>;
  /**
   * List of required conditions for selected item to be valid.
   * Selection is valid only if a valid required condition is found for the affected item.
   * Sequence is top-first: only the first valid condition is accepted. List more specific on top.
   */
  requiredConditions: Record<string, IRequiredCondition[]>;
  /**
   * List of discounts on monthly fees.
   * All discounts can occur concurrently: every discount condition will be checked.
   * Sequence is important as it will be evaluated from top to bottom. List higher priority on top. Other discountIds can be used as exclusion criteria.
   */
  discounts: IDiscount[];
  /**
   * List of promo IDs and conditions
   * All promos can occur concurrently: every promo condition will be checked.
   * As promos are only text-based marketing content, conditions for promos has includedPacks, excludedPacks, isGeneral, tiedTo.
   */
  promos: IPromo[];
  /**
   * List of term IDs and conditions
   * All terms can occur concurrently: every term condition will be checked.
   * As terms are only text-based business content, conditions for terms has includedPacks, excludedPacks, isGeneral, tiedTo.
   */
  terms: ITerm[];
  /**
   * List of promo and campaign codes that should be applied based on selection.
   * Sequence is top-first: only the first valid condition is accepted. List more specific on top.
   */
  codes: ICode[];
  /**
   * List of referral type
   */
  referralType: ReferralType;
}

interface ITax {
  /**
   * Mechanics of tax application.
   */
  type: TAXTYPE;
  /**
   * Amount of tax is based on ratio
   */
  ratio: number;
}

interface IItem {
  /**
   * Monthly fee
   */
  price: number;
  /**
   * ID used by Amdocs to identify item
   */
  pricePlanId: string;
  /**
   * Type of item: pack, mini, addon, box, speed
   */
  type: ITEMTYPE;
}

interface IPack extends IItem {
  /**
   * List of addons bundled in pack
   */
  addOns: string[];
}

interface IAddOn extends IItem {
  /**
   * Indicates if add-on price is not included in arpu
   */
  isExcludedInArpu?: BOOL;
  /**
   * One-time fee applied for box installation
   */
  fee?: number;
  /**
   * Indicates that the one-time fee is already including tax
   */
  feeIsInclusiveTax?: BOOL;
}

interface ICondition {
  /**
   * Combination of packs, add-ons, boxes, speeds, discount that must be included for condition to be valid
   */
  includedPacks?: string[][];
}

interface IAddOnCondition extends ICondition {
  pricePlanId?: string;
  /**
   * Price of item (monthly fee)
   */
  price?: number;
  /**
   * One-time fee of item (boxes)
   */
  fee?: number;
  /**
   * Price of item as a ratio of arpu
   */
  ratioOfArpu?: number;
  /**
   * Indicates if included packs applies to selected packs only (exclude bundled packs)
   */
  applyOnSelectedOnly?: BOOL;
  /**
   * List of packs, add-ons, boxes, speeds, discounts that must be excluded for condition to be valid
   */
  excludedPacks?: string[];
  /**
   * Minimum total ARPU (excluding arpu of the add-on in question) for condition to be valid
   */
  arpu?: number;
  /**
   * Indicates this is a required selection for other items to be valid
   */
  isRequired?: BOOL;
  /**
   * Indicates the variant of items.
   * Currently only used by:
   * - Discounts: for front-end to display different copy for referral related discounts
   * - PC codes: for assigning the referral version of the codes
   */
  referralType?: string;
}

type IRequiredCondition = Partial<IAddOnCondition>;

interface IMiniCondition extends ICondition {
  /**
   * List of prices for each mini selection
   */
  amount: number[];
  /**
   * List of validity based on number of minis selected
   */
  valid: number[];
}

interface InvoiceLinking {
  /**
   * Specifically tie this to certain items if they are present in the invoice.
   * If this is not provided and isGeneral is falsey, it will be automatically tied to the first item that triggered this condition.
   * For discounts, prices of items listed here will be excluded from ARPU if condition depends on arpu.
   */
  tiedTo?: string[];
}

interface IMarketing extends InvoiceLinking {
  /**
   * Indicates that this is applied in general to the whole subscription.
   * Only applicable for promotions and terms, not applicable to discounts.
   * If this is falsey and tiedTo is not provided, it will be automatically tied to the first item that triggered this condition.
   */
  isGeneral?: BOOL;
}

interface IDiscount extends IAddOnCondition, InvoiceLinking {
  /**
   * ID used internally to identify discount specifically
   */
  discountId: string;
  /**
   * Number of months that discount is applicable for
   */
  period?: number;
  /**
   * Amount that is discounted from the original price. Either amount or percent is required.
   */
  amount?: number;
  /**
   * Percent that is discounted from the original price. Either amount or percent is required.
   */
  percent?: number;
  /**
   * Indicates if discount amount applies to monthly fee (price) or one-time fee (fee)
   */
  discountType: DISCOUNTTYPE;
  /**
   * The final arpu is not affected by the discount
   */
  isExcludedInArpu?: BOOL;
  /**
   * The prices of the items tied to the discount are included in the arpu used to determine if the discount is applied
   */
  shouldIncludeInArpuComparison?: BOOL;
}

interface IComboCondition extends Required<ICondition> {
  /**
   * Combo add-on that should be added into invoice when condition is met.
   * If combo add-on is already included, skip combo condition.
   * If combo add-on is added, restart calculation with comboId included as selected add-on.
   */
  comboId: string;
}

interface ICode extends IAddOnCondition {
  /**
   * Amdocs promo code
   */
  promo: string;
  /**
   * Amdocs campaign code
   */
  campaign: string;
}

interface IPromo extends IAddOnCondition, IMarketing {
  promoId: string;
  /**
   * List of referral type to show the promotion text
   */
  referralTypes?: string[];
}

interface ITerm extends IAddOnCondition, IMarketing {
  termId: string;
}

/* Method interfaces */

export interface IInvoice {
  /**
   * Indicates if selection is valid
   */
  valid: boolean;
  /**
   * Base pack ID
   */
  id: string;
  /**
   * Selected speed (if any)
   */
  speed?: string;
  /**
   * Number of selected minis
   */
  mini: number;
  /**
   * Number of required minis for selection to be valid
   */
  miniRequired: number;
  /**
   * Number of required boxes for selection to be valid
   */
  boxRequired: number;
  /**
   * Number of selected boxes
   */
  box: number;
  /**
   * Number of required installation method for selection to be valid
   */
  methodRequired: number;
  /**
   * Number of selected methods
   */
  method: number;
  /**
   * List of invoice items' info
   */
  items: Record<string, InvoiceItem>;
  /**
   * List of promo IDs tied to invoice as a whole
   */
  promos?: string[];
  /**
   * List of terms IDs tied to invoice as a whole
   */
  terms?: string[];
  promoCode: string;
  campaignCode: string;
  /**
   * Total average revenue per user based on selection
   */
  arpu: number;
  /**
   * Total (monthly fee) before discounts (excluding tax)
   */
  subTotal: number;
  /**
   * Total (monthly fee) including discounts (excluding tax)
   */
  total: number;
  /**
   * Taxes levied for monthly fees
   */
  tax: Record<string, number>;
  /**
   * Final total (monthly fee) including discounts and tax
   */
  grandTotal: number;
  /**
   * Other grandtotals (monthly fee) after different campaign periods (less discounts and tax): {<period>: <grandTotal>}
   * Is not present if there are no discounts with campaign periods
   */
  otherGrandTotals?: Record<string, number>;
  /**
   * One-time fee summary
   */
  fee: InvoiceFee;
}

export interface InvoiceItem {
  type: ITEMTYPE;
  /**
   * Monthly fee without tax and discount
   */
  price: number;
  /**
   * Indicates that price is not included in arpu
   */
  isExcludedInArpu?: boolean;
  /**
   * One-time fee applicable for item
   */
  fee?: number;
  /**
   * Indicates that the one-time fee is already including tax
   */
  feeIsInclusiveTax?: boolean;
  pricePlanId?: string;
  /**
   * Indicates that this item is bundled (free) with another selection.
   */
  isBundled?: boolean;
  /**
   * Indicates that this item is required for other items to be valid in the selection.
   */
  isRequired?: boolean;
  /**
   * List of promos tied to item
   */
  promos?: string[];
  /**
   * List of terms tied to item
   */
  terms?: string[];
  /**
   * List of discounts tied to item
   */
  discounts?: InvoiceDiscount[];
}

export interface InvoiceDiscount {
  /**
   * Type of discount.
   */
  referralType: string;
  /**
   * Internal discount ID used to populate copy
   */
  discountId: string;
  /**
   * Amount that is discounted from the original price
   */
  amount: number;
  /**
   * Indicates if discount amount applies to monthly fee (price) or one-time fee (fee)
   */
  discountType: DISCOUNTTYPE;
  /**
   * Percent that is discounted from the original price. Only present if discount condition was configured by percent.
   */
  percent?: number;
  /**
   * List of item IDs (packs, add-ons, devices) that discount is applied to.
   * If not present, means it is applies to the entire subscription
   */
  tiedTo?: string[];
  /**
   * Number of months that discount is applicable for. If falsey value, means discount has no end date
   */
  period?: number;
  /**
   * External promotion description for the discount that is not configured from DF.
   *
   * Example: Promotional description of referral code discount
   */
  promotionDescription?: string;
  /**
   * External promotion title for the discount that is not configured from DF.
   *
   * Example: Promotional title of referral code discount
   */
  promotionTitle?: string;
}

interface InvoiceFee {
  /**
   * Total including discounts (excluding tax)
   */
  total: number;
  /**
   * Taxes levid for one-time fees
   */
  tax: Record<string, number>;
  /**
   * Final total (including discounts & tax)
   */
  grandTotal: number;
}

export interface ReferralParams {
  /**
   * Referral code.
   */
  id: string;
  /**
   * Discounted base pack price.
   * Obtained from `/check-promo-code` API as `refereeOfferPrice`.
   */
  discountedPrice: number;
  /**
   * Description for referral discount.
   * Obtained from `/check-promo-code` API.
   */
  description?: string;
  /**
   * Title for referral discount.
   * Obtained from `/check-promo-code` API.
   */
  title?: string;
  /**
   * Referral type.
   */
  referralType: string;
}

export interface CalculationOptions {
  referral?: ReferralParams;
}

/**
 * List of existing referral type.
 * Incoming referral type to be added here.
 */
interface ReferralType extends Record<string, string> {
  noReferral: string;
}
