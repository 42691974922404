import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "@store";
import { interpolate } from "@lib/common";
import { IPack, ISpeed, ITierConfig, ITierDescription } from "@ducks/config/types";
import { HelpMe, IItems } from "@ducks/shop/types";
import { getTierPrice } from "@ducks/shop";
import ShopCalculator, { TierPrice } from "@lib/calculator";
import { PackSelectionLanguageConfig, SubscriberStepLanguageConfig } from "@ducks/language/types";

export const tierTitleSelector = createSelector<AppState, ITierConfig, ISpeed | null, string, string>(
  (state) => state.shop.tier,
  (state) => state.shop.selectedSpeed,
  (state) => state.language.packSelection.BROADBAND_TITLE,
  getTierTitle
);

function getTierTitle(tier: ITierConfig, selectedSpeed: ISpeed | null, broadbandTitle: string): string {
  let tierTitle = tier.title;
  if (selectedSpeed) tierTitle = interpolate(broadbandTitle, { speed: selectedSpeed.title, title: tier.title });
  return tierTitle;
}
export const tierConfigSelector = createSelector<AppState, ITierConfig, ISpeed | null, IPack | null, ITierConfig>(
  (state) => state.shop.tier,
  (state) => state.shop.selectedSpeed,
  (state) => state.shop.selectedPack,
  getTierConfig
);

function getTierConfig(tier: ITierConfig, speed: ISpeed | null, pack: IPack | null): ITierConfig {
  const filter: Record<keyof ITierDescription, ReturnType<typeof getTierParameter>> = {
    bannerImages: getTierParameter("bannerImages"),
    bannerOverlayImage: getTierParameter("bannerOverlayImage"),
    baseHeading: getTierParameter("baseHeading"),
    packHeading: getTierParameter("packHeading"),
    basicHeading: getTierParameter("basicHeading"),
    popularHeading: getTierParameter("popularHeading"),
    deviceHeading: getTierParameter("deviceHeading"),
    installationMethodHeading: getTierParameter("installationMethodHeading"),
    baseDescriptionHtml: getTierParameter("baseDescriptionHtml"),
    packDescriptionHtml: getTierParameter("packDescriptionHtml"),
    basicDescriptionHtml: getTierParameter("basicDescriptionHtml"),
    popularDescriptionHtml: getTierParameter("popularDescriptionHtml"),
    deviceDescriptionHtml: getTierParameter("deviceDescriptionHtml"),
    installationMethodDescriptionHtml: getTierParameter("installationMethodDescriptionHtml"),
    baseTipHtml: getTierParameter("baseTipHtml"),
    basicTipHtml: getTierParameter("basicTipHtml"),
    popularTipHtml: getTierParameter("popularTipHtml"),
    deviceTipHtml: getTierParameter("deviceTipHtml"),
    subscriberTipHtml: getTierParameter("subscriberTipHtml"),
    installationMethodTipHtml: getTierParameter("installationMethodTipHtml"),
    helpMeTipHtml: getTierParameter("helpMeTipHtml"),
    benefitHighlights: getTierParameter("benefitHighlights"),
    promoBox: getTierParameter("promoBox"),
    basePromoBox: getTierParameter("basePromoBox"),
    packPromoBox: getTierParameter("packPromoBox"),
    basicPromoBox: getTierParameter("basicPromoBox"),
    popularPromoBox: getTierParameter("popularPromoBox"),
    devicePromoBox: getTierParameter("devicePromoBox"),
    subscriberPromoBox: getTierParameter("subscriberPromoBox"),
    installationMethodPromoBox: getTierParameter("installationMethodPromoBox"),
    helpMePromoBox: getTierParameter("helpMePromoBox"),
    promoBanner: getTierParameter("promoBanner"),
    basePromoBanner: getTierParameter("basePromoBanner"),
    packPromoBanner: getTierParameter("packPromoBanner"),
    basicPromoBanner: getTierParameter("basicPromoBanner"),
    popularPromoBanner: getTierParameter("popularPromoBanner"),
    devicePromoBanner: getTierParameter("devicePromoBanner"),
    subscriberPromoBanner: getTierParameter("subscriberPromoBanner"),
    installationMethodPromoBanner: getTierParameter("installationMethodPromoBanner"),
    helpMePromoBanner: getTierParameter("helpMePromoBanner"),
    bbUpsellPopup: getTierParameter("bbUpsellPopup"),
  };
  return Object.entries(filter).reduce(
    (config, [key, funnelParameter]) => {
      config = {
        ...config,
        [key]: funnelParameter(tier, speed, pack),
      };
      return config;
    },
    { ...tier } as ITierConfig
  );
}

function getTierParameter<T>(key: keyof ITierDescription) {
  return (tier: ITierConfig, speed: ISpeed | null, pack: IPack | null) =>
    funnelTierParameter(tier, speed, pack, key) as T;
}

function funnelTierParameter(
  tier: ITierConfig,
  speed: ISpeed | null,
  pack: IPack | null,
  key: keyof ITierDescription
): unknown {
  return pack?.[key] || speed?.[key] || tier[key];
}

export const tierPriceSelector = createSelector<AppState, ShopCalculator, IPack[], ISpeed | null, string, TierPrice>(
  (state) => state.shop.calculator,
  (state) => state.shop.tier.packs,
  (state) => state.shop.selectedSpeed,
  (state) => state.shop.selectedDevice,
  getTierPrice
);

export const showSubscriberStepSelector = createSelector<AppState, ISpeed | null, boolean, boolean>(
  (state) => state.shop.selectedSpeed,
  (state) => state.ulm.isLoggedIn,
  getShowSubscriberStep
);

export const subscriberStepCopySelector = createSelector<
  AppState,
  ISpeed | null,
  PackSelectionLanguageConfig,
  SubscriberStepLanguageConfig
>(
  (state) => state.shop.selectedSpeed,
  (state) => state.language.packSelection,
  getSubscriberStepCopy
);

export const showHelpMeStepSelector = createSelector<AppState, ISpeed | null, boolean | null, boolean, boolean>(
  (state) => state.shop.selectedSpeed,
  (state) => state.shop.isSubscriber,
  (state) => state.ulm.isLoggedIn,
  getShowHelpMeStep
);

export const showPackStepSelector = createSelector<AppState, ISpeed | null, boolean, boolean | null, string, boolean>(
  (state) => state.shop.selectedSpeed,
  (state) => state.ulm.isLoggedIn,
  (state) => state.shop.isSubscriber,
  (state) => state.shop.selectedHelpMe,
  getShowPackStep
);

export const showBasicAddOnsStepSelector = createSelector<AppState, IPack | null, boolean>(
  (state) => state.shop.selectedPack,
  (pack) => (pack ? pack.basicAddons.length > 0 : false)
);

export const showPopularAddOnsStepSelector = createSelector<AppState, IPack | null, boolean>(
  (state) => state.shop.selectedPack,
  (pack) => (pack ? pack.popularAddons.length > 0 : false)
);

export const showDevicesStepSelector = createSelector<AppState, IItems, boolean>(
  (state) => state.shop.devices,
  (devices) => {
    const deviceItems = Object.values(devices);
    return deviceItems.length > 0;
  }
);

export function getDevicesList(pack: IPack | null, speed: ISpeed | null): string[] {
  let devicesList: string[] = [];
  if (pack) {
    devicesList = pack.devices.map((device) => device.id);
    if (speed && speed.commonDevices) {
      const speedDevices = speed.commonDevices;
      devicesList = devicesList.filter((deviceId) => speedDevices.includes(deviceId));
    }
  }
  return devicesList;
}

export const showMethodsStepSelector = createSelector<AppState, IItems, boolean>(
  (state) => state.shop.methods,
  (methods) => {
    const methodItems = Object.values(methods);
    return methodItems.length > 0;
  }
);

function getShowSubscriberStep(selectedSpeed: ISpeed | null, isLoggedIn: boolean): boolean {
  let show = false;
  if (selectedSpeed) show = true;
  else show = !isLoggedIn;
  return show;
}

function getShowHelpMeStep(
  selectedSpeed: ISpeed | null,
  selectedSubscriber: boolean | null,
  isLoggedIn: boolean
): boolean {
  let show = false;
  if (!selectedSpeed) show = isLoggedIn || selectedSubscriber === true;

  return show;
}

function getShowPackStep(
  selectedSpeed: ISpeed | null,
  isLoggedIn: boolean,
  selectedSubscriber: boolean | null,
  selectedHelpMe: string
): boolean {
  let show = false;
  if (selectedSpeed) show = selectedSubscriber !== null;
  else show = isLoggedIn ? selectedHelpMe === HelpMe.Subscribe : selectedSubscriber === false;

  return show;
}

function getSubscriberStepCopy(speed: ISpeed | null, l: PackSelectionLanguageConfig): SubscriberStepLanguageConfig {
  return speed ? l.subscriberStep.bb : l.subscriberStep.tv;
}
