import { combineReducers, configureStore, Action } from "@reduxjs/toolkit";
import { History, createBrowserHistory } from "history";
import { connectRouter, routerMiddleware } from "connected-react-router";
import thunk, { ThunkAction, ThunkDispatch } from "redux-thunk";

import configReducer from "@ducks/config";
import languageReducer from "@ducks/language";
import shopReducer from "@ducks/shop";
import productInfoReducer from "@ducks/productInfo";
import addressReducer from "@ducks/address";
import signUpReducer from "@ducks/signUp";
import otpReducer from "@ducks/otp";
import orderSummaryReducer from "@ducks/orderSummary";
import referralReducer from "@ducks/referral";
import resizerReducer from "@ducks/resizer";
import ulmReducer from "@ducks/ulm";
import customerInfoReducer from "@ducks/customerInfo";
import gtmMiddleware from "./middlewares/gtm";
import ulmMiddleware from "./middlewares/ulm";
import appsLanguageReducer from "@ducks/apps/language";
import appsMetaReducer from "@ducks/apps/meta";
import appsUserReducer from "@ducks/apps/user";
import appsOtpReducer from "@ducks/apps/otp";
import appsErrorReducer from "@ducks/apps/error";
import appsContactReducer from "@ducks/apps/contact";

const rootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    config: configReducer,
    language: languageReducer,
    shop: shopReducer,
    productInfo: productInfoReducer,
    address: addressReducer,
    signUp: signUpReducer,
    otp: otpReducer,
    orderSummary: orderSummaryReducer,
    referral: referralReducer,
    resizer: resizerReducer,
    ulm: ulmReducer,
    customerInfo: customerInfoReducer,
    appsLanguage: appsLanguageReducer,
    appsMeta: appsMetaReducer,
    appsUser: appsUserReducer,
    appsOtp: appsOtpReducer,
    appsError: appsErrorReducer,
    appsContact: appsContactReducer,
  });

export type AppState = ReturnType<ReturnType<typeof rootReducer>>;

export type AppThunk = ThunkAction<void, AppState, undefined, Action<string>>;

export type AppDispatch = ThunkDispatch<AppState, undefined, Action<string>>;

export const history = createBrowserHistory();

const store = configureStore({
  reducer: rootReducer(history),
  middleware: [thunk, gtmMiddleware, routerMiddleware(history), ulmMiddleware],
  devTools: process.env.REACT_APP_USE_REDUX_DEVTOOLS === "true",
});

export default store;
