import { FetchStatus } from "@ducks/types";

export enum ModalType {
  Tier = 1,
  Pack,
  AddOn,
}

export type ChannelConfig =
  | number
  | {
      hd: number;
      sd: number;
    };

export interface AlsoSeeConfig {
  descriptionHtml: string;
  items: string[];
}

export interface ProductInfo {
  id: string;
  type: ModalType;
  banners: string[];
  accordionTitle?: string;
  title: string;
  descriptionHtml: string;
  tier?: string;
  channelHeading?: string;
  channels: ChannelConfig[];
  hideFeatured?: boolean;
  featuredChannels: number[];
  featuredDescriptionHtml?: string;
  streamingApps?: string[];
  featuredStreamingApps?: string[];
  alsoSee?: AlsoSeeConfig;
  disableSeeAll?: boolean;
}

export interface PackProductInfo extends ProductInfo {
  type: ModalType.Pack;
  tier: string;
  channelHeading: string;
}

export type ProductInfoMap = Record<string, ProductInfo>;

export interface ProductInfoConfig {
  contentGuideDomain: string;
  bannerSwiperConfig: Record<string, unknown>; // can't assign as SwiperOption here due to unassignable error while slicing
  modals: ProductInfoMap;
}

export interface Channel {
  id: number;
  title: string;
  stbNumber: string;
  originalImage: string;
  imageUrl: string;
  detailUrl: string;
}

export type ChannelData = Record<string, Channel>;

export interface ProductInfoState {
  config: ProductInfoConfig;
  channelDataFetchStatus: FetchStatus;
  channelData: ChannelData;
  selectedModalId: string | null;
  modalHeadingHeight: number;
}

export interface ChannelDetailsEvent {
  channelTitle: string;
  type: ModalType;
}
