import { createSelector } from "@reduxjs/toolkit";

import { AppState } from "@store";
import { ISpeed } from "@ducks/config/types";

export enum PortalType {
  TV = 1,
  BB = 2,
}

export const portalSelector = createSelector<AppState, ISpeed | null, PortalType>(
  (state) => state.shop.selectedSpeed,
  (speed) => (speed ? PortalType.BB : PortalType.TV)
);
