import { EventsMap } from "redux-beacon";
import { trackChannelClick, trackChannelDetails } from ".";
import { ChannelDetailsEvent, ModalType } from "./types";
import { tierTitleSelector } from "@selectors/packSelection";
import { EventType, getBrand, payTvPackage, ShopEventDefinition } from "@ducks/analytics";
import { portalSelector } from "@selectors/portalType";

/* Event definitions */
const channelDetailsEvent: ShopEventDefinition<string> = (action, state) => {
  const portal = portalSelector(state);
  const tierTitle = tierTitleSelector(state);
  return {
    event: EventType.Click,
    content_type: getBrand(portal),
    content_list_type: `See all channel ${action.payload}`,
    content_title: `${action.payload} channel details link`,
    content_category: payTvPackage,
    content_id: tierTitle,
    content_name: tierTitle,
  };
};

const channelClickEvent: ShopEventDefinition<ChannelDetailsEvent> = (action, state) => {
  const {
    shop: { selectedPack },
    productInfo: { selectedModalId },
    config: { addOns },
  } = state;
  const tierTitle = tierTitleSelector(state);
  const portal = portalSelector(state);

  let categoryTitle = "";

  if (action.payload) {
    const { type, channelTitle } = action.payload;

    switch (type) {
      case ModalType.Tier:
        categoryTitle = "Base";
        break;
      case ModalType.Pack:
        if (selectedPack) categoryTitle = selectedPack.title;
        break;
      case ModalType.AddOn:
        if (selectedModalId) categoryTitle = addOns[selectedModalId].name;
        break;
    }

    return {
      event: EventType.Click,
      content_type: getBrand(portal),
      content_list_type: `${categoryTitle} Channels`,
      content_title: channelTitle,
      content_category: payTvPackage,
      content_id: tierTitle,
      content_name: tierTitle,
    };
  }
};

/* Events map */
const productInfoEventsMap: EventsMap = {
  [trackChannelDetails.toString()]: channelDetailsEvent,
  [trackChannelClick.toString()]: channelClickEvent,
};

export default productInfoEventsMap;
