import { AppState } from "@store";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICustomerInfoState } from "./types";
import { APIResponse, isErrorResponse, getErrorCode } from "@lib/api";
import { ICustomerInfoResponse } from "@ducks/customerInfo/types";

import settings from "@settings";

const initialState: ICustomerInfoState = {
  fetchStatus: {
    loading: false,
    error: false,
    success: false,
  },
  customerInfoData: {
    signature: "",
    title: "",
    name: "",
    idType: "",
    idSuffix: "",
    email: "",
    phoneNumber: "",
  },
};

/* Thunks */
export const fetchCustomerInfo = createAsyncThunk<
  APIResponse<ICustomerInfoResponse>,
  undefined,
  { state: AppState; rejectValue: string | null }
>("customerInfo/fetchCustomerInfo", async (_, { getState, rejectWithValue }) => {
  const { ulmToken } = getState().ulm;

  const response = await fetch(settings.apiEndpoint.getCustomerInfo, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${ulmToken}`,
    },
  });
  const data = (await response.json()) as APIResponse<ICustomerInfoResponse>;

  if (isErrorResponse(data.response)) return rejectWithValue(getErrorCode(data.response));

  return data;
});

const customerInfoSlice = createSlice({
  name: "customerInfo",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomerInfo.fulfilled, (state, action: PayloadAction<APIResponse<ICustomerInfoResponse>>) => {
        const {
          payload: {
            response: {
              signature,
              individual: { title, name, idType, idSuffix, email, phoneNumber },
            },
          },
        } = action;

        state.fetchStatus.success = true;
        state.fetchStatus.error = false;
        state.fetchStatus.loading = false;

        state.customerInfoData.signature = signature;
        state.customerInfoData.title = title;
        state.customerInfoData.name = name;
        state.customerInfoData.idType = idType;
        state.customerInfoData.idSuffix = idSuffix;
        state.customerInfoData.email = email;
        state.customerInfoData.phoneNumber = phoneNumber;
      })
      .addCase(fetchCustomerInfo.pending, (state) => {
        state.fetchStatus.success = false;
        state.fetchStatus.loading = true;
        state.fetchStatus.error = false;
      })
      .addCase(fetchCustomerInfo.rejected, (state) => {
        state.fetchStatus.success = false;
        state.fetchStatus.loading = false;
        state.fetchStatus.error = true;
      });
  },
});

export default customerInfoSlice.reducer;
