import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

declare module "@material-ui/core/styles/createBreakpoints" {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    desktop: true;
    lgModal: true;
  }
}

const breakpoints = createBreakpoints({
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
    desktop: 768, // bp to transition from mobile layout -> desktop layout
    lgModal: 640, // bp to transition from full width -> 640px in large modals
  },
});

export default breakpoints;
