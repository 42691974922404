import { FunctionComponent } from "react";
import MuiButton, { ButtonProps as MuiButtonProps } from "@material-ui/core/Button";

export type ButtonProps = MuiButtonProps & {
  magenta?: boolean;
};

const Button: FunctionComponent<ButtonProps> = ({ magenta, ...props }) => {
  let variant: "text" | "outlined" | "contained" | undefined = "outlined";
  if (props.variant === "text") {
    variant = "text";
  } else if (magenta || props.disabled) {
    variant = "contained";
  }
  const extraButtonProps: MuiButtonProps = {
    variant,
  };
  if (magenta) extraButtonProps.color = "primary";

  return <MuiButton {...props} {...extraButtonProps} />;
};

Button.displayName = "Button";

export default Button;
