import dayjs from "dayjs";
import { EventsMap } from "redux-beacon";
import { customAlphabet } from "nanoid";

import { ecommerceIdCharacters } from "@constants/nanoid";
import { ShopEventDefinition, getBrand } from "@ducks/analytics";
import { getProducts } from "@ducks/analytics";
import { viewThankYou } from ".";
import { portalSelector } from "@selectors/portalType";

const generateRandomId = customAlphabet(ecommerceIdCharacters, 8);

/* Event definitions */
const viewThankYouEvent: ShopEventDefinition = (action, state) => {
  const { invoice } = state.shop;
  const id = `${dayjs().format("YYYYMMDD-HHmmss")}-${generateRandomId()}`;
  const portal = portalSelector(state);
  return {
    event: "ecommerce_purchase",
    content_type: getBrand(portal),
    content_list_type: "Thank You",
    ecommerce: {
      purchase: {
        actionField: {
          id,
          affiliation: getBrand(portal),
          revenue: invoice.grandTotal.toFixed(2),
          tax: invoice.tax.sst.toFixed(2),
        },
        products: getProducts(state),
      },
    },
  };
};

/* Events map */
const thankYouEventsMap: EventsMap = {
  [viewThankYou.toString()]: viewThankYouEvent,
};

export default thankYouEventsMap;
