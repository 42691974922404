import { MetaTags } from "@ducks/config/types";
import { AppsSeoSettings } from "./types";

export const APPS_SEO_SETTINGS: AppsSeoSettings = {
  titleTemplate: "",
  thumbnail: "https://static02.astro.com.my/astro/media/astromain/packagepromotion/astro_share_1.png",
  ogType: "article",
  twitterCard: "summary",
  twitterSite: "@astroshop",
  domain: "",
  themeColor: "#e6007d",
  schemaMarkup: {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "Astro",
    logo: "https://acm-assets.eco.astro.com.my/images/astro-logo.svg",
    sameAs: [
      "https://www.facebook.com/Astro",
      "https://twitter.com/astroonline",
      "https://www.instagram.com/astromalaysia/",
      "https://www.youtube.com/user/astromalaysiatv",
      "https://www.linkedin.com/company/astro",
      "https://en.wikipedia.org/wiki/Astro_(television)",
      "https://www.astro.com.my/",
    ],
  },
  title: "",
  description: "",
  keywords: "",
  imageUrl: "",
};

export const APPS_PAGE_SEO: MetaTags = {
  title: "",
  metas: [
    { name: "robots", content: "noindex, nofollow" },
    { name: "description", content: "" },
  ],
};
