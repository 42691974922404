import { createSlice } from "@reduxjs/toolkit";

import { LanguageConfig } from "./types";
import * as constants from "./constants";
import { fetchAppsConfig, fetchConfig } from "../config";

const initialState: LanguageConfig = {
  packSelection: constants.PACK_SELECTION_LANGUAGE,
  productInfo: constants.PRODUCT_INFO_LANGUAGE,
  orderSummary: constants.ORDER_SUMMARY_LANGUAGE,
  address: constants.ADDRESS_LANGUAGE,
  form: constants.FORM_LANGUAGE,
  otp: constants.OTP_LANGUAGE,
  registerInterest: constants.REGISTER_INTEREST_LANGUAGE,
  thankYou: constants.THANK_YOU_LANGUAGE,
  errorMessage: constants.FULLSCREEN_ERROR_MESSAGE_LANGUAGE,
  genreMap: {},
  logoutModal: constants.LOGOUT_MODAL_LANGUAGE,
};

const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchConfig.fulfilled, (state, action) => {
        const { language } = action.payload;
        if (language) {
          state.packSelection = language.packSelection;
          state.productInfo = language.productInfo;
          state.orderSummary = language.orderSummary;
          state.address = language.address;
          state.form = language.form;
          state.otp = language.otp;
          state.registerInterest = language.registerInterest;
          state.thankYou = language.thankYou;
          state.errorMessage = language.errorMessage;
          state.genreMap = language.genreMap;
          state.logoutModal = language.logoutModal;
        }
      })
      .addCase(fetchAppsConfig.fulfilled, (state, action) => {
        if (action.payload.language?.logoutModal) state.logoutModal = action.payload.language.logoutModal;
      });
  },
});

export default languageSlice.reducer;
