import { EventsMap } from "redux-beacon";
import dayjs from "dayjs";

import {
  EcommerceMode,
  Brand,
  ShopEventDefinition,
  EventType,
  getBrand,
  SignUpSubmitEventDefinition,
  getProducts,
  createEcommerceEvent,
} from "../analytics";
import {
  signUpPageLoad,
  idTypeUpdated,
  genderUpdated,
  ethnicUpdated,
  languageUpdated,
  residentialTypeUpdated,
  installationDateUpdated,
  installationTimeUpdated,
  trackWhyUploadDocument,
  trackProceedToStandardMethod,
  updateLead,
} from ".";
import { FormParameter } from "./types";
import { portalSelector } from "@selectors/portalType";

const SIGN_UP_LIST_TYPE = "Sign Up";

const loadSignUpEvent: ShopEventDefinition = (action, state) => {
  const portal = portalSelector(state);
  const brand = getBrand(portal);

  return createEcommerceEvent({
    mode: EcommerceMode.Checkout,
    brand,
    listType: SIGN_UP_LIST_TYPE,
    products: getProducts(state),
    actionStep: 1,
  });
};

const idTypeEvent: ShopEventDefinition<string> = (action, state) => {
  const {
    language: {
      form: { ID: category },
    },
    signUp: {
      config: {
        id: { types },
      },
    },
  } = state;
  const portal = portalSelector(state);

  return createDropdownEvent({
    brand: getBrand(portal),
    category,
    value: getDropdownLabel(types, action.payload ?? ""),
  });
};

const genderEvent: ShopEventDefinition<string> = (action, state) => {
  const {
    language: {
      form: { GENDER: category },
    },
    signUp: {
      config: {
        gender: { items },
      },
    },
  } = state;
  const portal = portalSelector(state);

  return createDropdownEvent({
    brand: getBrand(portal),
    category,
    value: getDropdownLabel(items, action.payload ?? ""),
  });
};

const ethnicEvent: ShopEventDefinition<string> = (action, state) => {
  const {
    language: {
      form: { ETHNIC: category },
    },
    signUp: {
      config: {
        ethnic: { items },
      },
    },
  } = state;
  const portal = portalSelector(state);

  return createDropdownEvent({
    brand: getBrand(portal),
    category,
    value: getDropdownLabel(items, action.payload ?? ""),
  });
};

const languageEvent: ShopEventDefinition<string> = (action, state) => {
  const {
    language: {
      form: { LANGUAGE: category },
    },
    signUp: {
      config: {
        language: { items },
      },
    },
  } = state;
  const portal = portalSelector(state);

  return createDropdownEvent({
    brand: getBrand(portal),
    category,
    value: getDropdownLabel(items, action.payload ?? ""),
  });
};

const residentialTypeEvent: ShopEventDefinition<number> = (action, state) => {
  const {
    language: {
      form: { RESIDENTIAL_TYPE: category },
    },
    signUp: {
      config: {
        residentialType: { items },
      },
    },
  } = state;
  const portal = portalSelector(state);

  return createDropdownEvent({
    brand: getBrand(portal),
    category,
    value: getDropdownLabel(items, action.payload ?? ""),
  });
};

const proceedToStandardMethodEvent: ShopEventDefinition = (action, state) => {
  const portal = portalSelector(state);
  const { pathname, search } = state.router.location;

  return {
    event: EventType.Click,
    content_type: getBrand(portal),
    content_list_type: "Plug & Play Not Available Popup",
    content_category: "Proceed",
    content_title: `${pathname}${search}`,
  };
};

const installationDateEvent: ShopEventDefinition<string> = (action, state) => {
  const {
    language: {
      form: { INSTALLATION_DATE: category },
    },
  } = state;
  const portal = portalSelector(state);

  return createDropdownEvent({
    brand: getBrand(portal),
    category,
    value: formatInstallationDate(action.payload ?? ""),
  });
};

const installationTimeEvent: ShopEventDefinition<string> = (action, state) => {
  const {
    language: {
      form: { INSTALLATION_TIME: category },
    },
    signUp: {
      config: {
        installationTime: { items },
      },
    },
  } = state;
  const portal = portalSelector(state);

  return createDropdownEvent({
    brand: getBrand(portal),
    category,
    value: getDropdownLabel(items, action.payload ?? ""),
  });
};

const whyUploadDocumentEvent: ShopEventDefinition = (action, state) => {
  const {
    language: {
      form: { UPLOAD_DOC: category, WHY_NEED_THIS: title },
    },
  } = state;
  const portal = portalSelector(state);

  return {
    event: EventType.Click,
    content_type: getBrand(portal),
    content_list_type: SIGN_UP_LIST_TYPE,
    content_category: category,
    content_title: title,
  };
};

const submitEvent: SignUpSubmitEventDefinition = (action, state) => {
  const {
    signUp: {
      config: {
        id: { types: idTypeList },
        gender: { items: genderList },
        ethnic: { items: ethnicList },
        language: { items: languageList },
        residentialType: { items: residentialTypeList },
        installationTime: { items: installationTimeList },
      },
      idType: { value: idType },
      gender: { value: gender },
      ethnic: { value: ethnic },
      language: { value: language },
      residentialType: { value: residentialType },
      city: { value: city },
      state: { value: stateValue },
      installationDate: { value: installationDate },
      installationTime: { value: installationTime },
    },
  } = state;
  const portal = portalSelector(state);

  return {
    event: EventType.Submit,
    content_type: getBrand(portal),
    content_list_type: SIGN_UP_LIST_TYPE,
    content_title: "Proceed",
    id_type: getDropdownLabel(idTypeList, idType),
    gender: getDropdownLabel(genderList, gender),
    ethnic: getDropdownLabel(ethnicList, ethnic),
    language: getDropdownLabel(languageList, language),
    residential_type: getDropdownLabel(residentialTypeList, residentialType),
    city,
    state: stateValue,
    installation_date: installationDate ? formatInstallationDate(installationDate) : "",
    installation_time: installationTime ? getDropdownLabel(installationTimeList, installationTime) : "",
  };
};

const signUpEventsMap: EventsMap = {
  [signUpPageLoad.toString()]: loadSignUpEvent,
  [idTypeUpdated.toString()]: idTypeEvent,
  [genderUpdated.toString()]: genderEvent,
  [ethnicUpdated.toString()]: ethnicEvent,
  [languageUpdated.toString()]: languageEvent,
  [residentialTypeUpdated.toString()]: residentialTypeEvent,
  [trackProceedToStandardMethod.toString()]: proceedToStandardMethodEvent,
  [installationDateUpdated.toString()]: installationDateEvent,
  [installationTimeUpdated.toString()]: installationTimeEvent,
  [trackWhyUploadDocument.toString()]: whyUploadDocumentEvent,
  [updateLead.fulfilled.toString()]: submitEvent,
};

export default signUpEventsMap;

function createDropdownEvent(params: { brand: Brand; category: string; value: string }) {
  return {
    event: EventType.Dropdown,
    content_type: params.brand,
    content_list_type: SIGN_UP_LIST_TYPE,
    content_category: params.category,
    content_title: params.value,
  };
}

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
function getDropdownLabel(list: FormParameter[], selectedValue: any): string {
  const item = list.find(({ value }) => value === selectedValue);
  return item?.label ?? "";
}

function formatInstallationDate(date: string): string {
  return dayjs(date).format("DD / MM / YYYY");
}
