import { createSlice, PayloadAction, createAction } from "@reduxjs/toolkit";
import { replace, push } from "connected-react-router";
import { shallowEqual } from "react-redux";

import {
  ShopState,
  IItems,
  ISelectionUpdate,
  AddOnSelection,
  BundleAlert,
  IItemInfo,
  InitShopState,
  UpdateSelectedPackPayload,
  UpdateSelectedAddOnsPayload,
  UpdateSelectedDevicePayload,
  UpdateSelectedMethodPayload,
  PromoLinkEventParams,
  InitShopMeta,
  InitShopNoPack,
  SelectionUpdateError,
  ShopError,
  HelpMe,
  BbUpsellChoice,
  UpdateSelectedSubscriberPayload,
} from "./types";
import * as constants from "./constants";
import {
  ITierConfig,
  ISpeed,
  IPack,
  ConfigState,
  IAddOnConfig,
  IDeviceConfig,
  InstallationMethodMap,
  AddOn,
  AddOnWithOptions,
} from "../config/types";
import { pushWithParams } from "../route";
import { ReferralCodeItem } from "../referral/types";
import { emailUpdated } from "../signUp";
import { AppState, AppThunk } from "@store";
import { dedupe, interpolate } from "@lib/common";
import ShopCalculator, { IInvoice, CalculationOptions, ITEMTYPE, TierPrice, IPriceConfig } from "@lib/calculator";
import ShopUrlQueryMapper, { isShopQueryParameter, ShopUrlQueryParameters, urlParameterKeys } from "@lib/urlQueryMap";
import { signUpFormPath, checkCoveragePath } from "@constants/paths";
import { PackSelectionLanguageConfig } from "@ducks/language/types";
import { portalSelector, PortalType } from "@selectors/portalType";
import { tierConfigSelector } from "@selectors/packSelection";
import { getDevicesList } from "@selectors/packSelection";
import { LoginType } from "../analytics";

const initialState: ShopState = {
  configStatus: {
    loading: false,
    error: false,
    success: false,
  },
  tier: constants.TIER_CONFIG,
  calculator: new ShopCalculator(),
  urlQueryMapper: new ShopUrlQueryMapper(),
  invoice: constants.INVOICE_BLANK,
  selectedSpeed: null,
  packs: {},
  devices: {},
  methods: {},
  selectedPack: null,
  selectedPackBundle: [],
  selectedDevice: "",
  selectedAddOns: [],
  selectedMethod: "",
  bbUpsellChoice: BbUpsellChoice.NOT_SET,
  isBbUpsellModalOpen: false,
  listedAddOns: {},
  comboAddOns: [],
  bundledAddOns: [],
  requiredAddOns: [],
  addOnSelection: null,
  bundleMessage: null,
  error: {
    alert: false,
    pack: false,
    basicAddOns: false,
    devices: false,
    method: false,
  },
  showUpsellPopup: false,
  urlQueryParameters: {
    tier: "",
    speed: "",
    pack: "",
    devices: "",
    addons: "",
  },
  isSubscriber: null,
  selectedHelpMe: "",
  removeDevice: false,
};

const shopSlice = createSlice({
  name: "shop",
  initialState,
  reducers: {
    initShopError(state, action: PayloadAction<string>) {
      state.configStatus = {
        loading: false,
        error: true,
        errorCode: action.payload,
        success: false,
      };
    },
    initPackWithoutSelectionSuccess: {
      reducer: (state, action: PayloadAction<InitShopNoPack, string, InitShopMeta>) => {
        state.configStatus = {
          loading: false,
          error: false,
          success: true,
        };
        state.calculator = action.payload.calculator;
        state.urlQueryMapper = action.payload.urlQueryMapper;
        state.tier = action.payload.tier;
        state.selectedSpeed = action.payload.selectedSpeed;
        state.packs = action.payload.packs;
        state.urlQueryParameters = action.payload.urlQueryParameters;
      },
      prepare: (payload: InitShopNoPack) => {
        return { payload, meta: { init: true } };
      },
    },
    initPackSelectionSuccess: {
      reducer(state, action: PayloadAction<InitShopState, string, InitShopMeta>) {
        // TODO: move updates to extraReducers using builder.addMatcher
        state.configStatus = {
          loading: false,
          error: false,
          success: true,
        };
        state.calculator = action.payload.calculator;
        state.urlQueryMapper = action.payload.urlQueryMapper;
        state.tier = action.payload.tier;
        state.selectedSpeed = action.payload.selectedSpeed;
        state.selectedPack = action.payload.selectedPack;
        state.selectedDevice = action.payload.selectedDevice;
        state.selectedMethod = action.payload.selectedMethod;
        state.packs = action.payload.packs;
        state.devices = action.payload.devices;
        state.methods = action.payload.methods;

        state.invoice = action.payload.invoice;
        state.selectedPackBundle = action.payload.selectedPackBundle;
        state.selectedAddOns = action.payload.selectedAddOns;
        state.comboAddOns = action.payload.comboAddOns;
        state.bundledAddOns = action.payload.bundledAddOns;
        state.requiredAddOns = action.payload.requiredAddOns;
        state.listedAddOns = action.payload.listedAddOns;
        state.addOnSelection = action.payload.addOnSelection;
        state.urlQueryParameters = action.payload.urlQueryParameters;
        state.bundleMessage = action.payload.bundleMessage;
        state.error.pack = action.payload.error.pack;
        state.error.basicAddOns = action.payload.error.basicAddOns;
        state.error.devices = action.payload.error.devices;
        state.error.method = action.payload.error.method;
      },
      prepare(payload: InitShopState) {
        return { payload, meta: { init: true } };
      },
    },
    initSignUpSuccess: {
      reducer(state, action: PayloadAction<InitShopState, string, InitShopMeta>) {
        // TODO: move updates to extraReducers using builder.addMatcher
        state.configStatus = {
          loading: false,
          error: false,
          success: true,
        };
        state.calculator = action.payload.calculator;
        state.urlQueryMapper = action.payload.urlQueryMapper;
        state.tier = action.payload.tier;
        state.selectedSpeed = action.payload.selectedSpeed;
        state.selectedPack = action.payload.selectedPack;
        state.selectedDevice = action.payload.selectedDevice;
        state.selectedMethod = action.payload.selectedMethod;
        state.packs = action.payload.packs;
        state.devices = action.payload.devices;
        state.methods = action.payload.methods;

        state.invoice = action.payload.invoice;
        state.selectedPackBundle = action.payload.selectedPackBundle;
        state.selectedAddOns = action.payload.selectedAddOns;
        state.comboAddOns = action.payload.comboAddOns;
        state.bundledAddOns = action.payload.bundledAddOns;
        state.requiredAddOns = action.payload.requiredAddOns;
        state.listedAddOns = action.payload.listedAddOns;
        state.addOnSelection = action.payload.addOnSelection;
        state.urlQueryParameters = action.payload.urlQueryParameters;
        state.bundleMessage = action.payload.bundleMessage;
        state.error.pack = action.payload.error.pack;
        state.error.basicAddOns = action.payload.error.basicAddOns;
        state.error.devices = action.payload.error.devices;
        state.error.method = action.payload.error.method;
      },
      prepare(payload: InitShopState) {
        return { payload, meta: { init: true } };
      },
    },
    initCheckCoverageSuccess: {
      reducer(state, action: PayloadAction<InitShopState, string, InitShopMeta>) {
        // TODO: move updates to extraReducers using builder.addMatcher
        state.configStatus = {
          loading: false,
          error: false,
          success: true,
        };
        state.calculator = action.payload.calculator;
        state.urlQueryMapper = action.payload.urlQueryMapper;
        state.tier = action.payload.tier;
        state.selectedSpeed = action.payload.selectedSpeed;
        state.selectedPack = action.payload.selectedPack;
        state.selectedDevice = action.payload.selectedDevice;
        state.packs = action.payload.packs;
        state.devices = action.payload.devices;
        state.methods = action.payload.methods;

        state.invoice = action.payload.invoice;
        state.selectedPackBundle = action.payload.selectedPackBundle;
        state.selectedAddOns = action.payload.selectedAddOns;
        state.comboAddOns = action.payload.comboAddOns;
        state.bundledAddOns = action.payload.bundledAddOns;
        state.requiredAddOns = action.payload.requiredAddOns;
        state.listedAddOns = action.payload.listedAddOns;
        state.addOnSelection = action.payload.addOnSelection;
        state.urlQueryParameters = action.payload.urlQueryParameters;
        state.bundleMessage = action.payload.bundleMessage;
        state.error.pack = action.payload.error.pack;
        state.error.basicAddOns = action.payload.error.basicAddOns;
        state.error.devices = action.payload.error.devices;
        state.error.method = action.payload.error.method;
      },
      prepare(payload: InitShopState) {
        return { payload, meta: { init: true } };
      },
    },
    initOrderSummarySuccess(state, action: PayloadAction<InitShopState>) {
      // TODO: move updates to extraReducers using builder.addMatcher
      state.configStatus = {
        loading: false,
        error: false,
        success: true,
      };
      state.calculator = action.payload.calculator;
      state.urlQueryMapper = action.payload.urlQueryMapper;
      state.tier = action.payload.tier;
      state.selectedSpeed = action.payload.selectedSpeed;
      state.selectedPack = action.payload.selectedPack;
      state.selectedDevice = action.payload.selectedDevice;
      state.packs = action.payload.packs;
      state.devices = action.payload.devices;
      state.methods = action.payload.methods;

      state.invoice = action.payload.invoice;
      state.selectedPackBundle = action.payload.selectedPackBundle;
      state.selectedAddOns = action.payload.selectedAddOns;
      state.comboAddOns = action.payload.comboAddOns;
      state.bundledAddOns = action.payload.bundledAddOns;
      state.requiredAddOns = action.payload.requiredAddOns;
      state.listedAddOns = action.payload.listedAddOns;
      state.addOnSelection = action.payload.addOnSelection;
      state.urlQueryParameters = action.payload.urlQueryParameters;
      state.bundleMessage = action.payload.bundleMessage;
      state.error.pack = action.payload.error.pack;
      state.error.basicAddOns = action.payload.error.basicAddOns;
      state.error.devices = action.payload.error.devices;
      state.error.method = action.payload.error.method;
    },
    updateSelectedPack(state, action: PayloadAction<UpdateSelectedPackPayload>) {
      state.selectedPack = action.payload.selectedPack;
      state.selectedDevice = action.payload.selectedDevice;
      state.selectedMethod = action.payload.selectedMethod;
      state.devices = action.payload.devices;
      state.methods = action.payload.methods;
      state.removeDevice = action.payload.removeDevice;

      state.invoice = action.payload.invoice;
      state.selectedPackBundle = action.payload.selectedPackBundle;
      state.selectedAddOns = action.payload.selectedAddOns;
      state.comboAddOns = action.payload.comboAddOns;
      state.bundledAddOns = action.payload.bundledAddOns;
      state.requiredAddOns = action.payload.requiredAddOns;
      state.listedAddOns = action.payload.listedAddOns;
      state.addOnSelection = action.payload.addOnSelection;
      state.urlQueryParameters = action.payload.urlQueryParameters;
      state.bundleMessage = action.payload.bundleMessage;
      state.error.pack = false;
      state.error.basicAddOns = false;
      state.error.devices = false;
      state.error.method = false;
    },
    updateSelectedAddOns(state, action: PayloadAction<UpdateSelectedAddOnsPayload>) {
      state.methods = action.payload.methods;
      state.devices = action.payload.devices;
      state.invoice = action.payload.invoice;
      state.selectedPackBundle = action.payload.selectedPackBundle;
      state.selectedAddOns = action.payload.selectedAddOns;
      state.selectedDevice = action.payload.selectedDevice;
      state.selectedMethod = action.payload.selectedMethod;
      state.comboAddOns = action.payload.comboAddOns;
      state.bundledAddOns = action.payload.bundledAddOns;
      state.requiredAddOns = action.payload.requiredAddOns;
      state.listedAddOns = action.payload.listedAddOns;
      state.addOnSelection = action.payload.addOnSelection;
      state.urlQueryParameters = action.payload.urlQueryParameters;
      state.bundleMessage = action.payload.bundleMessage;
      state.error.pack = action.payload.error.pack;
      state.error.basicAddOns = action.payload.error.basicAddOns;
      state.error.devices = action.payload.error.devices;
      state.error.method = action.payload.error.method;
    },
    updateSelectedDevice(state, action: PayloadAction<UpdateSelectedDevicePayload>) {
      state.selectedDevice = action.payload.selectedDevice;
      state.selectedMethod = action.payload.selectedMethod;
      state.methods = action.payload.methods;

      state.invoice = action.payload.invoice;
      state.selectedPackBundle = action.payload.selectedPackBundle;
      state.selectedAddOns = action.payload.selectedAddOns;
      state.comboAddOns = action.payload.comboAddOns;
      state.bundledAddOns = action.payload.bundledAddOns;
      state.requiredAddOns = action.payload.requiredAddOns;
      state.listedAddOns = action.payload.listedAddOns;
      state.addOnSelection = action.payload.addOnSelection;
      state.urlQueryParameters = action.payload.urlQueryParameters;
      state.bundleMessage = action.payload.bundleMessage;
      state.error.pack = action.payload.error.pack;
      state.error.basicAddOns = action.payload.error.basicAddOns;
      state.error.devices = action.payload.error.devices;
      state.error.method = action.payload.error.method;
    },
    updateSelectedMethod(state, action: PayloadAction<UpdateSelectedMethodPayload>) {
      state.selectedMethod = action.payload.selectedMethod;

      state.invoice = action.payload.invoice;
      state.selectedPackBundle = action.payload.selectedPackBundle;
      state.selectedAddOns = action.payload.selectedAddOns;
      state.comboAddOns = action.payload.comboAddOns;
      state.bundledAddOns = action.payload.bundledAddOns;
      state.requiredAddOns = action.payload.requiredAddOns;
      state.listedAddOns = action.payload.listedAddOns;
      state.addOnSelection = action.payload.addOnSelection;
      state.urlQueryParameters = action.payload.urlQueryParameters;
      state.bundleMessage = action.payload.bundleMessage;
      state.error.pack = action.payload.error.pack;
      state.error.basicAddOns = action.payload.error.basicAddOns;
      state.error.devices = action.payload.error.devices;
      state.error.method = action.payload.error.method;
    },
    updateSelectedSubscriber(state, action: PayloadAction<UpdateSelectedSubscriberPayload>) {
      state.isSubscriber = action.payload.isSubscriber;
      state.selectedDevice = action.payload.selectedDevice;
      state.selectedMethod = action.payload.selectedMethod;
      state.devices = action.payload.devices;
      state.methods = action.payload.methods;
      state.removeDevice = action.payload.removeDevice;

      state.invoice = action.payload.invoice;
      state.selectedPackBundle = action.payload.selectedPackBundle;
      state.selectedAddOns = action.payload.selectedAddOns;
      state.comboAddOns = action.payload.comboAddOns;
      state.bundledAddOns = action.payload.bundledAddOns;
      state.requiredAddOns = action.payload.requiredAddOns;
      state.listedAddOns = action.payload.listedAddOns;
      state.addOnSelection = action.payload.addOnSelection;
      state.urlQueryParameters = action.payload.urlQueryParameters;
      state.bundleMessage = action.payload.bundleMessage;
      state.error.pack = action.payload.error.pack;
      state.error.basicAddOns = action.payload.error.basicAddOns;
      state.error.devices = action.payload.error.devices;
      state.error.method = action.payload.error.method;
    },
    updateSubscriberWithoutPack(state, action: PayloadAction<boolean>) {
      state.isSubscriber = action.payload;
    },
    updateSelectedHelpMe(state, action: PayloadAction<string>) {
      state.selectedHelpMe = action.payload;
    },
    hideBundleAlert(state) {
      state.bundleMessage = null;
    },
    showErrorModal(state, action: PayloadAction<SelectionUpdateError>) {
      state.error.alert = true;
      state.error.pack = action.payload.pack;
      state.error.basicAddOns = action.payload.basicAddOns;
      state.error.devices = action.payload.devices;
      state.error.method = action.payload.method;
    },
    hideErrorModal(state) {
      state.error.alert = false;
    },
    showUpsellPopup(state) {
      state.showUpsellPopup = true;
    },
    hideUpsellPopup(state) {
      state.showUpsellPopup = false;
    },
    invoiceUpdated(state, action: PayloadAction<IInvoice>) {
      state.invoice = action.payload;
    },
    showBbUpsellModal(state) {
      state.isBbUpsellModalOpen = true;
    },
    hideBbUpsellModal(state) {
      state.isBbUpsellModalOpen = false;
    },
    updateBbUpsellChoice(state, action: PayloadAction<BbUpsellChoice>) {
      state.bbUpsellChoice = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(emailUpdated, (state, action) => {
      // removing this causes reference error, need to look into it later
    });
  },
});

const {
  initPackWithoutSelectionSuccess,
  initShopError,
  initPackSelectionSuccess,
  initSignUpSuccess,
  initCheckCoverageSuccess,
  initOrderSummarySuccess,
  updateSelectedPack,
  updateSelectedAddOns,
  updateSelectedDevice,
  updateSelectedMethod,
  updateSelectedSubscriber,
  updateSubscriberWithoutPack: updateIsSubscriberWithoutPack,
  updateSelectedHelpMe,
  hideBundleAlert,
  showErrorModal,
  hideErrorModal,
  showUpsellPopup,
  hideUpsellPopup,
  invoiceUpdated,
  updateBbUpsellChoice,
  showBbUpsellModal,
  hideBbUpsellModal,
} = shopSlice.actions;

export {
  initPackWithoutSelectionSuccess,
  initPackSelectionSuccess,
  initSignUpSuccess,
  initCheckCoverageSuccess,
  initOrderSummarySuccess,
  updateSelectedPack,
  updateSelectedAddOns,
  updateSelectedDevice,
  updateSelectedMethod,
  updateSelectedSubscriber,
  updateIsSubscriberWithoutPack as updateSubscriberWithoutPack,
  updateSelectedHelpMe,
  hideBundleAlert,
  showErrorModal,
  hideErrorModal,
  showUpsellPopup,
  hideUpsellPopup,
  invoiceUpdated,
  updateBbUpsellChoice,
  showBbUpsellModal,
  hideBbUpsellModal,
};

export default shopSlice.reducer;

export const proceedWithBbUpsellChoice =
  (bbUpsellChoice: BbUpsellChoice): AppThunk =>
  (dispatch) => {
    dispatch(updateBbUpsellChoice(bbUpsellChoice));
    dispatch(hideBbUpsellModal());
    dispatch(shopProceed());
  };

/* Thunks */
// TODO: Refactor how we initiatiate all pages from URL query string
export const initPackSelection = (): AppThunk => (dispatch, getState) => {
  try {
    const {
      config,
      language: { packSelection: l },
      shop,
      router: {
        location: { pathname, search },
      },
    } = getState();
    const {
      configStatus: { success: shopConfigRetrieved },
    } = shop;
    const { tiers, price, addOns: addOnConfig, devices: deviceConfig, installationMethods: methods } = config;

    if (shopConfigRetrieved) dispatch(trackViewPack());
    else {
      // Get tier
      const tierPath = pathname.replace(/\//g, "");
      const tier = getTier(tiers, tierPath);

      // Default: no selection
      let selectedPack: IPack | null = null;
      let selectedDevice = "";
      let selectedAddOns: string[] = [];
      let selectedMethod = "";

      // Get preselected pack (if any)
      const preSelectedPack = tier.packs.find((p) => p.id === tier.preSelectedPack);
      if (preSelectedPack) {
        selectedPack = preSelectedPack;
        selectedDevice = preSelectedPack.preSelectedDevice;
        if (selectedDevice)
          selectedMethod =
            preSelectedPack.devices.find((device) => device.id === selectedDevice)?.preSelectedMethod || "";
      }

      // Get query parameters (if any)
      const urlQueryMapper = new ShopUrlQueryMapper(tiers, addOnConfig, deviceConfig, methods);
      const params = urlQueryMapper.getSelection(search, tier.pageUrl);
      // Get speed
      const selectedSpeed = getSpeed(tier.speeds, params.speed);

      // Grab selection from query parameters
      // If invalid, load default selection
      const pack = tier.packs.find((p) => p.id === params.pack);
      if (pack) {
        // If pack is valid, maintain valid device and add-ons, or take preselected device if configured for pack
        selectedPack = pack;
        const device =
          pack.devices.find((device) => device.id === params.devices[0]) ??
          pack.devices.find((device) => device.id === pack.preSelectedDevice);
        if (device) {
          selectedDevice = device.id;
          if (device.methods) {
            const method =
              device.methods.find((method) => method === params.method) ??
              device.methods.find((method) => method === device.preSelectedMethod);
            selectedMethod = method || "";
          }
        }
        const packAddOnIds = normalizeAddOnIds([...pack.basicAddons, ...pack.popularAddons]);
        selectedAddOns = dedupe(params.addOns).filter((addOnId) => packAddOnIds.indexOf(addOnId) >= 0);
      }
      const calculator = new ShopCalculator(price);
      if (selectedPack) {
        const updates = selectionUpdated(
          {
            ...shop,
            calculator,
            urlQueryMapper,
            tier,
            selectedSpeed,
            selectedPack,
            selectedAddOns,
            selectedDevice,
            selectedMethod,
          },
          config,
          selectedPack,
          [],
          l
        );
        dispatch(updatePackSelectionUrl(updates.urlQueryParameters));
        dispatch(
          initPackSelectionSuccess({
            calculator,
            urlQueryMapper,
            tier,
            selectedSpeed,
            ...updates,
            bundleMessage: null, // disable bundling-related events
            selectedPack,
            selectedDevice,
            selectedMethod,
            packs: getPackPrices(calculator, tier, selectedSpeed),
            devices: getDevicePrices(calculator, selectedPack, selectedAddOns, selectedSpeed),
            methods: getMethodPrices(calculator, selectedPack, selectedAddOns, selectedDevice),
          })
        );
      } else {
        const urlQueryParameters = urlQueryMapper.getQueryParameters({
          tier: tier.pageUrl,
          speed: selectedSpeed?.id,
          addOns: [],
          devices: [],
          method: "",
        });
        dispatch(updatePackSelectionUrl(urlQueryParameters));
        dispatch(
          initPackWithoutSelectionSuccess({
            calculator,
            urlQueryMapper,
            tier,
            selectedSpeed,
            packs: getPackPrices(calculator, tier, selectedSpeed),
            urlQueryParameters,
          })
        );
      }
    }
  } catch (e) {
    const errorCode = e?.message ?? "GENERIC_ERROR";
    dispatch(initShopError(errorCode));
  }
};

export const initSignUp = (): AppThunk => (dispatch, getState) => {
  try {
    const {
      config: { tiers, price, addOns: addOnConfig, devices: deviceConfig, installationMethods: methods },
      language: { packSelection: l },
      shop,
      router: {
        location: { search },
      },
    } = getState();

    // Get query parameters for order summary
    const urlQueryMapper = new ShopUrlQueryMapper(tiers, addOnConfig, deviceConfig, methods);
    const params = urlQueryMapper.getSelection(search);
    // Get tier
    const tier = getTier(tiers, params.tier);
    // Get speed
    const selectedSpeed = getSpeed(tier.speeds, params.speed);
    if (!!selectedSpeed) {
      dispatch(push({ pathname: checkCoveragePath, search }));
      return;
    }
    // Get pack
    const pack = tier.packs.find((p) => p.id === params.pack);
    if (!pack) throw new Error("NOT_FOUND");
    // Get device
    const device = pack.devices.find((device) => device.id === params.devices[0])?.id;
    if (!device) throw new Error("NOT_FOUND");
    // Get add-ons
    const packAddOnIds = normalizeAddOnIds([...pack.basicAddons, ...pack.popularAddons]);
    const validAddOns =
      dedupe(params.addOns).length === params.addOns.length &&
      params.addOns.every((addOnId) => packAddOnIds.indexOf(addOnId) >= 0);
    if (!validAddOns) throw new Error("NOT_FOUND");
    // Get shop pricing permutation
    const calculator = new ShopCalculator(price);
    const updates = selectionUpdated(
      {
        ...shop,
        calculator,
        urlQueryMapper,
        tier,
        selectedSpeed,
        selectedAddOns: params.addOns,
        selectedDevice: device,
        selectedMethod: params.method,
      },
      getState().config,
      pack,
      shop.selectedAddOns,
      l
    );
    if (!updates.invoice.valid) throw new Error("NOT_FOUND");

    // Initialise data for order summary
    dispatch(
      initSignUpSuccess({
        ...updates,
        bundleMessage: null, // disable bundling-related events
        calculator,
        urlQueryMapper,
        tier,
        selectedSpeed,
        selectedPack: pack,
        selectedDevice: device,
        selectedMethod: params.method,
        packs: getPackPrices(calculator, tier, selectedSpeed),
        devices: getDevicePrices(calculator, pack, params.addOns, selectedSpeed),
        methods: getMethodPrices(calculator, pack, params.addOns, device),
      })
    );
  } catch (e) {
    const errorCode = e?.message ?? "GENERIC_ERROR";
    dispatch(initShopError(errorCode));
  }
};

export const initCheckCoverage = (): AppThunk => (dispatch, getState) => {
  try {
    const {
      config,
      language: { packSelection: l },
      shop,
      router: {
        location: { search },
      },
    } = getState();
    const { tiers, price, addOns: addOnConfig, devices: deviceConfig, installationMethods: methods } = config;

    // Get query parameters for order summary
    const urlQueryMapper = new ShopUrlQueryMapper(tiers, addOnConfig, deviceConfig, methods);
    const params = urlQueryMapper.getSelection(search);
    // Get tier
    const tier = getTier(tiers, params.tier);
    // Get speed
    const selectedSpeed = getSpeed(tier.speeds, params.speed);
    if (!selectedSpeed) throw new Error("NOT_FOUND");
    // Get pack
    const pack = tier.packs.find((p) => p.id === params.pack);
    if (!pack) throw new Error("NOT_FOUND");
    // Get device
    const device = pack.devices.find((device) => device.id === params.devices[0])?.id;
    if (!device) throw new Error("NOT_FOUND");
    // Get add-ons
    const packAddOnIds = normalizeAddOnIds([...pack.basicAddons, ...pack.popularAddons]);
    const validAddOns =
      dedupe(params.addOns).length === params.addOns.length &&
      params.addOns.every((addOnId) => packAddOnIds.indexOf(addOnId) >= 0);
    if (!validAddOns) throw new Error("NOT_FOUND");
    // Get shop pricing permutation
    const calculator = new ShopCalculator(price);
    const updates = selectionUpdated(
      {
        ...shop,
        calculator,
        urlQueryMapper,
        tier,
        selectedSpeed,
        selectedAddOns: params.addOns,
        selectedDevice: device,
      },
      config,
      pack,
      shop.selectedAddOns,
      l
    );
    if (!updates.invoice.valid) throw new Error("NOT_FOUND");

    // Initialise data for order summary
    dispatch(
      initCheckCoverageSuccess({
        ...updates,
        bundleMessage: null, // disable bundling-related events
        calculator,
        urlQueryMapper,
        tier,
        selectedSpeed,
        selectedPack: pack,
        selectedDevice: device,
        selectedMethod: params.method,
        packs: getPackPrices(calculator, tier, selectedSpeed),
        devices: getDevicePrices(calculator, pack, params.addOns, selectedSpeed),
        methods: getMethodPrices(calculator, pack, params.addOns, device),
      })
    );
  } catch (e) {
    const errorCode = e?.message ?? "GENERIC_ERROR";
    dispatch(initShopError(errorCode));
  }
};

export const initOrderSummary = (): AppThunk => (dispatch, getState) => {
  const {
    config: { tiers, addOns: addOnConfig, devices: deviceConfig, installationMethods: methods },
    router: {
      location: { search },
    },
  } = getState();

  // Get query parameters for order summary
  const urlQueryMapper = new ShopUrlQueryMapper(tiers, addOnConfig, deviceConfig, methods);
  const params = urlQueryMapper.getSelection(search);
  // Get tier
  const tier = getTier(tiers, params.tier);
  // Get speed
  const selectedSpeed = getSpeed(tier.speeds, params.speed);
  if (selectedSpeed) dispatch(push({ pathname: checkCoveragePath, search }));
  else dispatch(push({ pathname: signUpFormPath, search }));
};

export const updateIsSubscriber =
  (isSubscriber: boolean): AppThunk =>
  (dispatch, getState) => {
    const {
      shop,
      config,
      language: { packSelection: l },
    } = getState();
    const {
      selectedPack,
      isSubscriber: prevIsSubscriber,
      calculator,
      selectedDevice: prevSelectedDevice,
      selectedMethod: prevSelectedMethod,
      selectedAddOns,
      selectedSpeed,
    } = shop;

    if (selectedPack) {
      if (isSubscriber !== prevIsSubscriber) {
        const removeDevice = checkRemoveDevice(isSubscriber, selectedPack.id, config.price.boxExclusions);
        let devices = {};
        let selectedDevice = "";
        let methods = {};
        let selectedMethod = "";
        if (!removeDevice) {
          devices = getDevicePrices(calculator, selectedPack, selectedAddOns, selectedSpeed);
          selectedDevice = prevSelectedDevice || selectedPack.preSelectedDevice;
          methods = getMethodPrices(calculator, selectedPack, selectedAddOns, selectedDevice);
          selectedMethod =
            prevSelectedMethod ||
            selectedPack.devices.find((device) => device.id === selectedDevice)?.preSelectedMethod ||
            "";
        }

        const updates = selectionUpdated(
          {
            ...shop,
            devices,
            selectedDevice,
            methods,
            selectedMethod,
          },
          config,
          selectedPack,
          selectedAddOns,
          l
        );
        dispatch(updatePackSelectionUrl(updates.urlQueryParameters));
        dispatch(
          updateSelectedSubscriber({
            ...updates,
            isSubscriber,
            removeDevice,
            devices,
            selectedDevice,
            methods,
            selectedMethod,
          })
        );
      }
    } else {
      dispatch(updateIsSubscriberWithoutPack(isSubscriber));
    }
  };

export const selectPack =
  (packId: string): AppThunk =>
  (dispatch, getState) => {
    const {
      shop,
      config,
      language: { packSelection: l },
    } = getState();
    const { tier, selectedSpeed, selectedPack, calculator, selectedAddOns, isSubscriber } = shop;
    const pack = tier.packs.find((p) => p.id === packId);
    // Need revisit this if there's changes in box selection validity
    const removeDevice = checkRemoveDevice(isSubscriber, packId, config.price.boxExclusions);
    if (pack)
      if (packId !== selectedPack?.id) {
        // Pack changed
        const _selectedAddOns: string[] = [];
        let selectedDevice = pack.preSelectedDevice;
        let selectedMethod = pack.devices.find((device) => device.id === selectedDevice)?.preSelectedMethod || "";

        if (removeDevice) {
          selectedDevice = "";
          selectedMethod = "";
        }
        const updates = selectionUpdated(
          {
            ...shop,
            selectedAddOns: _selectedAddOns,
            selectedDevice,
            selectedMethod,
          },
          config,
          pack,
          selectedAddOns,
          l
        );
        dispatch(updatePackSelectionUrl(updates.urlQueryParameters));
        dispatch(
          updateSelectedPack({
            ...updates,
            selectedPack: pack,
            selectedDevice,
            selectedMethod,
            devices: removeDevice ? {} : getDevicePrices(calculator, pack, _selectedAddOns, selectedSpeed),
            methods: removeDevice ? {} : getMethodPrices(calculator, pack, _selectedAddOns, selectedDevice),
            removeDevice,
          })
        );
      }
  };

export const selectDevice =
  (device: string): AppThunk =>
  (dispatch, getState) => {
    const {
      shop,
      config,
      language: { packSelection: l },
    } = getState();
    const { selectedDevice, selectedPack, selectedAddOns, calculator } = shop;

    if (selectedPack)
      if (device !== selectedDevice) {
        // Box changed
        const selectedMethod = selectedPack.devices.find((d) => d.id === device)?.preSelectedMethod || "";
        const updates = selectionUpdated(
          {
            ...shop,
            selectedDevice: device,
            selectedMethod,
          },
          config,
          selectedPack,
          selectedAddOns,
          l
        );
        const methods = getMethodPrices(calculator, selectedPack, selectedAddOns, device);
        dispatch(updatePackSelectionUrl(updates.urlQueryParameters));
        dispatch(
          updateSelectedDevice({
            ...updates,
            selectedDevice: device,
            selectedMethod,
            methods,
          })
        );
      }
  };

export const selectMethod =
  (method: string): AppThunk =>
  (dispatch, getState) => {
    const {
      shop,
      config,
      language: { packSelection: l },
    } = getState();
    const { selectedDevice, selectedPack, selectedAddOns, selectedMethod } = shop;

    if (selectedPack && selectedDevice) {
      if (selectedMethod !== method) {
        // Method Changed
        const updates = selectionUpdated(
          {
            ...shop,
            selectedMethod: method,
          },
          config,
          selectedPack,
          selectedAddOns,
          l
        );
        dispatch(updatePackSelectionUrl(updates.urlQueryParameters));
        dispatch(
          updateSelectedMethod({
            ...updates,
            selectedMethod: method,
          })
        );
      }
    }
  };

export const selectAddOn =
  (addOn: string): AppThunk =>
  (dispatch, getState) => {
    const {
      shop,
      config,
      language: { packSelection: l },
    } = getState();
    const {
      selectedAddOns,
      selectedPack,
      calculator,
      selectedSpeed,
      selectedDevice: prevSelectedDevice,
      devices,
      selectedMethod: prevSelectedMethod,
      removeDevice,
    } = shop;

    if (selectedPack)
      if (calculator.isValidItem(addOn)) {
        if (selectedAddOns.indexOf(addOn) < 0) {
          // New add-on selected
          const _selectedAddOns = [...selectedAddOns, addOn];
          const currentDevices = Object.entries(devices);
          const newDevices = removeDevice
            ? {}
            : getDevicePrices(calculator, selectedPack, _selectedAddOns, selectedSpeed);
          let selectedDevice = prevSelectedDevice;
          let selectedMethod = prevSelectedMethod;
          if (Object.entries(newDevices).length === 1 && currentDevices.length !== 1) {
            selectedDevice = Object.entries(newDevices)[0][0];
            selectedMethod =
              selectedPack.devices.find((device) => device.id === selectedDevice)?.preSelectedMethod || "";
          }
          if (removeDevice) {
            selectedDevice = "";
            selectedMethod = "";
          }
          const methods = removeDevice
            ? {}
            : getMethodPrices(calculator, selectedPack, _selectedAddOns, selectedDevice);
          const updates = selectionUpdated(
            {
              ...shop,
              selectedAddOns: _selectedAddOns,
              selectedDevice,
              selectedMethod,
            },
            config,
            selectedPack,
            selectedAddOns,
            l
          );
          dispatch(updatePackSelectionUrl(updates.urlQueryParameters));
          dispatch(
            updateSelectedAddOns({
              ...updates,
              devices: newDevices,
              methods,
              selectedDevice,
              selectedMethod,
            })
          );
        }
      }
  };

export const removeAddOn =
  (addOn: string): AppThunk =>
  (dispatch, getState) => {
    const {
      shop,
      config,
      language: { packSelection: l },
    } = getState();
    const { selectedAddOns, selectedPack, calculator, selectedSpeed, selectedDevice, selectedMethod, removeDevice } =
      shop;

    if (selectedPack) {
      if (selectedAddOns.indexOf(addOn) >= 0) {
        // Add-on is deselected
        const _selectedAddOns = selectedAddOns.filter((id) => id !== addOn);
        const updates = selectionUpdated(
          {
            ...shop,
            selectedAddOns: _selectedAddOns,
          },
          config,
          selectedPack,
          selectedAddOns,
          l
        );
        dispatch(updatePackSelectionUrl(updates.urlQueryParameters));
        const devices = removeDevice ? {} : getDevicePrices(calculator, selectedPack, _selectedAddOns, selectedSpeed);
        const methods = removeDevice ? {} : getMethodPrices(calculator, selectedPack, _selectedAddOns, selectedDevice);
        dispatch(updateSelectedAddOns({ ...updates, devices, methods, selectedDevice, selectedMethod }));
      }
    }
  };

export const shopProceed = (): AppThunk => (dispatch, getState) => {
  const appState = getState();
  const {
    shop: { invoice, tier, isSubscriber, selectedPack, selectedMethod, bbUpsellChoice },
    config: {
      packSelection: { subscriberProceedUrlBb },
    },
    config: {
      packSelection: { triggerMethodForBbUpsell },
    },
  } = appState;
  const portal = portalSelector(appState);
  const { bbUpsellPopup } = tierConfigSelector(appState);

  const errors = getErrors(selectedPack, invoice);
  if (errors.pack || errors.basicAddOns || errors.devices || errors.method) dispatch(showErrorModal(errors));
  else if (!invoice.valid) console.log("error");
  else if (selectedPack) {
    const isBbUpsellEnabled = !!bbUpsellPopup;
    const isBbUpsellChoiceNotSet = bbUpsellChoice === BbUpsellChoice.NOT_SET;
    const isBbUpsellTriggerFiringForMethod = selectedMethod === triggerMethodForBbUpsell;
    if (isBbUpsellChoiceNotSet) dispatch(trackProceed());
    if (isBbUpsellEnabled && isBbUpsellChoiceNotSet && isBbUpsellTriggerFiringForMethod) {
      return dispatch(showBbUpsellModal());
    }
    if (isSubscriber) {
      if (portal === PortalType.BB) window.location.href = getUrlWithLeadFormQuery(subscriberProceedUrlBb, appState);
    } else if (tier.showUpsellPopup) dispatch(showUpsellPopup());
    else if (portal === PortalType.BB) {
      dispatch(pushWithParams(checkCoveragePath));
    } else dispatch(pushWithParams(signUpFormPath));
  }
};

export const upsellDecision =
  (isUpsold: boolean): AppThunk =>
  (dispatch, getState) => {
    const appState = getState();
    const {
      shop: { selectedPack },
      config: {
        packSelection: { upsellUrl },
      },
    } = appState;
    const portal = portalSelector(appState);

    if (isUpsold && selectedPack) window.location.href = getUrlWithLeadFormQuery(upsellUrl, appState);
    else if (portal === PortalType.BB) {
      dispatch(pushWithParams(checkCoveragePath));
    } else {
      dispatch(pushWithParams(signUpFormPath));
    }
  };

export const updatePackSelectionUrl =
  (queryParameters: ShopUrlQueryParameters): AppThunk =>
  (dispatch, getState) => {
    const pathname = getState().router.location.pathname;
    /**
     * tier should only be omitted on pack selection page, since it is already part of the slug,
     * while on sign-up page it should not be omitted.
     */
    const omitTierParam = pathname !== `/${signUpFormPath}`;
    const queryString = getShopUrlQueryString(queryParameters, getState().router.location.search, omitTierParam);
    dispatch(replace({ pathname, search: queryString }));
  };

export const initHelpMeStep = (): AppThunk => (dispatch, getState) => {
  const {
    router: {
      location: { pathname, search },
    },
  } = getState();
  const urlParams = new URLSearchParams(search);

  if (urlParams.get("ulm") === "true") {
    dispatch(updateSelectedHelpMe(HelpMe.Subscribe));
    urlParams.delete("ulm");
    dispatch(replace({ pathname, search: urlParams.toString() }));
  }
};

export const redirectToLogin = (): AppThunk => (dispatch, getState) => {
  const {
    ulm: { loginUrl },
  } = getState();

  const location = window.location;
  const query = new URLSearchParams(location.search);
  query.append("ulm", "true");

  const redirectUrl = encodeURIComponent(`${location.origin}${location.pathname}?${query.toString()}`);
  location.href = interpolate(loginUrl, { location: redirectUrl });
};

/* Analytics actions */
export const trackViewPack = createAction("shop/viewPack");
export const trackMethodLearnMore = createAction<string>("shop/learnMore");
export const trackShowMorePopularAddOns = createAction("shop/showMorePopularAddOns");
export const upsellBroadbandCancelCta = createAction("shop/upsellBroadbandCancel");
export const upsellBroadbandProceedTvCta = createAction("shop/upsellBroadbandProceedTv");
export const upsellBroadbandProceedBbCta = createAction("shop/upsellBroadbandProceedBb");
export const trackProceed = createAction("shop/proceed");
export const trackPromoRibbonLink = createAction<PromoLinkEventParams>("shop/trackPromoRibbonLink");
export const trackPromoBannerLink = createAction<PromoLinkEventParams>("shop/trackPromoBannerLink");
export const trackExistingCustomer = createAction("shop/trackExistingCustomer");
export const trackBenefitHighlight = createAction<string>("shop/trackBenefitHighlight");
export const trackCompareDevices = createAction<string>("shop/trackCompareDevices");
export const trackLogin = createAction<LoginType>("shop/loginNow");

/* Utility functions */
function getTier(tiers: ITierConfig[], tierPath: string): ITierConfig {
  const tier = tiers.find((t) => t.pageUrl === tierPath);
  if (!tier) throw new Error("NOT_FOUND");
  if (tier.packs.length === 0) throw new Error("GENERIC_ERROR");
  return tier;
}

function getSpeed(speeds: ISpeed[] = [], speedId?: string): ISpeed | null {
  let speed: ISpeed | undefined;
  if (speeds.length) {
    speed = speeds.find((s) => s.id === speedId);
    if (!speed) throw new Error("NOT_FOUND");
  } else if (speedId) throw new Error("NOT_FOUND");
  return speed || null;
}

export function getTierPrice(
  calculator: ShopCalculator,
  packs: IPack[],
  selectedSpeed: ISpeed | null,
  selectDevice?: string
): TierPrice {
  const packIds = packs.reduce((idList, pack) => {
    idList.push(pack.id);
    return idList;
  }, [] as string[]);
  const tierPrice = calculator.getTierPrice(packIds, selectedSpeed?.id, selectDevice);
  return tierPrice;
}

function getPackPrices(calculator: ShopCalculator, tier: ITierConfig, selectedSpeed: ISpeed | null): IItems {
  const tierPrice = getTierPrice(calculator, tier.packs, selectedSpeed);

  return tier.packs.reduce((p, pack) => {
    const packPrice = calculator.getPackPrice(pack.id, selectedSpeed?.id);
    if (packPrice !== null)
      p[pack.id] = {
        displayPrice: packPrice.displayPrice - tierPrice.afterDiscount,
        itemPrice: packPrice.itemPrice,
      };
    return p;
  }, {} as IItems);
}

function getDevicePrices(
  calculator: ShopCalculator,
  selectedPack: IPack,
  selectedAddOns: string[],
  selectedSpeed: ISpeed | null
): IItems {
  const addOns = [...selectedAddOns];
  if (selectedSpeed) addOns.push(selectedSpeed.id);
  const devicesList = getDevicesList(selectedPack, selectedSpeed);
  return devicesList.reduce((d, id) => {
    const maxArpu = selectedPack.devices.find((device) => device.id === id)?.maxArpu;
    const deviceInfo = calculator.getDeviceInfo(selectedPack.id, addOns, id, maxArpu);
    if (deviceInfo !== null) {
      d[id] = {
        displayPrice: deviceInfo.displayPrice,
        displayFee: deviceInfo.displayFee,
        itemPrice: deviceInfo.monthlyPrice,
        itemFee: deviceInfo.itemFee,
      };
    }
    return d;
  }, {} as IItems);
}

function getMethodPrices(
  calculator: ShopCalculator,
  selectedPack: IPack,
  selectedAddOns: string[],
  selectedDevice: string
): IItems {
  const addOns = [...selectedAddOns];
  if (selectedDevice) addOns.push(selectedDevice);
  const methodsList = selectedPack.devices.find((device) => device.id === selectedDevice)?.methods;
  if (!methodsList) return {};
  return methodsList?.reduce((m, id) => {
    const methodInfo = calculator.getDeviceInfo(selectedPack.id, addOns, id);
    if (methodInfo !== null) {
      m[id] = {
        displayPrice: methodInfo.displayPrice,
        displayFee: methodInfo.displayFee,
        itemPrice: methodInfo.monthlyPrice,
        itemFee: methodInfo.itemFee,
      };
    }
    return m;
  }, {} as IItems);
}

function getErrors(selectedPack: IPack | null, invoice: IInvoice): SelectionUpdateError {
  return {
    pack: selectedPack === null,
    basicAddOns: invoice.miniRequired !== invoice.mini,
    devices: invoice.boxRequired > invoice.box,
    method: invoice.methodRequired !== invoice.method,
  };
}

function clearResolvedErrors(
  shopErrors: ShopError,
  selectedPack: IPack | null,
  invoice: IInvoice
): SelectionUpdateError {
  const { pack, basicAddOns, devices, method } = shopErrors;
  const errors: SelectionUpdateError = { pack, basicAddOns, devices, method };
  const selectionErrors = getErrors(selectedPack, invoice);
  /* Clear error if resolved */
  errors.pack && !selectionErrors.pack && (errors.pack = false);
  errors.basicAddOns && !selectionErrors.basicAddOns && (errors.basicAddOns = false);
  errors.devices && !selectionErrors.devices && (errors.devices = false);
  errors.method && !selectionErrors.method && (errors.method = false);
  return errors;
}

export function getInvoice(
  selectedPack: IPack,
  shopState: ShopState,
  priceConfig: IPriceConfig,
  referral?: ReferralCodeItem | null
) {
  const { calculator, selectedAddOns, selectedDevice, selectedSpeed, selectedMethod } = shopState;
  const addons = [...selectedAddOns, selectedDevice, selectedMethod];
  selectedSpeed && addons.push(selectedSpeed.id);

  const calcOptions: CalculationOptions = {};
  if (referral) {
    const { id, price, title, description, referralType } = referral;
    calcOptions.referral = {
      id,
      discountedPrice: price,
      title,
      description,
      referralType: priceConfig.referralType[referralType],
    };
  }
  return calculator.calculate(selectedPack.id, addons, calcOptions);
}

/**
 * Typeguard for add-on with options
 */
export function isAddOnWithOptions(addOn: AddOn): addOn is AddOnWithOptions {
  return typeof addOn !== "string";
}

/**
 * To extract add-on ID from AddOn type object
 * @param addOn Add-on
 * @returns Add-on ID
 */
export function getAddOnId(addOn: AddOn): string {
  return isAddOnWithOptions(addOn) ? addOn.id : addOn;
}

/**
 * To flatten the add-ons array into list of IDs
 * @param addOns Add-ons array
 * @returns List of add-on IDs
 */
export function normalizeAddOnIds(addOns: AddOn[]): string[] {
  return addOns.map(getAddOnId);
}

/**
 * Processes changes to display and pricing based on user's selection
 * @param shopState Shop state
 * @param selectedPack Selected pack (latest)
 * @param prevSelectedAddOns Previous list of selected add-ons for comparison
 */
function selectionUpdated(
  shopState: ShopState,
  configState: ConfigState,
  selectedPack: IPack,
  prevSelectedAddOns: string[],
  l: PackSelectionLanguageConfig
): ISelectionUpdate {
  const {
    calculator, // fixed
    urlQueryMapper, // fixed
    selectedDevice, // new
    selectedAddOns, // new
    selectedMethod, // new
    comboAddOns, // old
    bundledAddOns, // old
    requiredAddOns, // old
    listedAddOns, // old
    error, // old
    selectedSpeed, // new
    tier, // fixed
  } = shopState;
  const { addOns: addOnConfig, price: priceConfig } = configState;

  const invoice = getInvoice(selectedPack, shopState, priceConfig); // Generate new invoice
  const selectedPackBundle = calculator.getPackBundle(selectedPack.id);
  const updatedComboAddOns: string[] = []; // list of new combos pushed into invoice
  const updatedBundledAddOns: string[] = []; // list of bundled items in invoice
  const updatedRequiredAddOns: string[] = []; // list of required items in invoice
  for (const [id, { isBundled, isRequired }] of Object.entries(invoice.items)) {
    selectedPackBundle.indexOf(id) < 0 &&
      selectedAddOns.indexOf(id) < 0 &&
      selectedDevice !== id &&
      selectedSpeed?.id !== id &&
      selectedMethod !== id &&
      updatedComboAddOns.push(id); // is a combo if is not bundled with pack and not selected by user
    isBundled && updatedBundledAddOns.push(id);
    isRequired && updatedRequiredAddOns.push(id);
  }

  /* Add-on info for GA */
  const addOnSelection: AddOnSelection = {
    addManual: [],
    removeManual: [],
    addCombo: "",
    removeBundled: [],
    addRequired: [],
    removeRequired: [],
  };

  let updatedSelectedAddOns: string[] = [...selectedAddOns]; // update user selection
  let bundleAlert: BundleAlert | null = null; // bundle alert
  // Check for combo
  const newCombo = updatedComboAddOns.filter(
    (id) => comboAddOns.indexOf(id) < 0 && updatedRequiredAddOns.indexOf(id) < 0
  )[0];
  if (newCombo) {
    updatedSelectedAddOns.push(newCombo); // Add combo to user selection
    addOnSelection.addCombo = newCombo;
    bundleAlert = {
      parent: newCombo,
      parentPrice: invoice.items[newCombo].price,
      children: [],
    };
  }
  // Check for new bundled items in invoice (from new changes to selection)
  const newBundledItems = updatedBundledAddOns.filter((id) => bundledAddOns.indexOf(id) < 0);
  if (newBundledItems.length > 0) {
    updatedSelectedAddOns = updatedSelectedAddOns.filter((id) => newBundledItems.indexOf(id) < 0); // Remove bundled add-ons from user selection
    if (bundleAlert === null) {
      // Check for newly selected add-on (if any)
      const newSelected = selectedAddOns.find((id) => prevSelectedAddOns.indexOf(id) < 0);
      if (newSelected)
        bundleAlert = {
          parent: newSelected,
          parentPrice: invoice.items[newSelected].price,
          children: [],
        };
    }
    if (bundleAlert !== null) bundleAlert.children = newBundledItems;
  }

  // Add-ons added by user
  addOnSelection.addManual = updatedSelectedAddOns.filter(
    (id) => prevSelectedAddOns.indexOf(id) < 0 && id !== newCombo
  );
  // Bundled items removed from user selection
  addOnSelection.removeBundled = newBundledItems.filter((id) => prevSelectedAddOns.indexOf(id) >= 0);
  // Add-ons removed by user
  addOnSelection.removeManual = prevSelectedAddOns.filter(
    (id) => updatedSelectedAddOns.indexOf(id) < 0 && addOnSelection.removeBundled.indexOf(id) < 0
  );
  // New required items added to user selection
  addOnSelection.addRequired = updatedRequiredAddOns.filter(
    (id) => requiredAddOns.indexOf(id) < 0 && updatedSelectedAddOns.indexOf(id) < 0
  );
  // Removed required items removed from user selection
  addOnSelection.removeRequired = requiredAddOns.filter(
    (id) => updatedRequiredAddOns.indexOf(id) < 0 && prevSelectedAddOns.indexOf(id) < 0
  );

  /* Get list of add-ons with display price and status info */
  const prevList = listedAddOns; // Previous add-on display info
  const newList: IItems = {}; // New add-on display info
  const normalizedAddOnIds = normalizeAddOnIds([...selectedPack.basicAddons, ...selectedPack.popularAddons]);
  for (const id of normalizedAddOnIds) // Complete list of add-ons to be listed
    if (newList[id] === undefined)
      newList[id] = {
        ...getAddOnInfo({
          addOnId: id,
          packId: selectedPack.id,
          selectedAddOns: updatedSelectedAddOns,
          selectedBox: selectedDevice,
          selectedMethod,
          calculator,
          bundledAddOns: updatedBundledAddOns,
          requiredAddOns: updatedRequiredAddOns,
          selectedSpeed: selectedSpeed?.id,
        }),
      };
  // Check how selection has changed and update accordingly
  let isDifferent = false; // selected items(s) removed or sequence changed
  let onlyAdded = false; // only new items appended
  const sameSelection: string[] = []; // part of selection that remains exactly the same until a change
  const newSelection: string[] = []; // new items appended
  for (let i = 0; i < updatedSelectedAddOns.length; i++) {
    const now = updatedSelectedAddOns[i];
    const before = prevSelectedAddOns[i];
    if (before === undefined)
      if (shallowEqual(sameSelection, prevSelectedAddOns))
        // new selection added
        onlyAdded = true;
      else isDifferent = true;
    else if (before !== now) isDifferent = true;
    if (onlyAdded) {
      const newItem = newList[now];
      if (newItem) {
        const selectedAddOnIds = [...sameSelection, ...newSelection, selectedDevice, selectedMethod];
        selectedSpeed && selectedAddOnIds.push(selectedSpeed.id);
        const { priceDiff: price } = calculator.getItemInfo(selectedPack.id, selectedAddOnIds, now);
        newList[now] = {
          ...newItem,
          displayPrice: price,
        };
      }
      newSelection.push(now);
    } else if (isDifferent) {
      const newItem = newList[now];
      if (newItem) {
        const selectedAddOnIds = [...sameSelection, ...newSelection, selectedDevice, selectedMethod];
        selectedSpeed && selectedAddOnIds.push(selectedSpeed.id);
        const { priceDiff: price } = calculator.getItemInfo(selectedPack.id, selectedAddOnIds, now);
        newList[now] = {
          ...newItem,
          displayPrice: price,
        };
      }
      newSelection.push(now);
    } else {
      // No change, remains selected
      sameSelection.push(now);
      lockPrice(now, prevList, newList);
    }
  }

  const updates: ISelectionUpdate = {
    invoice,
    selectedPackBundle,
    selectedAddOns: updatedSelectedAddOns,
    comboAddOns: updatedComboAddOns,
    bundledAddOns: updatedBundledAddOns,
    requiredAddOns: updatedRequiredAddOns,
    listedAddOns: newList,
    urlQueryParameters: urlQueryMapper.getQueryParameters({
      tier: tier.pageUrl,
      speed: selectedSpeed?.id,
      pack: selectedPack.id,
      addOns: selectedAddOns,
      devices: [selectedDevice],
      method: selectedMethod,
    }),
    bundleMessage: null,
    addOnSelection,
    error: clearResolvedErrors(error, selectedPack, invoice),
  };

  /* Add-on bundling popup message */
  if (bundleAlert !== null) {
    // get bundle parent item display price
    const { parent } = bundleAlert;
    const parentPrice = newList[bundleAlert.parent].displayPrice;
    // get all items that just became bundled with parent
    const children: string[] = [];
    Object.entries(newList).forEach(([id, info]) => {
      if (info.bundled) {
        const prevInfo = prevList[id];
        prevInfo && !prevInfo.bundled && children.push(id);
      }
    });
    // get bundle popup message
    const addOnNames = children.reduce((list, id) => {
      const addOn = addOnConfig[id];
      if (addOn) list.push(addOn.name);
      return list;
    }, [] as string[]);
    const addons = addOnNames.reduce((formatted, name, index, list) => {
      if (!formatted.trim()) return name;
      if (index === list.length - 1) return formatted + " and " + name;
      return formatted + ", " + name;
    }, "" as string);
    const parentAddOnConfig = addOnConfig[parent];
    if (!parentAddOnConfig.hideBundledModal) {
      updates.bundleMessage = interpolate(l.MODAL_BUNDLE_MESSAGE_HTML, {
        addons,
        bundle: parentAddOnConfig?.name || "",
        amount: Math.abs(parentPrice).toFixed(2),
      });
    }
  }

  return updates;
}

function lockPrice(id: string, prevList: IItems, currentList: IItems): void {
  const currentItem = currentList[id];
  const prevItem = prevList[id];
  if (currentItem && prevItem)
    currentList[id] = {
      ...currentItem,
      displayPrice: prevItem.displayPrice,
      bundled: prevItem.bundled,
    };
}

function getAddOnInfo(params: {
  addOnId: string;
  packId: string;
  selectedAddOns: string[];
  selectedBox: string;
  selectedMethod: string;
  calculator: ShopCalculator;
  bundledAddOns: string[];
  requiredAddOns: string[];
  selectedSpeed?: string;
}): IItemInfo {
  const {
    addOnId,
    packId,
    selectedAddOns,
    selectedBox,
    selectedMethod,
    calculator,
    bundledAddOns,
    requiredAddOns,
    selectedSpeed,
  } = params;
  const selectedAddOnIds = [...selectedAddOns, selectedBox, selectedMethod];
  selectedSpeed && selectedAddOnIds.push(selectedSpeed);
  const { priceDiff, itemPrice, isBundled, combos } = calculator.getItemInfo(packId, selectedAddOnIds, addOnId);
  const bundled = bundledAddOns.indexOf(addOnId) >= 0 || (!combos.length && isBundled);
  const required = requiredAddOns.indexOf(addOnId) >= 0;
  const selected = selectedAddOns.indexOf(addOnId) >= 0;
  return {
    displayPrice: priceDiff,
    itemPrice,
    bundled: bundled || required,
    selected: bundled || required || selected,
    disabled: bundled || required,
  };
}

export function isInitAction(action: unknown): action is PayloadAction<InitShopState, string, InitShopMeta> {
  const meta = (action as PayloadAction<InitShopState, string, InitShopMeta>).meta;
  return meta !== undefined && meta.init;
}

export function getShopUrlQueryString(
  shopQueryParameters: ShopUrlQueryParameters,
  currentQueryString: string,
  omitTierParam = false
): string {
  const { tier, speed, pack, addons, devices, method } = shopQueryParameters;
  const urlParams = new URLSearchParams();
  if (!omitTierParam) urlParams.set(urlParameterKeys.tier, tier);
  if (speed) urlParams.set(urlParameterKeys.speed, speed);
  if (pack) urlParams.set(urlParameterKeys.pack, pack);
  if (devices) urlParams.set(urlParameterKeys.devices, devices);
  if (addons) urlParams.set(urlParameterKeys.addons, addons);
  if (method) urlParams.set(urlParameterKeys.method, method);

  const searchParams = new URLSearchParams(currentQueryString);
  searchParams.forEach((value, key) => {
    !isShopQueryParameter(key) && urlParams.set(key, value);
  });

  return urlParams.toString();
}

function getUrlWithLeadFormQuery(url: string, appState: AppState): string {
  const {
    shop: { invoice, selectedPack, selectedSpeed },
    config: { addOns, devices, installationMethods: methods },
    router: {
      location: { search },
    },
  } = appState;

  return interpolate(url, {
    leadFormQuery: selectedPack ? leadFormQuery(invoice, selectedPack, selectedSpeed, addOns, devices, methods) : "",
    additionalQuery: selectedPack ? getAdditionalQuery(search) : "",
  });
}

function leadFormQuery(
  invoice: IInvoice,
  selectedPack: IPack,
  speed: ISpeed | null,
  addOns: IAddOnConfig,
  devices: IDeviceConfig,
  methods: InstallationMethodMap
): string {
  const { addOns: selectedAddOns, devices: selectedDevices } = getItemNames(invoice, addOns, devices, methods);
  let bundlePack = selectedPack.invoiceName;
  if (speed) bundlePack += ` with ${speed.title}`;
  const parameters = {
    bundlePack,
    selectedAddOns: selectedAddOns.join(","),
    selectedDevices: selectedDevices.join(","),
  };
  return generateQueryString(parameters);
}

export function getItemNames(
  invoice: IInvoice,
  addOnsConfig: IAddOnConfig,
  devicesConfig: IDeviceConfig,
  methodsConfig: InstallationMethodMap
): { addOns: string[]; devices: string[]; subType: string } {
  const addOns: string[] = [];
  const devices: string[] = [];
  let subType = "";
  Object.entries(invoice.items).forEach(([id, item]) => {
    if (id !== invoice.id && !item.isBundled) {
      switch (item.type) {
        case ITEMTYPE.mini:
        case ITEMTYPE.addOn:
          addOns.push(addOnsConfig[id].name);
          break;
        case ITEMTYPE.device:
          devices.push(devicesConfig[id].name);
          break;
        case ITEMTYPE.method:
          subType = methodsConfig[id].subType;
          break;
      }
    }
  });
  return {
    addOns,
    devices,
    subType,
  };
}

function generateQueryString(parameters: { [key: string]: string }): string {
  const query = new URLSearchParams();
  for (const [key, value] of Object.entries(parameters)) query.set(key, value);
  return query.toString();
}

function getAdditionalQuery(currentShopQuery: string): string {
  const searchParams = new URLSearchParams(currentShopQuery);
  const additionalParams = new URLSearchParams();
  searchParams.forEach((value, key) => {
    !isShopQueryParameter(key) && additionalParams.set(key, value);
  });

  return additionalParams.toString() ? `&${additionalParams.toString()}` : "";
}

function checkRemoveDevice(subscriber: boolean | null, selectedPack: string, boxExclusions: string[]): boolean {
  return !!subscriber && !!boxExclusions.find((addon) => addon === selectedPack);
}
