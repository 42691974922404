import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "@store";
import {
  AppsMobileNumberConfig,
  AstroAccount,
  AppsErrors,
  SelectedSmartcard,
  ActivationSelection,
  PurchaseSelection,
  DisneyAddOnsConfig,
  DisneyAddOn,
} from "@ducks/apps/user/types";
import { isAllowedToProceedWithActivation, isAllowedToProceedWithPurchase } from "@ducks/apps/user";
import { MobileNumberErrorMessageMap, ThankYouLanguageConfig, ThankYouMessage } from "@ducks/apps/language/types";
import { ContactFormValues } from "@ducks/apps/contact/types";
import { isContactFormComplete } from "@ducks/apps/contact";

export const showLoadingForUlmRedirectionSelector = createSelector<AppState, boolean, boolean, boolean>(
  (state) => state.ulm.isLoggedIn,
  (state) => state.ulm.isUlmHandlerTriggered,
  (isLoggedIn, isUlmHandlerTriggered) => !isLoggedIn && !isUlmHandlerTriggered
);

export const allowActivationProceedSelector = createSelector<
  AppState,
  AppsMobileNumberConfig,
  ActivationSelection,
  AppsErrors,
  string[],
  boolean
>(
  (state) => state.appsUser.mobileConfig,
  (state) => state.appsUser.activationSelection,
  (state) => state.appsUser.activationError,
  (state) => state.appsLanguage.activate.termsHtml,
  isAllowedToProceedWithActivation
);

export const allowPurchaseSelector = createSelector<
  AppState,
  AppsMobileNumberConfig,
  PurchaseSelection,
  AppsErrors,
  string[],
  boolean
>(
  (state) => state.appsUser.mobileConfig,
  (state) => state.appsUser.purchaseSelection,
  (state) => state.appsUser.purchaseError,
  (state) => state.appsLanguage.activate.termsHtml,
  isAllowedToProceedWithPurchase
);

export const isEligibleSelector = createSelector<AppState, number, number, boolean>(
  (state) => state.appsUser.availableSmartcards.activation,
  (state) => state.appsUser.availableSmartcards.purchase,
  (activation, purchase) => !!(activation || purchase)
);

export const isEligibleForActivationSelector = createSelector<AppState, number, boolean>(
  (state) => state.appsUser.availableSmartcards.activation,
  (activation) => !!activation
);

export const isEligibleForPurchaseSelector = createSelector<AppState, number, boolean>(
  (state) => state.appsUser.availableSmartcards.purchase,
  (purchase) => !!purchase
);

export const showActivationMobileNumberErrorSelector = createSelector<
  AppState,
  AppsErrors,
  MobileNumberErrorMessageMap,
  string
>(
  (state) => state.appsUser.activationError,
  (state) => state.appsLanguage.mobileNumber.errorMessage,
  getMobileNumberErrorMessage
);

export const showPurchaseMobileNumberErrorSelector = createSelector<
  AppState,
  AppsErrors,
  MobileNumberErrorMessageMap,
  string
>(
  (state) => state.appsUser.purchaseError,
  (state) => state.appsLanguage.mobileNumber.errorMessage,
  getMobileNumberErrorMessage
);

function getMobileNumberErrorMessage(errors: AppsErrors, l: MobileNumberErrorMessageMap): string {
  let errorMessage = "";
  if (errors.mobileErrorCode) errorMessage = l[errors.mobileErrorCode];
  else if (errors.mobileSuffix.hasInvalidError) errorMessage = l.INVALID;
  else if (errors.mobilePrefix.hasRequiredError || errors.mobileSuffix.hasRequiredError) errorMessage = l.REQUIRED;
  return errorMessage;
}

export const showSelectPlanForActivation = createSelector<AppState, AstroAccount[], number, boolean>(
  (state) => state.appsUser.accounts.activation,
  (state) => state.appsUser.availableSmartcards.purchase,
  (activationAccounts, numberOfAvailableSmartcardsForPurchase) => {
    const numberOfSmartcardsForActivation = activationAccounts.reduce(
      (acc, account) => acc + Object.keys(account.smartcards).length,
      0
    );
    if (numberOfSmartcardsForActivation > 1) return true;
    else if (numberOfAvailableSmartcardsForPurchase >= 1) return true;
    return false;
  }
);

export const showSubscribeNowCtaSelector = createSelector<AppState, number, boolean>(
  (state) => state.appsUser.availableSmartcards.purchase,
  (numberOfAvailableSmartcardsForPurchase) => !!numberOfAvailableSmartcardsForPurchase
);

export const showSelectPlanForPurchase = createSelector<AppState, AstroAccount[], number, boolean>(
  (state) => state.appsUser.accounts.activation,
  (state) => state.appsUser.availableSmartcards.purchase,
  (activationAccounts, numberOfAvailableSmartcardsForPurchase) => {
    const numberOfSmartcardsForActivation = activationAccounts.reduce(
      (acc, account) => acc + Object.keys(account.smartcards).length,
      0
    );
    if (numberOfAvailableSmartcardsForPurchase > 1) return true;
    else if (numberOfSmartcardsForActivation >= 1) return true;
    return false;
  }
);

export const showActivationSelectPlanErrorSelector = createSelector<
  AppState,
  boolean,
  SelectedSmartcard | null,
  boolean
>(
  (state) => state.appsUser.activationError.showError,
  (state) => state.appsUser.activationSelection.smartcard,
  (showError, card) => showError && !card
);

export const showPurchaseSelectPlanErrorSelector = createSelector<AppState, boolean, SelectedSmartcard | null, boolean>(
  (state) => state.appsUser.purchaseError.showError,
  (state) => state.appsUser.purchaseSelection.smartcard,
  (showError, card) => showError && !card
);

export const selectedAddOnSelector = createSelector<AppState, DisneyAddOnsConfig, string, DisneyAddOn | null>(
  (state) => state.appsUser.disneyAddOnsConfig,
  (state) => state.appsUser.purchaseSelection.addOnId,
  getSelectedAddOnInfo
);

export function getSelectedAddOnInfo(config: DisneyAddOnsConfig, addOnId: string): DisneyAddOn | null {
  return config.addOns.find(({ id }) => id === addOnId) ?? null;
}

export const purchaseThankYouMessageSelector = createSelector<
  AppState,
  ThankYouLanguageConfig,
  string,
  ThankYouMessage
>(
  (state) => state.appsLanguage.thankYou,
  (state) => state.appsUser.purchaseSelection.addOnId,
  (config, addOnId) => config.purchase[addOnId] ?? { title: "", descriptionHtml: "" }
);

export const showWhatsAppHelplineForActivation = createSelector<AppState, AstroAccount[], boolean, boolean>(
  (state) => state.appsUser.accounts.purchase,
  (state) => state.appsUser.hasAccountsWithoutSmartcardData,
  (purchaseAccounts, emptySmc) => purchaseAccounts.length === 0 && emptySmc
);

export const contactFormCompleteSelector = createSelector<AppState, ContactFormValues, boolean>(
  (state) => state.appsContact.formValues,
  (formValues) => isContactFormComplete(formValues)
);
