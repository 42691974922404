import { ComponentsProps } from "@material-ui/core/styles/props";

const props: ComponentsProps = {
  MuiGrid: {
    spacing: 2,
  },
  MuiRadio: {
    color: "default",
  },
  MuiCheckbox: {
    color: "default",
  },
  MuiButtonBase: {
    disableRipple: true,
  },
  MuiButton: {
    variant: "outlined",
    disableElevation: true,
  },
  MuiCircularProgress: {
    size: 64,
    thickness: 5.2,
  },
};

export default props;
