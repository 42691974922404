import { ITierConfig, IDeviceConfig, IAddOnConfig, InstallationMethodMap } from "@ducks/config/types";

export const urlParameterKeys = {
  tier: "tier",
  pack: "pack",
  speed: "speed",
  addons: "addons",
  devices: "devices",
  method: "method",
};

export interface ShopUrlQueryParameters {
  tier: string;
  speed?: string;
  pack?: string;
  devices?: string;
  method?: string;
  addons?: string;
}

interface ShopSelection {
  /**
   * Tier page URL
   */
  tier: string;
  /**
   * Speed ID
   */
  speed?: string;
  /**
   * Pack ID
   */
  pack?: string;
  /**
   * List of device IDs
   */
  devices: string[];
  /**
   * List of add-on IDs
   */
  addOns: string[];
  /**
   * Installation Method
   */
  method: string;
}

interface ShopMap {
  [key: string]: string;
}

interface PacksByTierMap {
  [tierPageUrl: string]: {
    [key: string]: string;
  };
}

export default class ShopUrlQueryMapper {
  /**
   * Maps all IDs and query values for add-ons, devices, speeds
   */
  private addOnsMap: ShopMap;
  /**
   * Maps all packs (IDs and query values) by tiers (page URL)
   */
  private packsByTierMap: PacksByTierMap;

  constructor(
    tiers: ITierConfig[] = [],
    addOns: IAddOnConfig = {},
    devices: IDeviceConfig = {},
    methods: InstallationMethodMap = {}
  ) {
    this.addOnsMap = {};
    this.packsByTierMap = {};

    this.mapTierConfig(tiers);
    this.mapAddOnConfig(addOns);
    this.mapDeviceConfig(devices);
    this.mapMethodsConfig(methods);
  }

  /**
   * Retrieve selection from URL query string
   * @param queryString URL query string
   */
  public getSelection(queryString: string, tierPath?: string): ShopSelection {
    const params = new URLSearchParams(queryString);
    const tier = params.get(urlParameterKeys.tier) || tierPath || "";
    const speed = params.get(urlParameterKeys.speed);
    const pack = params.get(urlParameterKeys.pack) || "";
    const devices = params.get(urlParameterKeys.devices) || "";
    const addons = params.get(urlParameterKeys.addons);
    const method = params.get(urlParameterKeys.method) || "";
    const selection: ShopSelection = {
      tier: tier,
      pack: this.convertPack(tier, pack),
      devices: devices.split(",").map((device) => this.convert(device)),
      addOns: addons?.split(",").map((addon) => this.convert(addon)) || [],
      method: this.convert(method) || "",
    };
    if (speed) selection.speed = this.convert(speed);
    return selection;
  }

  /**
   * Create URL query string parameters from selection
   * @param selection User's shop selection
   */
  public getQueryParameters(selection: ShopSelection): ShopUrlQueryParameters {
    const { tier, speed, pack, devices, addOns, method } = selection;
    // Get values
    const packParam = pack ? this.convertPack(tier, pack) : "";
    const devicesParam = devices.map((id) => this.convert(id)).join(",");
    const speedParam = speed ? this.convert(speed) : "";
    const addonParam = addOns.length > 0 ? addOns.map((id) => this.convert(id)).join(",") : "";
    const methodParam = method ? this.convert(method) : "";
    return {
      tier,
      speed: speedParam,
      pack: packParam,
      devices: devicesParam,
      addons: addonParam,
      method: methodParam,
    };
  }

  private convertPack(tier: string, pack: string) {
    return this.packsByTierMap[tier]?.[pack];
  }

  private convert(value: string): string {
    return this.addOnsMap[value];
  }

  private mapDeviceConfig(devices: IDeviceConfig): void {
    for (const [id, { name, urlParameter }] of Object.entries(devices))
      this.mapAddOn(id, this.formatQueryValue(urlParameter || name));
  }

  private mapAddOnConfig(addOns: IAddOnConfig): void {
    for (const [id, { name, urlParameter }] of Object.entries(addOns))
      this.mapAddOn(id, this.formatQueryValue(urlParameter || name));
  }

  private mapMethodsConfig(methods: InstallationMethodMap): void {
    for (const [id, { name, urlParameter }] of Object.entries(methods))
      this.mapAddOn(id, this.formatQueryValue(urlParameter || name));
  }

  private mapTierConfig(tiers: ITierConfig[]): void {
    for (const tier of tiers) {
      const tierPageUrl = tier.pageUrl;

      for (const pack of tier.packs) {
        const packId = pack.id;
        const packParam = this.formatQueryValue(pack.urlParameter || pack.title);
        this.mapPackToTier(tierPageUrl, packId, packParam);
      }

      if (tier.speeds)
        for (const speed of tier.speeds) {
          const id = speed.id;
          const param = this.formatQueryValue(speed.urlParams);
          this.mapAddOn(id, param);
        }
    }
  }

  private mapAddOn(selectionId: string, queryParam: string): void {
    this.addOnsMap[selectionId] = queryParam;
    this.addOnsMap[queryParam] = selectionId;
  }

  private mapPackToTier(tier: string, packId: string, packQuery: string): void {
    if (this.packsByTierMap[tier] === undefined) this.packsByTierMap[tier] = {};
    this.packsByTierMap[tier][packId] = packQuery;
    this.packsByTierMap[tier][packQuery] = packId;
  }

  private formatQueryValue(value: string): string {
    // kebab-case
    return value.trim().toLowerCase().replace(/ /g, "-");
  }
}

export function isShopQueryParameter(key: string): boolean {
  return Object.keys(urlParameterKeys).indexOf(key) >= 0;
}
