import React from "react";
import ReactDOM from "react-dom";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { Provider } from "react-redux";
import "intersection-observer";

import App from "@app";
import store from "@store";
import settings from "@settings";
import { vitalsMetricHandler } from "@lib/gtm";
import reportWebVitals from "./reportWebVitals";
import "./index.scss";

Bugsnag.start({
  apiKey: settings.bugsnagApiKey,
  plugins: [new BugsnagPluginReact(React)],
});
const ErrorBoundary = Bugsnag.getPlugin("react")?.createErrorBoundary(React);

const app = (
  <Provider store={store}>
    <App />
  </Provider>
);
const rootElement = ErrorBoundary ? <ErrorBoundary>{app}</ErrorBoundary> : app;
ReactDOM.render(rootElement, document.getElementById("YWNtLXNob3A"));

reportWebVitals(vitalsMetricHandler);
