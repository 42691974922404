import { Overrides } from "@material-ui/core/styles/overrides";
import { CSSProperties, CreateCSSProperties } from "@material-ui/core/styles/withStyles";
import { MuiPickersOverrides } from "@material-ui/pickers/typings/overrides";
import breakpoints from "../breakpoints";
import palette from "../palette";

type overridesNameToClassKey = {
  [P in keyof MuiPickersOverrides]: keyof MuiPickersOverrides[P];
};

type CSSRules = CSSProperties | CreateCSSProperties<Record<string, unknown>> | undefined;

declare module "@material-ui/core/styles/overrides" {
  export interface ComponentNameToClassKey extends overridesNameToClassKey {
    MuiPickersDay: {
      day?: CSSRules;
      dayDisabled?: CSSRules;
    };
    MuiPickersBasePicker: {
      pickerView?: CSSRules;
    };
    MuiPickersCalendar: {
      transitionContainer?: CSSRules;
      week?: CSSRules;
    };
    MuiPickersCalendarHeader: {
      switchHeader?: CSSRules;
      transitionContainer?: CSSRules;
      iconButton?: CSSRules;
      dayLabel?: CSSRules;
    };
  }
}

const calendarOverrides: Overrides = {
  MuiPickersDay: {
    day: {
      fontSize: "1rem",
      "&:hover": {
        backgroundColor: palette.grey[100],
      },
      "& span, & p": {
        fontSize: "inherit",
      },
    },
    dayDisabled: {
      color: palette.grey[300],
      "&:hover": {
        backgroundColor: "transparent",
      },
      "& span, & p": {
        color: "inherit",
      },
    },
  },
  MuiPickersCalendar: {
    transitionContainer: {
      minHeight: 220,
      marginBottom: "1rem",
      [breakpoints.up("desktop")]: {
        minHeight: 236,
      },
    },
    week: {
      "&:not(:first-child)": {
        marginTop: "0.25rem",
      },
    },
  },
  MuiPickersCalendarHeader: {
    switchHeader: {
      marginTop: 0,
    },
    transitionContainer: {
      "& > p": {
        paddingLeft: "1.5rem",
        textAlign: "left",
        lineHeight: "24px",
        fontWeight: "bold",
      },
    },
    iconButton: {
      "&:first-child": {
        order: 1,
        paddingRight: "0.75rem",
      },
      "&:last-child": {
        order: 2,
        paddingLeft: "0.75rem",
        paddingRight: "1.5rem",
      },
    },
    dayLabel: {
      fontSize: "1rem",
      fontWeight: "bold",
      color: palette.text.primary,
    },
  },
};

export default calendarOverrides;
