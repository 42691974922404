import { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

import { AppState } from "@store";
import { MetaConfig, ITierSeoSettings } from "@ducks/config/types";

interface IBaseMetaState {
  meta: MetaConfig;
  tierSpecificMeta?: ITierSeoSettings;
}

const BaseMeta: FunctionComponent = () => {
  const state = useSelector<AppState, IBaseMetaState>((state) => ({
    meta: state.config.meta,
    tierSpecificMeta: state.shop.tier.seoSettings,
  }));
  const { meta, tierSpecificMeta } = state;
  const { thumbnail, ogType, twitterCard, twitterSite, themeColor } = meta;
  const titleTemplate = tierSpecificMeta?.titleTemplate ?? meta.titleTemplate;

  return (
    <Helmet titleTemplate={titleTemplate}>
      <meta property="og:image" content={thumbnail} />
      <meta property="og:type" content={ogType} />
      <meta name="twitter:card" content={twitterCard} />
      <meta name="twitter:image" content={thumbnail} />
      <meta name="twitter:site" content={twitterSite} />
      <meta name="theme-color" content={themeColor} />
    </Helmet>
  );
};

BaseMeta.displayName = "BaseMeta";

export default BaseMeta;
