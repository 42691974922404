import { createSelector } from "@reduxjs/toolkit";

import { AppState } from "@store";
import { AddressState, AddressCheckerResult } from "@ducks/address/types";
import { isComplete } from "@ducks/address";
import { ResidentialType } from "@ducks/signUp/types";
import { AbTestVariant } from "@lib/abTest";
import { abVariantSelector } from "./common";

export const loadingSelector = createSelector<AppState, boolean, boolean, boolean>(
  (state) => state.signUp.leadStatus.loading,
  (state) => state.address.registerInterestStatus.loading,
  (leadLoading, registerInterestLoading) => leadLoading || registerInterestLoading
);

export const checkCoverageCompleteSelector = createSelector<AppState, AddressState, AbTestVariant, boolean>(
  (state) => state.address,
  abVariantSelector,
  (addressState, abVariant) => isComplete(addressState, abVariant)
);

export const missingUnitFieldsSelector = createSelector<
  AppState,
  AddressCheckerResult | null,
  ResidentialType | null,
  boolean
>(
  (state) => state.address.selectedAddress,
  (state) => state.address.fields.residentialType.value,
  (selectedAddress, residentialType) => {
    if (selectedAddress !== null) {
      if (residentialType === ResidentialType.SingleDwelling) return !selectedAddress.unitNo;
      else if (residentialType === ResidentialType.MultiDwelling) return !selectedAddress.unitNo;
    }
    return false;
  }
);

export const showConfirmAddressSelector = createSelector<AppState, boolean, boolean, boolean>(
  (state) => Boolean(state.address.selectedAddress),
  (state) => state.address.hasAddressModified,
  (hasSelectedAddress, hasAddressModified) => hasSelectedAddress && !hasAddressModified
);

export const showRegisterInterestSelector = createSelector<AppState, boolean, boolean, boolean>(
  (state) => state.address.addressNotListed,
  (state) => state.address.hasAddressModified,
  (noAddress, hasAddressModified) => noAddress && !hasAddressModified
);

export const formattedAddressSelector = createSelector<
  AppState,
  AddressCheckerResult | null,
  ResidentialType | null,
  string,
  string,
  string,
  string[]
>(
  (state) => state.address.selectedAddress,
  (state) => state.address.fields.residentialType.value,
  (state) => state.address.fields.houseNumber.value,
  (state) => state.address.fields.unit.value,
  (state) => state.address.fields.block.value,
  (selectedAddress, residentialType, houseNumber, unit, block) => {
    if (selectedAddress && residentialType) {
      const { area, buildingName, city, postCode, state, streetName } = selectedAddress;
      return formatAddress(
        residentialType,
        houseNumber,
        unit,
        block,
        buildingName,
        streetName,
        area,
        postCode,
        city,
        state
      );
    }
    return [];
  }
);

export function formatAddress(
  residentialType: ResidentialType,
  houseNumber = "",
  unit = "",
  block = "",
  building = "",
  streetName = "",
  area = "",
  postcode = "",
  city = "",
  state = ""
): string[] {
  const lines: string[] = [];
  const firstLine: string[] = [];

  switch (residentialType) {
    case ResidentialType.SingleDwelling:
      houseNumber && firstLine.push(houseNumber);
      streetName && firstLine.push(streetName);
      firstLine.length && lines.push(firstLine.join(", "));
      break;
    case ResidentialType.MultiDwelling:
      unit && firstLine.push(unit);
      block && firstLine.push(block);
      building && firstLine.push(building);
      firstLine.length && lines.push(firstLine.join(", "));
      streetName && lines.push(streetName);
      break;
  }
  area && lines.push(area);
  lines.push(`${postcode} ${city}`, state);

  return lines.map((line, i, array) => line + (i < array.length - 1 ? "," : ""));
}
