import { LogoutModalLanguageConfig } from "@ducks/types";
import {
  AddressLanguageConfig,
  FormLanguageConfig,
  FullScreenErrorMessageMap,
  OrderSummaryLanguageConfig,
  OtpLanguageConfig,
  PackSelectionLanguageConfig,
  ProductInfoLanguageConfig,
  RegisterInterestLanguageConfig,
  ThankYouLanguageConfig,
} from "./types";

export const PACK_SELECTION_LANGUAGE: PackSelectionLanguageConfig = {
  STEP_PREFIX: "",
  LEARN_MORE: "",
  SHOW_MORE: "",
  BASE_PRICE: "",
  BASE_PRICE_STRIKE: "",
  PACK_PRICE: "",
  STREAMING_APP_SINGULAR: "",
  STREAMING_APP_PLURAL: "",
  STREAMING_APP_BUNDLED: "",
  ADDON_PRICE: "",
  ADDON_PRICE_STRIKE: "",
  BUNDLED: "",
  DEVICE_PRICE: "",
  DEVICE_PRICE_STRIKE: "",
  DEVICE_FEE: "",
  DEVICE_FEE_STRIKE: "",
  FREE_INSTALLATION: "",
  INSTALLATION_METHOD_FEE: "",
  INSTALLATION_METHOD_FEE_STRIKE: "",
  TOTAL: "",
  TOTAL_BAR_PRICE: "",
  MONTHLY_FEE: "",
  ONE_TIME_FEE: "",
  PROCEED: "",
  MODAL_OK: "",
  MODAL_BUNDLE_HEADING: "",
  MODAL_BUNDLE_MESSAGE_HTML: "",
  MODAL_INVALID_HEADING: "",
  MODAL_INVALID_MESSAGE_HTML: "",
  ERROR_PACK: "",
  ERROR_BASIC_ADDONS: "",
  ERROR_DEVICES: "",
  ERROR_INSTALLATION_METHOD: "",
  BROADBAND_TITLE: "",
  UPSELL_TITLE: "",
  UPSELL_YES: "",
  UPSELL_NO: "",
  FREE: "",
  SEE_ALL_CHANNEL_DETAILS: "",
  MULTILANGUAGE_BASE: "",
  MULTILANGUAGE_BASE_GA: "",
  HELP_ME_HEADING: "",
  HELP_ME_DESCRIPTION: "",
  HELP_ME_UPGRADE: "",
  HELP_ME_UPGRADE_DESCRIPTION: "",
  HELP_ME_UPGRADE_BUTTON: "",
  HELP_ME_SUBSCRIBE: "",
  HELP_ME_SUBSCRIBE_DESCRIPTION: "",
  HELP_ME_SUBSCRIBE_BUTTON: "",
  BB_UPSELL_MODAL: {
    BENEFITS: [],
    DESCRIPTION_TEMPLATE: "",
    TITLE: "",
    YES: "",
    NO: "",
  },
  subscriberStep: {
    tv: {
      TITLE: "",
      DESCRIPTION: "",
      SUBSCRIBER_YES: "",
      SUBSCRIBER_NO: "",
    },
    bb: {
      TITLE: "",
      DESCRIPTION: "",
      SUBSCRIBER_YES: "",
      SUBSCRIBER_NO: "",
    },
  },
  itemName: {
    pack: "",
    addOn: "",
    contentCount: "",
    channelSingular: "",
    channelPlural: "",
    appsSingular: "",
    appsPlural: "",
    connective: "",
  },
};

export const PRODUCT_INFO_LANGUAGE: ProductInfoLanguageConfig = {
  PAGINATION: "",
  NUMBER_OF_BASE_CHANNELS: "",
  NUMBER_OF_BASE_CHANNELS_SINGULAR: "",
  NUMBER_OF_CHANNELS: "",
  NUMBER_OF_CHANNELS_SINGULAR: "",
  NUMBER_OF_APPS: "",
  NUMBER_OF_APPS_SINGULAR: "",
  APPS_HEADING: "",
  SINGLE_CHANNEL: "",
  MULTI_CHANNEL: "",
  ALSO_SEE: "",
};

export const ORDER_SUMMARY_LANGUAGE: OrderSummaryLanguageConfig = {
  ORDER_SUMMARY: "",
  FREE: "",
  MONTHLY_FEE: "",
  TOTAL_MONTHLY: "",
  ONE_TIME_FEES: "",
  PACKS_AND_SERVICES: "",
  ADD_ONS: "",
  DEVICES: "",
  OTHERS_TAXES: "",
  PRICE: "",
  MONTHLY_PRICE: "",
  ONE_TIME_PRICE: "",
  INSTALLATION_FEE: "",
  INSTALLATION_FEE_METHOD: "",
  TAX_INCLUSIVE: "",
  SERVICE_TAX: "",
  TOTAL_ONE_TIME: "",
  AGREEMENT_SHOP: "",
  AGREEMENT_BB: "",
  agreement: {
    HTML: "",
    terms: {},
    privacies: {},
  },
  INSTALL_FEE_TERMS: "",
  CONFIRM: "",
  PERSONAL_INFORMATION: "",
  NAME_IDENTITY: "",
  INSTALLATION_ADDRESS: "",
  INSTALLATION_SCHEDULE: "",
  CONTACT_INFORMATION: "",
  TERMS_CONDITIONS: "",
  OK: "",
  BB_INVOICE_NAME: "",
  REFERRAL_INVOICE_NAME: "",
  consentErrorMessage: {
    GENERIC_ERROR: {
      title: "",
      message: "",
    },
  },
  referralErrorMessage: {
    EMPTY_FIELD: "",
    DUPLICATE_CODE: "",
  },
  REFERRAL_TITLE: "",
  REFERRAL_PLACEHOLDER: "",
  REFERRAL_APPLY: "",
  REFERRAL_APPLIED: "",
  REFERRAL_REPLACE_TITLE: "",
  REFERRAL_REPLACE_DESCRIPTION: "",
  REFERRAL_REPLACE_NO: "",
  REFERRAL_REPLACE_YES: "",
  REFERRAL_RETRIEVING: "",
  BF_MASKED_ID: "",
};

export const ADDRESS_LANGUAGE: AddressLanguageConfig = {
  CHECK: "",
  SEARCH: "",
  SEARCH_AGAIN: "",
  RETRIEVING: "",
  CHECK_COVERAGE: "",
  CHECK_COVERAGE_DESCRIPTION_HTML: "",
  CONFIRM_ADDRESS: "",
  COMPLETE_YOUR_ADDRESS: "",
  ALLOW_DISH_INSTALLATION: "",
  TNC_AGREEMENT: "",
  UNABLE_TO_RETRIEVE_ADDRESS: "",
  ADDRESS_LISTING_SDU_HEADING: "",
  ADDRESS_LISTING_SDU_SEARCH_PLACEHOLDER: "",
  ADDRESS_LISTING_MDU_HEADING: "",
  ADDRESS_LISTING_MDU_SEARCH_PLACEHOLDER: "",
  ADDRESS_LISTING_DESCRIPTION: "",
  MY_ADDRESS_IS_NOT_LISTED: "",
  IS_THIS_YOUR_ADDRESS: "",
  IS_THIS_YOUR_ADDRESS_DESCRIPTION: "",
  YES: "",
  NO: "",
  REGISTER_INTEREST: "",
  REGISTER_INTEREST_NO_RESULTS: "",
  REGISTER_INTEREST_DESCRIPTION: "",
  REGISTER_INTEREST_BUTTON: "",
  errorMessage: {},
};

export const FORM_LANGUAGE: FormLanguageConfig = {
  SIGN_UP: "",
  REQUIRED_MARKER: "",
  REQUIRED_ERROR: "",
  PERSONAL_INFORMATION: "",
  NAME: "",
  NAME_SHORT: "",
  SALUTATION_PLACEHOLDER: "",
  NAME_PLACEHOLDER: "",
  NAME_INVALID_ERROR: "",
  ID: "",
  ID_INVALID_ERROR: "",
  ID_TYPE_PLACEHOLDER: "",
  UPLOAD_DOC: "",
  UPLOAD_DOC_DESCRIPTION_HTML: "",
  WHY_NEED_THIS: "",
  WHY_NEED_THIS_TITLE: "",
  WHY_NEED_THIS_DESCRIPTION_HTML: "",
  WHY_NEED_THIS_OK: "",
  FILE_SIZE_ERROR: "",
  FILE_MINIMUM_SIZE_ERROR: "",
  FILE_FORMAT_ERROR: "",
  FILE_UPLOAD_ERROR: "",
  DELETE_FILE_TITLE: "",
  DELETE_FILE_DESCRIPTION: "",
  DELETE_FILE_CONFIRM: "",
  DELETE_FILE_CANCEL: "",
  DOB: "",
  DOB_PLACEHOLDER: "",
  DOB_INVALID_ERROR: "",
  DOB_NOT_ELIGIBLE: "",
  GENDER: "",
  GENDER_PLACEHOLDER: "",
  MALE: "",
  FEMALE: "",
  ETHNIC: "",
  ETHNIC_PLACEHOLDER: "",
  LANGUAGE: "",
  LANGUAGE_PLACEHOLDER: "",
  SELECT_ADDRESS: "",
  SELECT_ADDRESS_DESCRIPTION_HTML: "",
  SELECT_ADDRESS_PLACEHOLDER: "",
  NO_ADDRESS_FOUND: "",
  ADDRESS_NOT_LISTED: "",
  INSTALLATION_ADDRESS: "",
  RESIDENTIAL_TYPE: "",
  RESIDENTIAL_TYPE_PLACEHOLDER: "",
  MULTI_DWELLING: "",
  SINGLE_DWELLING: "",
  UNIT: "",
  UNIT_PLACEHOLDER: "",
  UNIT_INVALID_ERROR: "",
  BLOCK: "",
  BLOCK_PLACEHOLDER: "",
  BLOCK_INVALID_ERROR: "",
  BUILDING: "",
  BUILDING_PLACEHOLDER: "",
  BUILDING_INVALID_ERROR: "",
  HOUSE_NUMBER: "",
  HOUSE_NUMBER_PLACEHOLDER: "",
  HOUSE_NUMBER_INVALID_ERROR: "",
  STREET_NAME: "",
  STREET_NAME_PLACEHOLDER: "",
  STREET_NAME_INVALID_ERROR: "",
  STREET_TYPE_PLACEHOLDER: "",
  AREA: "",
  AREA_PLACEHOLDER: "",
  AREA_INVALID_ERROR: "",
  POSTCODE: "",
  POSTCODE_PLACEHOLDER: "",
  POSTCODE_INVALID_ERROR: "",
  POSTCODE_FAILED_ERROR: "",
  SEARCH: "",
  RETRIEVING: "",
  CITY: "",
  CITY_PLACEHOLDER: "",
  CITY_INVALID_ERROR: "",
  STATE: "",
  STATE_PLACEHOLDER: "",
  INSTALLATION_SCHEDULE: "",
  INSTALLATION_DESCRIPTION_HTML: "",
  INSTALLATION_DATE: "",
  INSTALLATION_DATE_PLACEHOLDER: "",
  INSTALLATION_TIME: "",
  INSTALLATION_TIME_PLACEHOLDER: "",
  CONTACT_INFO: "",
  CONTACT_INFO_DESCRIPTION_HTML: "",
  INSTALLATION_METHOD_NOT_AVAILABLE_DIALOG: {
    HEADING_TEMPLATE: "",
    DESCRIPTION_TEMPLATE: "",
  },
  MOBILE_NUMBER: "",
  ALTERNATE_NUMBER: "",
  MOBILE_NUMBER_PLACEHOLDER: "",
  MOBILE_NUMBER_INVALID_ERROR: "",
  MOBILE_PREFIX_PLACEHOLDER: "",
  ALTERNATE_NUMBER_PLACEHOLDER: "",
  ALTERNATE_NUMBER_INVALID_ERROR: "",
  EMAIL: "",
  EMAIL_PLACEHOLDER: "",
  EMAIL_INVALID_ERROR: "",
  CANCEL: "",
  PROCEED: "",
  SUBMIT: "",
  OK: "",
};

export const OTP_LANGUAGE: OtpLanguageConfig = {
  OTP_HEADING: "",
  OTP_MESSAGE: "",
  TEXTFIELD_LABEL: "",
  RESEND: "",
  RESEND_IN: "",
  VERIFY: "",
  EDIT_MOBILE_NUMBER: "",
  REMAINING_ATTEMPTS_SINGULAR: "",
  REMAINING_ATTEMPTS_PLURAL: "",
  errorMessage: {},
};

export const REGISTER_INTEREST_LANGUAGE: RegisterInterestLanguageConfig = {
  HEADING: "",
  SUBHEADING: "",
  WHAT_HAPPENS_NEXT: "",
  WHAT_HAPPENS_NEXT_ITEMS_HTML: [],
  DONE: "",
};

export const THANK_YOU_LANGUAGE: ThankYouLanguageConfig = {
  header: "",
  topDescriptionHtml: {
    tv: "",
    bb: "",
  },
  bottomDescriptionHtml: "",
};

export const FULLSCREEN_ERROR_MESSAGE_LANGUAGE: FullScreenErrorMessageMap = {
  GENERIC_ERROR: {
    title: "Oops, something went wrong",
    message: "Apologies for the inconvenience, but rest assured we’re working on it. Please try again later.",
  },
  MAINTENANCE: {
    title: "Under Maintenance",
    message: "Oops, we are down for scheduled maintenance right now. We will be back soon!",
  },
  NOT_FOUND: {
    title: "404",
    message: "Oops, the page you were looking for does not exist.",
    buttonText: "GO TO HOME",
  },
  SESSION_EXPIRED: {
    title: "Session Expired",
    message: "The session has expired.",
    buttonText: "GO TO HOME",
    buttonUrl: "https://product.astro.com.my",
  },
  SESSION_EXPIRED_TV: {
    title: "Session Expired",
    message: "The session has expired.",
    buttonText: "GO TO HOME",
    buttonUrl: "https://product.astro.com.my/tv",
  },
  SESSION_EXPIRED_BB: {
    title: "Session Expired",
    message: "The session has expired.",
    buttonText: "GO TO HOME",
    buttonUrl: "https://product.astro.com.my/broadband",
  },
};

export const LOGOUT_MODAL_LANGUAGE: LogoutModalLanguageConfig = {
  title: "",
  descriptionHtml: "",
  continueLogout: "",
  cancelLogout: "",
};
