import { FetchStatus } from "@ducks/types";

export enum ConsentErrorType {
  Overlay,
  Modal,
}

export enum ConsentErrorCode {
  InvalidToken = "FORBIDDEN",
  Validation = "VALIDATION_ERROR",
  LeadReadFailed = "LEAD_READ_FAILED",
  LeadNotVerified = "INVALID_LEAD_UPDATE",
  LeadUpdateFailed = "LEAD_UPDATION_FAILED",
  RefereeOfferPriceMismatch = "REFEREE_OFFER_PRICE_MISMATCH",
  InvalidReferral = "INVALID_REFERRAL",
  InvalidSmcNo = "INVALID_SMC_NO",
  InactiveSmcNo = "INACTIVE_SMC_NO",
  PromoCodeUsed = "PROMO_CODE_USED",
  PromoCodeNotEligible = "PROMO_CODE_NOT_ELIGIBLE",
  EligibilityCheckFailed = "FAILED_TO_CHECK_PROMO_ELIGIBILITY",
}

export interface OrderSummaryState {
  config: OrderSummaryConfig;

  packSection: BillItemLabel[];
  addOnsSection: BillItemLabel[];
  devicesMonthlySection: BillItemLabel[];
  devicesInstallationSection: BillItemLabel[];
  termsConditionsSection: string[];

  nameIdentity: string[];
  installationAddress: string[];
  installationSchedule: string[];
  contactInformation: string[];

  consentStatus: ConsentStatus;
}

interface ConsentStatus extends FetchStatus {
  referralErrorCode?: string;
}

export interface UpdatePersonalInformation {
  nameIdentity: string[];
  installationAddress: string[];
  installationSchedule: string[];
  contactInformation: string[];
}

export interface ConsentRequest {
  referralSignature?: string;
  promoCode?: string;
  campaignCode?: string;
}

export interface ConsentRejectValue {
  errorType: ConsentErrorType;
  errorCode?: string;
}
export interface BillItemLabel {
  name: string;
  currentPrice: string;
  prevPrice?: string;
}

export interface ReferralConfig {
  blockedRegex: string;
  length: number;
}

type IPromotionConfig = Record<string, IPromotion>;

interface IPromotion {
  descriptionHtml: string;
  priceTag?: string;
}

interface IIDTypeMapping {
  [key: string]: string;
  NRIC: string;
  Passport: string;
  "Police ID": string;
  "Army ID": string;
  "Navy ID": string;
  "Air Force ID": string;
  "National IC - Old": string;
  "Registration ID": string;
}

export interface OrderSummaryConfig {
  addOnsSequence: string[];
  promotions: IPromotionConfig;
  terms: IPromotionConfig;
  showAlternateNumber: boolean;
  showHomeNumber: boolean;
  showOfficeNumber: boolean;
  referralCode: ReferralConfig;
  appliedReferralIcon: string;
  idTypeMapping: IIDTypeMapping;
}

export interface TermsConditionsEvent {
  title: string;
  href: string;
}
