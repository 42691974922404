import {
  ShopEventDefinition,
  getBrand,
  EventType,
  EcommerceMode,
  getProducts,
  createEcommerceEvent,
} from "@ducks/analytics";
import { EventsMap } from "redux-beacon";
import { personalInformationUpdated, trackCheckout, trackInstallFeeLink, trackTermsConditions } from ".";
import { orderSummaryLink, ORDER_SUMMARY_LIST_TYPE } from "./constants";
import { TermsConditionsEvent } from "./types";
import { portalSelector } from "@selectors/portalType";

/* Event definitions */
const viewOrderSummaryEvent: ShopEventDefinition = (action, state) => {
  const portal = portalSelector(state);

  return createEcommerceEvent({
    mode: EcommerceMode.Checkout,
    brand: getBrand(portal),
    listType: ORDER_SUMMARY_LIST_TYPE,
    products: getProducts(state),
    actionStep: 3,
  });
};
const trackCheckoutEvent: ShopEventDefinition = (action, state) => {
  const portal = portalSelector(state);

  return {
    event: EventType.Submit,
    content_type: getBrand(portal),
    content_list_type: ORDER_SUMMARY_LIST_TYPE,
    content_title: "Confirm Order",
  };
};
const trackInstallFeeLinkEvent: ShopEventDefinition<string> = (action, state) => {
  const portal = portalSelector(state);

  return {
    event: EventType.Nav,
    content_type: getBrand(portal),
    content_list_type: orderSummaryLink,
    content_title: "See full price list",
    content_id: action.payload,
  };
};
const trackTermsConditionsEvent: ShopEventDefinition<TermsConditionsEvent> = (action, state) => {
  const portal = portalSelector(state);
  if (action.payload) {
    const { title, href } = action.payload;

    return {
      event: EventType.Nav,
      content_type: getBrand(portal),
      content_list_type: orderSummaryLink,
      content_title: title,
      content_id: href,
    };
  }
};

/* Events map */
const orderSummaryEventsMap: EventsMap = {
  [personalInformationUpdated.toString()]: viewOrderSummaryEvent,
  [trackCheckout.toString()]: trackCheckoutEvent,
  [trackInstallFeeLink.toString()]: trackInstallFeeLinkEvent,
  [trackTermsConditions.toString()]: trackTermsConditionsEvent,
};

export default orderSummaryEventsMap;
