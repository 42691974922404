import { createSlice } from "@reduxjs/toolkit";

import { AppsLanguageConfig } from "./types";
import { fetchAppsConfig } from "@ducks/config";
import {
  ACTIVATE_COMPLETE_LANGUAGE,
  ACTIVATE_LANGUAGE,
  APPS_ERROR_MESSAGE_LANGUAGE,
  CONFIRM_ORDER_MODAL_LANGUAGE,
  CONTACT_LANGUAGE,
  HOW_TO_CHECK_MODAL_LANGUAGE,
  MOBILE_NUMBER_LANGUAGE,
  MULTI_SMC_MODAL_LANGUAGE,
  OTP_LANGUAGE,
  PURCHASE_LANGUAGE,
  PURCHASE_INELIGIBLE,
  SELECT_PLAN_LANGUAGE,
  THANK_YOU_LANGUAGE,
  THANK_YOU_MESSAGE,
  WHATS_NEXT_LANGUAGE,
  CONTACT_THANK_YOU_LANGUAGE,
  WAITING_LANGUAGE,
  UPGRADE_PACK,
} from "./constants";

const initialState: AppsLanguageConfig = {
  selectPlan: SELECT_PLAN_LANGUAGE,
  mobileNumber: MOBILE_NUMBER_LANGUAGE,
  activate: ACTIVATE_LANGUAGE,
  activatePending: THANK_YOU_MESSAGE,
  activateComplete: ACTIVATE_COMPLETE_LANGUAGE,
  purchase: PURCHASE_LANGUAGE,
  purchaseInEligible: PURCHASE_INELIGIBLE,
  errorMessage: APPS_ERROR_MESSAGE_LANGUAGE,
  confirmOrderModal: CONFIRM_ORDER_MODAL_LANGUAGE,
  multiSmcModal: MULTI_SMC_MODAL_LANGUAGE,
  howToCheckModal: HOW_TO_CHECK_MODAL_LANGUAGE,
  otp: OTP_LANGUAGE,
  thankYou: THANK_YOU_LANGUAGE,
  whatsNext: WHATS_NEXT_LANGUAGE,
  contact: CONTACT_LANGUAGE,
  contactThankYou: CONTACT_THANK_YOU_LANGUAGE,
  waiting: WAITING_LANGUAGE,
  loadingMessageHtml: "",
  whatsAppButtonText: "",
  fallbackErrorDisplayCode: "",
  whatsAppButtonChat: "",
  upgradePack: UPGRADE_PACK,
};

const appsLanguageSlice = createSlice({
  name: "appsLanguage",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAppsConfig.fulfilled, (state, action) => {
      const { language } = action.payload;
      if (language) {
        state.selectPlan = language.selectPlan;
        state.mobileNumber = language.mobileNumber;
        state.activate = language.activate;
        state.activatePending = language.activatePending;
        state.activateComplete = language.activateComplete;
        state.purchase = language.purchase;
        state.purchaseInEligible = language.purchaseInEligible;
        state.errorMessage = language.errorMessage;
        state.confirmOrderModal = language.confirmOrderModal;
        state.multiSmcModal = language.multiSmcModal;
        state.howToCheckModal = language.howToCheckModal;
        state.otp = language.otp;
        state.thankYou = language.thankYou;
        state.whatsNext = language.whatsNext;
        state.contact = language.contact;
        state.contactThankYou = language.contactThankYou;
        state.waiting = language.waiting;
        state.loadingMessageHtml = language.loadingMessageHtml;
        state.whatsAppButtonText = language.whatsAppButtonText;
        state.fallbackErrorDisplayCode = language.fallbackErrorDisplayCode;
        state.whatsAppButtonChat = language.whatsAppButtonChat;
        state.upgradePack = language.upgradePack;
      }
    });
  },
});

export default appsLanguageSlice.reducer;
