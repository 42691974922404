import { AppsUlmConfig } from "./types";

export const APPS_ULM_CONFIG: AppsUlmConfig = {
  url: "",
  domain: "",
  rp: "",
  handler: "",
  scope: [],
};

export const ulmUuidCookie = "acm_ulm_uuid";
export const ulmHandlerTriggeredCookie = "acm_ulm_handler_triggered";
