import type { NavEnvironment } from "acm-components/module/layouts/Navigation/NavProvider";
import json from "settings.json";

interface ProjectSettings {
  navEnv: NavEnvironment;
  config: {
    url: string;
    key: {
      app: string;
      language: string;
      packSelection: string;
      streamingApps: string;
      tiers: string;
      addOns: string;
      devices: string;
      installationMethods: string;
      price: string;
      productInfo: string;
      accordion: string;
      meta: string;
      orderSummary: string;
      form: string;
      otp: string;
      address: string;
      regex: string;
      thankYou: string;
      resizer: string;
    };
  };
  apiEndpoint: {
    channel: string;
    generateLead: string;
    updateLead: string;
    updateBFLead: string;
    generateSignUrl: string;
    deleteDocument: string;
    generateOtp: string;
    verifyOtp: string;
    postcode: string;
    consent: string;
    checkAddress: string;
    checkAddressV2: string;
    registerInterest: string;
    checkReferralCode: string;
    getCustomerInfo: string;
    listEntitlements: string;
    validateEligibility: string;
    validatePurchase: string;
    validateActivate: string;
    activate: string;
    purchaseOtp: string;
    purchase: string;
    disneyGenerateLead: string;
    disneyVerifyLead: string;
    resync: string;
  };
  contentHubUrl: string;
  bugsnagApiKey: string;
  appsConfig: {
    url: string;
    key: {
      apps: string;
      meta: string;
      language: string;
      ulm: string;
      mobileNumber: string;
      offerMapping: string;
      disneyAddOns: string;
      upgradePack: string;
      otp: string;
      customerTypeMapping: string;
      contactForm: string;
      purchaseEligibleOfferId: string;
    };
  };
}

const settings: ProjectSettings = json as unknown as ProjectSettings;

export default settings;
