/* Shop TV & BB plans */
export const orderSummaryPath = "order-summary";
export const signUpFormPath = "sign-up";
export const checkCoveragePath = "check-coverage";
export const thankYouPath = "order-success";
export const registerInterestPath = "register-thank-you";

/* Apps */
export const disneyActivatePath = "disney";
export const disneyPurchasePath = "disney/purchase";
export const disneyActivateThankYouPath = "disney/activate/thankyou";
export const disneyActivateCompletePath = "disney/activate/complete";
export const disneyActivatePendingPath = "disney/activate/pending";
export const disneyPurchaseThankYouPath = "disney/purchase/thankyou";
export const disneyContactPath = "disney/contact";
export const disneyContactThankYouPath = "disney/contact/thankyou";
export const disneyWaitingPath = "disney/waiting";
export const disneyPurchaseIneligiblePath = "disney/purchase-ineligible";
