import { EventDefinition } from "redux-beacon";
import { PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "@store";

export const appsDisneyHotstar = "Apps - Disney Hotstar";

export enum EventType {
  View = "viewEvent",
  Click = "clickEvent",
  Popup = "popupEvent",
  TrafficAnalysis = "traffic_analysis",
}

export enum DisneyPageType {
  Activate = "Activation",
  Purchase = "Purchase Activation",
  ActivateThankYou = "Activation Success",
  PurchaseThankYou = "Purchase Activation Thank you",
  ActivatePending = "Activation Pending",
  ActivateComplete = "Activation Complete",
  Contact = "BCP Lead Form",
  ContactThankYou = "BCP Lead Form Thank you",
  WaitingRoom = "Waiting Room",
  PurchaseIneligible = "Purchase Ineligible",
}

export const contactPage = "Contact";
export const contactThankYouPage = "Contact Thank you";

export interface GtmEvent {
  event?: string;
  content_type?: string;
  content_list_type?: string;
  content_title?: string;
  content_category?: string;
  content_id?: string;
}

export interface DisneyErrorData {
  api_endpoint: string;
  error_code: string;
  error_status: string;
  account_id?: string;
}

export interface DisneyTrafficData {
  account_id?: string;
  screen_name: string;
  reason: string;
  api_endpoint?: string;
}

export interface AppsDisneyTrafficGtmEvent extends GtmEvent, DisneyTrafficData {}

export interface AppsDisneyErrorGtmEvent extends GtmEvent, DisneyErrorData {
  ulm_token: string;
}

export type AppsDisneyErrorEventDefinition<T = DisneyErrorData> = EventDefinition<
  AppsDisneyErrorGtmEvent | undefined,
  Partial<PayloadAction<T>>,
  AppState
>;

export type AppsDisneyTrafficEventDefinition<T = DisneyTrafficData> = EventDefinition<
  AppsDisneyTrafficGtmEvent | undefined,
  Partial<PayloadAction<T>>,
  AppState
>;

export type AppsDisneyTrafficSessionExpiredEventDefinition<T = string> = EventDefinition<
  AppsDisneyTrafficGtmEvent | undefined,
  Partial<PayloadAction<T>>,
  AppState
>;

export type AppsDisneyEventDefinition<T = undefined> = EventDefinition<
  GtmEvent | GtmEvent[] | undefined,
  Partial<PayloadAction<T>>,
  AppState
>;

export interface TermsConditionsLinkEvent {
  pageType: DisneyPageType;
  title: string;
  href: string;
}

export interface DownloadAppsCtaEvent {
  pageType: DisneyPageType;
  name: string;
  href: string;
}
