import { FunctionComponent, useCallback, useEffect, useRef } from "react";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";

export interface BaseModalProps extends DialogProps {
  lockBodyScroll?: boolean;
}

const BaseModal: FunctionComponent<BaseModalProps> = (props) => {
  const { lockBodyScroll = true, onClose: onCloseCallback, onEntered: onEnteredCallback, ...dialogProps } = props;
  const dialog = useRef<HTMLElement>(null);

  const toggleScrollLock = useCallback(
    (state: boolean) => {
      if (dialog.current && lockBodyScroll) {
        const scrollerClass =
          dialogProps.scroll === "body" ? "MuiDialog-paperScrollBody" : "MuiDialog-paperScrollPaper";
        const scroller = dialog.current.getElementsByClassName(scrollerClass)[0];
        if (scroller) state ? disableBodyScroll(scroller) : enableBodyScroll(scroller);
      }
    },
    [dialog, lockBodyScroll, dialogProps.scroll]
  );

  const handleClose = useCallback(
    (event: Record<string, unknown>, reason: "backdropClick" | "escapeKeyDown") => {
      onCloseCallback && onCloseCallback(event, reason);
    },
    [onCloseCallback]
  );

  const handleEntered = useCallback(
    (node: HTMLElement, isAppearing: boolean) => {
      toggleScrollLock(true);
      onEnteredCallback && onEnteredCallback(node, isAppearing);
    },
    [toggleScrollLock, onEnteredCallback]
  );

  const handleExit = useCallback(() => {
    toggleScrollLock(false);
    clearAllBodyScrollLocks();
  }, [toggleScrollLock]);

  useEffect(() => {
    return () => {
      toggleScrollLock(false);
      clearAllBodyScrollLocks();
    };
  }, [toggleScrollLock]);

  return <Dialog ref={dialog} onClose={handleClose} onEntered={handleEntered} onExit={handleExit} {...dialogProps} />;
};

BaseModal.displayName = "BaseModal";

export default BaseModal;
