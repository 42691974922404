import { createAction, createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import settings from "@settings";
import {
  ChannelData,
  ChannelDetailsEvent,
  ModalType,
  PackProductInfo,
  ProductInfo,
  ProductInfoMap,
  ProductInfoState,
} from "./types";
import { fetchConfig } from "../config";
import { APIResponse } from "@lib/api";
import { AppThunk } from "@store";

const getChannelData = createAsyncThunk("productInfo/getChannelData", async () => {
  const response = await fetch(settings.apiEndpoint.channel, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = (await response.json()) as APIResponse<ChannelData>;
  return data.response;
});

const initialState: ProductInfoState = {
  config: {
    contentGuideDomain: "",
    bannerSwiperConfig: {},
    modals: {},
  },
  channelDataFetchStatus: {
    loading: false,
    error: false,
    success: false,
  },
  channelData: {},
  selectedModalId: null,
  modalHeadingHeight: 0,
};

const productInfoSlice = createSlice({
  name: "productInfo",
  initialState,
  reducers: {
    updateModalId(state, action: PayloadAction<string>) {
      const modalId = action.payload;
      if (state.config.modals[modalId]) state.selectedModalId = modalId;
    },
    resetModalId(state) {
      state.selectedModalId = null;
    },
    updateHeadingHeight(state, action: PayloadAction<number>) {
      if (action.payload >= 0) state.modalHeadingHeight = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchConfig.fulfilled, (state, action) => {
        action.payload.productInfo && (state.config = action.payload.productInfo);
      })
      .addCase(getChannelData.pending, (state) => {
        state.channelDataFetchStatus = {
          loading: true,
          error: false,
          success: false,
        };
      })
      .addCase(getChannelData.rejected, (state) => {
        state.channelDataFetchStatus = {
          loading: false,
          error: true,
          success: false,
        };
      })
      .addCase(getChannelData.fulfilled, (state, action) => {
        state.channelDataFetchStatus = {
          loading: false,
          error: false,
          success: true,
        };
        state.channelData = action.payload;
      });
  },
});

export const { updateModalId, resetModalId, updateHeadingHeight } = productInfoSlice.actions;

export default productInfoSlice.reducer;

export const fetchChannelData = (): AppThunk => async (dispatch, getState) => {
  if (getState().productInfo.channelDataFetchStatus.success) return;
  dispatch(getChannelData());
};

/* Analytics actions */
export const trackChannelDetails = createAction<string>("shop/trackChannelDetails");
export const trackChannelClick = createAction<ChannelDetailsEvent>("shop/trackChannelClick");

export function isPackModal(product: ProductInfo): product is PackProductInfo {
  const { type, tier, channelHeading } = product;
  return type === ModalType.Pack && tier !== undefined && channelHeading !== undefined;
}

export function getNumberOfChannels(id: string, modals: ProductInfoMap): number {
  const productInfo = modals[id];
  if (!productInfo) return 0;

  let numberOfChannels = productInfo.channels.length;
  if (isPackModal(productInfo)) {
    const { tier } = productInfo;
    if (modals[tier]) numberOfChannels += modals[tier].channels.length;
  }
  return numberOfChannels;
}
