import { FetchStatus } from "../types";
import { ITierConfig, ISpeed, IPack } from "../config/types";
import ShopCalculator, { IInvoice } from "@lib/calculator";
import ShopUrlQueryMapper, { ShopUrlQueryParameters } from "@lib/urlQueryMap";

export enum HelpMe {
  Upgrade = "Upgrade",
  Subscribe = "Subscribe",
}

export enum BbUpsellChoice {
  NOT_SET = "NOT_SET",
  INTERESTED = "INTERESTED",
  NOT_INTERESTED = "NOT_INTERESTED",
}

export interface ShopState {
  configStatus: FetchStatus;
  tier: ITierConfig;
  calculator: ShopCalculator;
  urlQueryMapper: ShopUrlQueryMapper;
  invoice: IInvoice;
  selectedSpeed: ISpeed | null;
  packs: IItems;
  devices: IItems;
  methods: IItems;
  selectedPack: IPack | null;
  selectedPackBundle: string[];
  selectedDevice: string;
  selectedAddOns: string[];
  selectedMethod: string;
  isBbUpsellModalOpen: boolean;
  bbUpsellChoice: BbUpsellChoice;
  listedAddOns: IItems;
  comboAddOns: string[];
  bundledAddOns: string[];
  requiredAddOns: string[];
  addOnSelection: AddOnSelection | null;
  bundleMessage: string | null;
  error: ShopError;
  showUpsellPopup: boolean;
  urlQueryParameters: ShopUrlQueryParameters;
  isSubscriber: boolean | null;
  selectedHelpMe: string;
  removeDevice: boolean;
}

export interface IItems {
  [id: string]: IItemInfo;
}

export interface IItemInfo {
  /**
   * Displayed price difference
   */
  displayPrice: number;
  /**
   * Display fee difference
   */
  displayFee?: number;
  /**
   * Actual item price in invoice
   */
  itemPrice: number;
  itemFee?: number;
  selected?: boolean;
  bundled?: boolean;
  disabled?: boolean;
}

export interface BundleAlert {
  parentPrice: number;
  parent: string;
  children: string[];
}

export interface ShopError extends SelectionUpdateError {
  alert: boolean; // show error popup
}

export interface SelectionUpdateError {
  pack: boolean;
  basicAddOns: boolean;
  devices: boolean;
  method: boolean;
}

export interface AddOnSelection {
  /**
   * Add-on added by user
   */
  addManual: string[];
  /**
   * Add-on removed by user
   */
  removeManual: string[];
  /**
   * Add-on added into user selection as combo with other selected add-ons
   */
  addCombo: string;
  /**
   * Add-ons added into user selection because required together with other selected add-ons
   */
  addRequired: string[];
  /**
   * Add-ons removed from user selection because bundled for free with other selected add-ons
   */
  removeBundled: string[];
  /**
   * Add-ons removed from user selection because no longer required together with other selected add-ons
   */
  removeRequired: string[];
}

export interface InitShopMeta {
  init: boolean;
}

export interface InitShopNoPack {
  calculator: ShopCalculator;
  urlQueryMapper: ShopUrlQueryMapper;
  tier: ITierConfig;
  selectedSpeed: ISpeed | null;
  packs: IItems;
  urlQueryParameters: ShopUrlQueryParameters;
}

export interface InitShopState extends InitShopNoPack, ISelectionUpdate {
  selectedPack: IPack;
  selectedDevice: string;
  selectedMethod: string;
  devices: IItems;
  methods: IItems;
  error: SelectionUpdateError;
}

export interface UpdateSelectedSubscriberPayload extends ISelectionUpdate {
  isSubscriber: boolean;
  selectedDevice: string;
  selectedMethod: string;
  devices: IItems;
  methods: IItems;
  removeDevice: boolean;
}

export interface UpdateSelectedPackPayload extends ISelectionUpdate {
  selectedPack: IPack;
  selectedDevice: string;
  selectedMethod: string;
  devices: IItems;
  methods: IItems;
  removeDevice: boolean;
}

export interface UpdateSelectedAddOnsPayload extends ISelectionUpdate {
  selectedDevice: string;
  selectedMethod: string;
  devices: IItems;
  methods: IItems;
}

export interface UpdateSelectedDevicePayload extends ISelectionUpdate {
  selectedDevice: string;
  selectedMethod: string;
  methods: IItems;
}

export interface UpdateSelectedMethodPayload extends ISelectionUpdate {
  selectedMethod: string;
}

/* Utility interfaces */
export interface ISelectionUpdate {
  invoice: IInvoice;
  selectedPackBundle: string[];
  selectedAddOns: string[];
  comboAddOns: string[];
  bundledAddOns: string[];
  requiredAddOns: string[];
  listedAddOns: IItems;
  addOnSelection: AddOnSelection | null;
  urlQueryParameters: ShopUrlQueryParameters;
  bundleMessage: string | null;
  error: SelectionUpdateError;
}

export interface PromoLinkEventParams {
  href: string | undefined;
  title: string | undefined;
}
