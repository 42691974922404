import {
  SignUpState,
  CustomerLeadParameters,
  BFCustomerLeadParameters,
  ResidentialType,
  BasicLeadParameters,
  ProductType,
  GATrackingParameters,
} from "./types";
import { getItemNames } from "@ducks/shop";
import { BbUpsellChoice } from "@ducks/shop/types";
import { AppState } from "@store";
import dayjs from "dayjs";
import { utmParameters } from "./constants";
import { ICustomerInfoState } from "@ducks/customerInfo/types";

export function formatInstallationDate(date: string): string {
  return dayjs(date).format("YYYY-MM-DD");
}

export function getCustomerLeadParameters(signUpState: SignUpState): CustomerLeadParameters {
  const {
    name: { value: name },
    salutation: { value: title },
    idType: { value: idType },
    idValue: { value: idValue },
    dob: { value: dob },
    gender: { value: gender },
    ethnic: { value: ethnic },
    language: { value: language },
    residentialType: { value: residenceType },
    house: { value: houseNumber },
    unit: { value: unitNumber },
    block: { value: block },
    building: { value: buildingName },
    street: { value: street },
    streetType: { value: streetType },
    streetName: { value: streetName },
    postcode: { value: postal },
    area: { value: town },
    city: { value: city },
    state: { value: state },
    installationDate: { value: installationDate },
    installationTime: { value: installationTime },
    mobileNumber: { value: mobileNumber },
    mobilePrefix: { value: mobilePrefix },
    alternateNumber: { value: alternateNumber },
    email: { value: email },
    config: {
      alternateNumber: { prefixValue: prefix },
    },
    allowDishInstallation,
  } = signUpState;

  const [d, m, y] = dob.split(" / ");
  const parameters: CustomerLeadParameters = {
    name,
    title,
    idType,
    idValue: idValue.replace(/-/g, ""),
    dob: [y, m, d].join("-"),
    gender,
    ethnic,
    language,
    residenceType: residenceType ?? 0,
    streetName: street || [streetType, streetName].join(" "),
    postal,
    town,
    city,
    state,
    phoneNumber: [mobilePrefix, mobileNumber].join(""),
    email,
    allowDishInstallation,
  };
  if (alternateNumber) parameters.alternatePhoneNumber = [prefix, alternateNumber].join("");
  if (residenceType === ResidentialType.SingleDwelling) parameters.houseNumber = houseNumber;
  else if (residenceType === ResidentialType.MultiDwelling) {
    parameters.unitNumber = unitNumber;
    parameters.block = block;
    parameters.buildingName = buildingName;
  }
  if (installationDate) parameters.installationDate = formatInstallationDate(installationDate);
  if (installationTime) parameters.installationTime = installationTime;
  return parameters;
}

export function getBFCustomerLeadParameters(
  signUpState: SignUpState,
  customerInfoState: ICustomerInfoState
): BFCustomerLeadParameters {
  const {
    residentialType: { value: residenceType },
    house: { value: houseNumber },
    unit: { value: unitNumber },
    block: { value: block },
    building: { value: buildingName },
    street: { value: street },
    streetType: { value: streetType },
    streetName: { value: streetName },
    postcode: { value: postal },
    area: { value: town },
    city: { value: city },
    state: { value: state },
    installationDate: { value: installationDate },
    installationTime: { value: installationTime },
    mobileNumber: { value: mobileNumber },
    mobilePrefix: { value: mobilePrefix },
    alternateNumber: { value: alternateNumber },
    email: { value: email },
    config: {
      alternateNumber: { prefixValue: prefix },
    },
    allowDishInstallation,
  } = signUpState;

  const { signature } = customerInfoState.customerInfoData;

  const parameters: BFCustomerLeadParameters = {
    signature,
    residenceType: residenceType ?? 0,
    streetName: street || [streetType, streetName].join(" "),
    postal,
    town,
    city,
    state,
    phoneNumber: [mobilePrefix, mobileNumber].join(""),
    email,
    allowDishInstallation,
  };
  if (alternateNumber) parameters.alternatePhoneNumber = [prefix, alternateNumber].join("");
  if (residenceType === ResidentialType.SingleDwelling) parameters.houseNumber = houseNumber;
  else if (residenceType === ResidentialType.MultiDwelling) {
    parameters.unitNumber = unitNumber;
    parameters.block = block;
    parameters.buildingName = buildingName;
  }
  if (installationDate) parameters.installationDate = formatInstallationDate(installationDate);
  if (installationTime) parameters.installationTime = installationTime;
  return parameters;
}

export function getGATrackingLeadParameters(appState: AppState): { gaTracking?: GATrackingParameters } {
  const {
    router: {
      location: { search },
    },
  } = appState;

  const gaTracking: GATrackingParameters = {};
  const queryParameters = new URLSearchParams(search);
  const utmSource = queryParameters.get(utmParameters.source);
  utmSource && (gaTracking.utmSource = utmSource);
  const utmMedium = queryParameters.get(utmParameters.medium);
  utmMedium && (gaTracking.utmMedium = utmMedium);
  const utmCampaign = queryParameters.get(utmParameters.campaign);
  utmCampaign && (gaTracking.utmCampaign = utmCampaign);

  if (Object.keys(gaTracking).length > 0) return { gaTracking };
  else return {};
}

export function getBasicLeadParameters(appState: AppState): BasicLeadParameters {
  const {
    shop: { invoice, selectedPack: pack, selectedSpeed: speed, tier, selectedAddOns, selectedDevice, bbUpsellChoice },
    config: { addOns: addOnsConfig, devices: devicesConfig, installationMethods: methodsConfig },
    signUp: { cableType, serviceProvider },
  } = appState;

  const { id: packId, items, campaignCode, promoCode } = invoice;
  const pricePlanId = items[packId]?.pricePlanId ?? "";
  const [offerId, ...extraAddOns] = pricePlanId.split(",");
  const addOnsList = Object.entries(items).reduce((list, [itemId, item]) => {
    if (itemId !== packId && !item.isBundled && item.pricePlanId) list.push(item.pricePlanId);
    return list;
  }, [] as string[]);
  const selectedPackages = extraAddOns.concat(addOnsList);
  const { addOns, devices, subType } = getItemNames(invoice, addOnsConfig, devicesConfig, methodsConfig);

  const lead: BasicLeadParameters = {
    type: getLeadType(!!speed),
    package: {
      offerId,
      selectedPackages,
      bundlePack: pack?.invoiceName ?? "",
      selectedAddOns: addOns,
      selectedDevices: devices,
      speed: speed?.urlParams,
      tierId: tier.pageUrl,
      speedId: speed?.id,
      packId,
      addonIds: selectedAddOns,
      deviceIds: [selectedDevice],
      subType: "REGULAR",
      broadbandInterest: bbUpsellChoice === BbUpsellChoice.INTERESTED ? true : false,
    },
  };
  if (subType) lead.package.subType = subType;
  if (campaignCode) lead.package.campaignCode = campaignCode;
  if (promoCode) lead.package.promoCode = promoCode;
  if (speed) {
    if (cableType) lead.package.cableType = cableType;
    if (serviceProvider) lead.package.serviceProvider = serviceProvider;
  }

  return lead;
}

export function getLeadType(speed: boolean): ProductType {
  return speed ? ProductType.Broadband : ProductType.Shop;
}
