import createMixins from "@material-ui/core/styles/createMixins";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import breakpoints from "./breakpoints";
import spacing from "./spacing";

declare module "@material-ui/core/styles/createMixins" {
  interface Mixins {
    shimmer: CSSProperties;
  }
}

const mixins = createMixins(breakpoints, spacing, {
  shimmer: {
    animation: "shimmer 1.5s linear 0s infinite forwards",
    background: "linear-gradient(to right, #f0f0f0 0%, #d8d8d8 50%, #f0f0f0 100%)",
    backgroundPosition: "0 0",
    backgroundRepeat: "no-repeat",
    backgroundSize: "25% 100%",
  },
});

export default mixins;
