import { ThankYouConfig } from "./types";

export const THANK_YOU: ThankYouConfig = {
  upsell: {
    broadband: {
      title: "",
      descriptionHtml: "",
      imgSrc: "",
      url: "",
      cta: "",
    },
    tv: {
      title: "",
      descriptionHtml: "",
      imgSrc: "",
      url: "",
      cta: "",
    },
  },
  emailIcon: "",
};
