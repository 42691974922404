import { FunctionComponent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import ErrorMessage from "@components/ErrorMessage";

import { AppState } from "@store";
import { FullScreenErrorMessageMap } from "@ducks/language/types";
import { resetUrlQueryString } from "@ducks/route";

interface IShopErrorProps {
  errorCode?: string;
}

const ShopError: FunctionComponent<IShopErrorProps> = ({ errorCode = "GENERIC_ERROR" }) => {
  const dispatch = useDispatch();
  const errorMessage = useSelector<AppState, FullScreenErrorMessageMap>((state) => state.language.errorMessage);

  useEffect(() => {
    dispatch(resetUrlQueryString());
  }, [dispatch]);

  return <ErrorMessage {...errorMessage[errorCode]} />;
};

ShopError.displayName = "ShopError";

export default ShopError;
