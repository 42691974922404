import { FunctionComponent } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import Container from "acm-components/module/components/Container";
import Button from "@components/Button";

interface IErrorMessageProps {
  title?: string;
  message?: string;
  buttonText?: string;
  buttonUrl?: string;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      margin: "1.5rem 0",
      fontSize: "2rem",
      lineHeight: "38px",
      [theme.breakpoints.up("md")]: {
        marginTop: "2.5rem",
      },
    },
    button: {
      marginTop: "1.5rem",
      [theme.breakpoints.up("desktop")]: {
        maxWidth: 368,
      },
    },
  })
);

const ErrorMessage: FunctionComponent<IErrorMessageProps> = (props) => {
  const {
    title = "Oops, something went wrong",
    message = "Apologies for the inconvenience, but rest assured we’re working on it. Please try again later.",
    buttonText,
    buttonUrl,
  } = props;
  const styles = useStyles();

  return (
    <Container>
      <h1 className={styles.title}>{title}</h1>
      <p>{message}</p>
      {buttonText && buttonUrl && (
        <Button className={styles.button} href={buttonUrl} magenta fullWidth>
          {buttonText}
        </Button>
      )}
    </Container>
  );
};

ErrorMessage.displayName = "ErrorMessage";

export default ErrorMessage;
