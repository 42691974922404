import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LocationChangeAction, LOCATION_CHANGE } from "connected-react-router";
import { SsoProfileParams } from "acm-components/module/layouts/Navigation/AuthProvider";
import { UlmProfile } from "acm-components/module/lib/sso";

import { fetchAppsConfig } from "@ducks/config";
import { APPS_ULM_CONFIG } from "./constants";
import { UlmState } from "./types";
import {
  checkCoveragePath,
  thankYouPath,
  registerInterestPath,
  disneyActivatePath,
  disneyPurchasePath,
  disneyActivateThankYouPath,
  disneyActivateCompletePath,
  disneyPurchaseThankYouPath,
  disneyActivatePendingPath,
  disneyContactPath,
  disneyContactThankYouPath,
} from "@constants/paths";
import { LogoutModalActionType } from "../analytics";

const initialState: UlmState = {
  config: APPS_ULM_CONFIG,
  enableUxf: false,
  isUlmFetched: false,
  isLoggedIn: false,
  ulmToken: "",
  loginUrl: "",
  ulmUuid: null,
  isUlmHandlerTriggered: false,
  is2faUser: false,
  hasLogoutModal: false,
  isLogoutModalOpened: false,
  redirectToDisneyMarketingPage: false,
  puid: "",
  userContactId: "",
};

const ulmSlice = createSlice({
  name: "ulm",
  initialState,
  reducers: {
    ulmStatusUpdated(state, action: PayloadAction<SsoProfileParams>) {
      state.isUlmFetched = true;
      state.isLoggedIn = !!action.payload;
      state.ulmToken = action.payload?.ulmProfileToken ?? "";
      state.is2faUser = (action.payload && is2faUser(action.payload)) ?? false;
      state.puid = action.payload?.PUID ?? "";
      state.userContactId = action.payload?.user_contactId ?? "";
    },
    loginUrlUpdated(state, action: PayloadAction<string>) {
      state.loginUrl = action.payload;
    },
    ulmUuidUpdated(state, action: PayloadAction<string | null>) {
      state.ulmUuid = action.payload;
    },
    ulmHandlerTriggered(state, action: PayloadAction<boolean>) {
      state.isUlmHandlerTriggered = action.payload;
    },
    hasLogoutModalUpdated(state, action: PayloadAction<boolean>) {
      state.hasLogoutModal = action.payload;
    },
    openLogoutModal(state) {
      state.isLogoutModalOpened = true;
    },
    closeLogoutModal(state) {
      state.isLogoutModalOpened = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAppsConfig.fulfilled, (state, action) => {
        const { ulm } = action.payload;
        if (ulm) state.config = ulm;
      })
      .addCase(LOCATION_CHANGE, (state, action: LocationChangeAction) => {
        const {
          payload: {
            location: { pathname },
          },
        } = action;

        state.isLogoutModalOpened = false;
        switch (pathname.replace(/^\//, "")) {
          case checkCoveragePath:
          case thankYouPath:
          case registerInterestPath:
            state.hasLogoutModal = false;
            state.redirectToDisneyMarketingPage = false;
            break;
          case disneyActivatePath:
          case disneyPurchasePath:
          case disneyContactPath:
          case disneyActivateThankYouPath:
          case disneyActivatePendingPath:
          case disneyActivateCompletePath:
          case disneyPurchaseThankYouPath:
          case disneyContactThankYouPath:
            state.hasLogoutModal = false;
            state.redirectToDisneyMarketingPage = true;
            break;
          default:
            state.hasLogoutModal = true;
            state.redirectToDisneyMarketingPage = false;
            break;
        }
      });
  },
});

export const {
  ulmStatusUpdated,
  loginUrlUpdated,
  ulmUuidUpdated,
  ulmHandlerTriggered,
  hasLogoutModalUpdated,
  openLogoutModal,
  closeLogoutModal,
} = ulmSlice.actions;

export default ulmSlice.reducer;

export const initUlmUuid = createAction("ulm/initUlmUuid");
export const initUlmHandlerTriggered = createAction("ulm/initUlmHandlerTriggered");
export const trackLogoutModalActions = createAction<LogoutModalActionType>("ulm/logoutModalActions");

/* Utility */
function is2faUser(userInfo: UlmProfile) {
  return !!(userInfo.astro_entitlements && userInfo.user_accountNumber && userInfo.user_contactId);
}
