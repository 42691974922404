import { FunctionComponent, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { AppState } from "@store";

const ScrollToTop: FunctionComponent = () => {
  const pathname = useSelector<AppState, unknown>((state) => state.router.location.pathname);

  useLayoutEffect(() => {
    // Scroll to top when url changes
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

ScrollToTop.displayName = "ScrollToTop";

export default ScrollToTop;
