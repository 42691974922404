import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk } from "@store";
import { DropdownConfig, InputState, TextFieldConfig } from "@ducks/types";
import { AppsContactState, ContactFormFieldKeys, ContactFormValues, FieldUpdatePayload } from "./types";
import { fetchAppsConfig } from "@ducks/config";
import { APPS_CONTACT_CONFIG, FORM_VALUES } from "./constants";
import { generateDisneyLead, verifyDisneyLead } from "../leadActions";

const initialState: AppsContactState = {
  config: APPS_CONTACT_CONFIG,
  formValues: FORM_VALUES,
  showFormErrors: false,
  submissionStatus: {
    loading: false,
    error: false,
    success: false,
  },
};

const appsContactSlice = createSlice({
  name: "appsContact",
  initialState,
  reducers: {
    contactReset(state) {
      state.showFormErrors = false;
    },
    fieldUpdated(state, action: PayloadAction<FieldUpdatePayload>) {
      // NOTE: this action is not for updating mobile prefix & suffix
      const { formFields } = state.config;
      const { field, value } = action.payload;
      if (value !== state.formValues[field].value) {
        state.formValues[field].value = value;

        const config = formFields[field];
        state.formValues[field].hasRequiredError = config.isRequired && !value.trim();
        if (!isDropdownConfig(config)) {
          const pattern = config.validationRegex;
          if (pattern) {
            const regex = new RegExp(pattern);
            state.formValues[field].hasInvalidError = !!value && !regex.test(value);
          }
        }
      }
    },
    mobilePrefixUpdated(state, action: PayloadAction<InputState>) {
      state.formValues.mobilePrefix = action.payload;
    },
    mobileSuffixUpdated(state, action: PayloadAction<InputState>) {
      state.formValues.mobileSuffix = action.payload;
    },
    contactFormValidated(state) {
      if (!isContactFormComplete(state.formValues)) state.showFormErrors = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAppsConfig.fulfilled, (state, action) => {
        const { contactForm: contactFormConfig, mobileNumber: mobileNumberConfig } = action.payload;
        if (contactFormConfig) {
          state.config = contactFormConfig;

          // Initialize form field state (excl. mobile)
          for (const [k, v] of Object.entries(contactFormConfig.formFields)) {
            const key = k as ContactFormFieldKeys; // TODO: need to improve typings instead of casting
            if (state.formValues[key]) state.formValues[key].hasRequiredError = v.isRequired;
          }
        }

        if (mobileNumberConfig) {
          // Initialize mobile number field state
          const { prefix, suffix } = mobileNumberConfig;
          state.formValues.mobilePrefix.hasRequiredError = prefix.isRequired;
          state.formValues.mobileSuffix.hasRequiredError = suffix.isRequired;
          const preselectedPrefix = prefix.items.find((item) => item.value === prefix.preselectedValue);
          if (preselectedPrefix) state.formValues.mobilePrefix.value = preselectedPrefix.value;
        }
      })
      .addCase(generateDisneyLead.rejected, (state, action) => {
        if (action.payload) {
          const { emailError } = action.payload;
          if (emailError) {
            state.formValues.email.hasInvalidError = true;
            state.showFormErrors = true;
          }
        }
      })
      .addCase(verifyDisneyLead.pending, (state) => {
        state.submissionStatus = {
          loading: true,
          success: false,
          error: false,
        };
      })
      .addCase(verifyDisneyLead.rejected, (state, action) => {
        state.submissionStatus = {
          loading: false,
          success: false,
          error: true,
        };
        if (action.payload) state.submissionStatus.errorCode = action.payload.errorCode;
      })
      .addCase(verifyDisneyLead.fulfilled, (state, action) => {
        state.submissionStatus = {
          loading: false,
          success: true,
          error: false,
        };
      });
  },
});

export const { contactReset, fieldUpdated, mobilePrefixUpdated, mobileSuffixUpdated, contactFormValidated } =
  appsContactSlice.actions;

export default appsContactSlice.reducer;

export const updateMobilePrefix =
  (value: string): AppThunk =>
  (dispatch, getState) => {
    const {
      appsUser: {
        mobileConfig: { prefix: prefixConfig },
      },
      appsContact: {
        formValues: { mobilePrefix: mobilePrefixState },
      },
    } = getState();
    if (value !== mobilePrefixState.value) {
      const hasRequiredError = prefixConfig.isRequired && !value.trim();
      dispatch(
        mobilePrefixUpdated({
          value,
          hasRequiredError,
          hasInvalidError: false,
        })
      );
    }
  };

export const updateMobileSuffix =
  (value: string): AppThunk =>
  (dispatch, getState) => {
    const {
      appsUser: {
        mobileConfig: { suffix: suffixConfig },
      },
      appsContact: {
        formValues: { mobileSuffix: mobileSuffixState },
      },
    } = getState();
    if (value !== mobileSuffixState.value) {
      const hasRequiredError = suffixConfig.isRequired && !value.trim();
      const hasInvalidError = !!value.trim() && !new RegExp(suffixConfig.regex).test(value);
      dispatch(mobileSuffixUpdated({ value, hasRequiredError, hasInvalidError }));
    }
  };

/* Utility functions */
export function isContactFormComplete(formValues: ContactFormValues): boolean {
  return Object.values(formValues).every((inputState) => !inputState.hasRequiredError && !inputState.hasInvalidError);
}

export function isDropdownConfig(config: TextFieldConfig | DropdownConfig): config is DropdownConfig {
  return "items" in config;
}
