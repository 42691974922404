import { FunctionComponent, useEffect } from "react";
import { IconButton } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";

import CloseIcon from "acm-components/module/icons/Close";
import BaseModal, { BaseModalProps } from "../BaseModal";

export interface SmallModalProps extends BaseModalProps {
  open: boolean;
  onClose: () => void;
  onPageHide?: () => void;
  hasCloseIcon?: boolean;
}

const useStyles = makeStyles<Theme, SmallModalProps>((theme) =>
  createStyles({
    paper: {
      width: 328,
      maxWidth: "calc(100% - 2rem)",
      maxHeight: "calc(100% - 2rem)",
      margin: "auto",
      padding: (props) => (props.hasCloseIcon ? "0 1rem 2rem" : "2rem 1rem"),
      [theme.breakpoints.up(360)]: {
        maxWidth: 328,
      },
    },
    closeIcon: {
      alignSelf: "flex-end",
      position: "relative",
      right: "-1rem",
      margin: "0.25rem",
      padding: "0.75rem",
    },
  })
);

const SmallModal: FunctionComponent<SmallModalProps> = (props) => {
  const { hasCloseIcon, onClose, onPageHide, classes = {}, children, ...modalProps } = props;
  const styles = useStyles(props);
  const { paper, ..._classes } = classes;
  const dialogClasses = {
    paper: clsx(styles.paper, paper),
    ..._classes,
  };

  useEffect(() => {
    if (onPageHide) {
      window.addEventListener("pagehide", onPageHide);
      return () => window.removeEventListener("pagehide", onPageHide);
    }
  }, [onPageHide]);

  return (
    <BaseModal classes={dialogClasses} onClose={onClose} {...modalProps}>
      {hasCloseIcon && (
        <IconButton classes={{ root: styles.closeIcon }} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      )}
      {children}
    </BaseModal>
  );
};

SmallModal.displayName = "SmallModal";

export default SmallModal;
