export const errorCodes = {
  phoneAlreadyUsed: "PHONE_ALREADY_USED",
  failedToVerifyOtp: "FAILED_TO_VERIFY_OTP",
  failedToGenerateOtp: "FAILED_TO_GENERATE_OTP",
  duplicatePurchaseValidation: "DUPLICATE_PURCHASE_VALIDATION",
  genericError: "GENERIC_ERROR",
  validationError: "VALIDATION_ERROR",
  /** Custom frontend error code to indicate is validation error due to token that should be handled as an OTP field error message */
  validationErrorToken: "VALIDATION_ERROR_TOKEN",
  notEntitled: "NOT_ENTITLED",
  unlinkedAccount: "UNLINKED_ACCOUNT",
  loginError: "LOGIN_ERROR",
};

export const generateLeadErrorValue = {
  emailType: "string.email",
  email: "email",
};

export const validationErrorTokenValue = {
  verifyLead: "leadToken",
  purchase: "purchaseValidateOtpToken",
  activate: "activationValidateToken",
};

export const bcpContactForm = "BCP";

export const waitingRoom = "WAITING_ROOM";

export const ulmWithoutForceLogin = "ULM_WITHOUT_FORCE";

export const reason = {
  statusNotActive: "STATUS_NOT_ACTIVE",
  emptySmartcard: "EMPTY_SMARTCARD",
  activationNotValid: "ACTIVATION_STATUS_NOT_VALID",
  missingInfo: "MISSING_INFO",
  notLoggedIn: "NOT_LOGGED_IN",
  sessionExpired: "SESSION_EXPIRED",
  retrySuccess: "RETRY_SUCCESS",
};

export const disneyPackId = "1447246";
