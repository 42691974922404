import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "@store";
import { UlmState } from "@ducks/ulm/types";
import { interpolate } from "@lib/common";

export const appsUlmLoginUrlSelector = createSelector<AppState, UlmState, string, string>(
  (state) => state.ulm,
  (state) => state.router.location.pathname,
  constructAppsLoginUrl
);

export function constructAppsLoginUrl(ulm: UlmState, redirectUrl: string) {
  const {
    config: { url, domain, rp, handler, scope: listedScopes },
    isUlmHandlerTriggered,
    ulmUuid,
  } = ulm;
  const clientId = encodeURIComponent(rp);
  const redirectUri = encodeURIComponent(handler);
  const scope = encodeURIComponent(listedScopes.join(" "));
  const loginPrompt = isUlmHandlerTriggered ? "prompt=login" : "";
  return interpolate(url, {
    domain,
    loginPrompt,
    clientId,
    scope,
    redirectUri,
    redirectUrl: window.location.origin + redirectUrl,
    ulmUuid: String(ulmUuid),
  });
}
