import { AppsContactConfig, ContactFormValues } from "./types";

export const APPS_CONTACT_CONFIG: AppsContactConfig = {
  errorCodes: [],
  formFields: {
    salutation: {
      isRequired: true,
      items: [],
    },
    name: {
      isRequired: true,
    },
    smartcardNumber: {
      isRequired: true,
    },
    email: {
      isRequired: true,
    },
  },
};

export const FORM_VALUES: ContactFormValues = {
  salutation: {
    value: "",
    hasRequiredError: false,
    hasInvalidError: false,
  },
  name: {
    value: "",
    hasRequiredError: false,
    hasInvalidError: false,
  },
  smartcardNumber: {
    value: "",
    hasRequiredError: false,
    hasInvalidError: false,
  },
  email: {
    value: "",
    hasRequiredError: false,
    hasInvalidError: false,
  },
  mobilePrefix: {
    value: "",
    hasRequiredError: false,
    hasInvalidError: false,
  },
  mobileSuffix: {
    value: "",
    hasRequiredError: false,
    hasInvalidError: false,
  },
};
