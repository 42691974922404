import createTypography from "@material-ui/core/styles/createTypography";
import palette from "./palette";

const typography = createTypography(palette, {
  htmlFontSize: 16,
  fontFamily: [
    `Mulish`,
    `-apple-system`,
    `BlinkMacSystemFont`,
    `"Segoe UI"`,
    `Roboto`,
    `"Helvetica Neue"`,
    `Arial`,
    "Microsoft YaHei",
    "微软雅黑",
    "STXihei",
    "华文细黑",
    `sans-serif`,
    `"Apple Color Emoji"`,
    `"Segoe UI Emoji"`,
    `"Segoe UI Symbol"`,
  ].join(","),
  button: {
    fontWeight: "bold",
  },
});

export default typography;
