import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";

import { AppState } from "@store";

import LiveChat from "acm-components/module/layouts/Navigation/LiveChat";

const LiveChatWidget: FunctionComponent = () => {
  const pathname = useSelector<AppState, string>((state) => state.router.location.pathname);
  return <LiveChat path={pathname} />;
};

export default LiveChatWidget;
